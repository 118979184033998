import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  useNavigate,
  useParams
} from "react-router-dom";
import { PageContainer } from "@packages/theme-mui-v5";
import {
  CaseManagementAddPage,
  CaseManagementAssignedToMeCasesDataGridPage,
  CaseManagementCreatedByMeCasesDataGridPage,
  CaseManagementEditPage,
  CaseManagementFollowedCasesDataGridPage,
  CaseManagementHelpPage,
  CaseManagementInvestigationsDataGridPage,
  CaseManagementRowAction,
  CaseManagementSearchDataGridPage,
  CaseManagementSubmissionsDataGridPage,
  CaseManagementSubscriptionsDataGridPage,
  CaseManagementValidationDataGridPage,
  CaseManagementFeedPage,
  CaseManagementDashboardPage
} from "@packages/case-management";
import CaseManagementConfig, {
  CaseManagementCRUDDefinitions,
  CaseManagementCaseCRUDPermissionIds,
  caseManagementApplicationId
} from "./config";
import ApplicationAccessManagement, {
  ApplicationAccessPermissionsProvider
} from "@packages/application-access-management";
import { PermissionsProvider, usePermissionContext } from "@packages/service-api";
import { useMemo } from "react";

const CaseManagementRoutes = () => {
  const { pathname } = useLocation();
  return (
    <PageContainer title={""} hideLocationbar={!pathname.includes("admin/auth/")}>
      <Routes>
        <Route path="/" Component={CaseManagementDashboardRoute} />
        <Route path="/feed" Component={CaseManagementFeedRoute} />

        {/* My Activities */}
        <Route path="/cases/submissions" Component={CaseManagementSubmissionsRoute} />
        <Route path="/cases/investigations" Component={CaseManagementInvestigationsRoute} />
        <Route path="/cases/validations" Component={CaseManagementValidationRoute} />
        <Route path="/cases/following" Component={CaseManagementFollowingRoute} />
        <Route path="/cases/subscriptions" Component={CaseManagementSubscriptionsRoute} />
        <Route path="/cases/assigned-to-me" Component={CaseManagementAssignedToMeRoute} />
        <Route path="/cases/created-by-me" Component={CaseManagementCreatedByMeRoute} />

        {/* Cases */}
        <Route path="/case/new" Component={CaseManagementAddRoute} />
        <Route path="/case/:caseId/edit" Component={CaseManagementEditRoute} />
        <Route path="/cases/search" Component={CaseManagementSearchDataGridRoute} />

        <Route path="/help" Component={CaseManagementHelpRoute} />

        <Route
          path="admin/*"
          element={
            <ApplicationAccessPermissionsProvider cruds={CaseManagementCRUDDefinitions}>
              <ApplicationAccessManagement
                hidePageContainer={true}
                basePath={CaseManagementConfig.path.value}
                applicationId={CaseManagementConfig.applicationId}
              />
            </ApplicationAccessPermissionsProvider>
          }
        />
      </Routes>
    </PageContainer>
  );
};

/**
 * This hook uses app navigate and permissions and passes in to the components
 * defined in the case management package.  Separating the hook from the
 * dumb components allows us to keep app dependencies & logic separate from the package.
 */
function useCaseManagementDataGridRowActions() {
  const navigate = useNavigate();
  const permissions = usePermissionContext();

  return useMemo(() => {
    if (permissions.canUpdate || permissions.canDelete) {
      return [
        {
          icon: "edit",
          color: "primary",
          tooltip: "Edit",
          action: (params) => navigate(`/case/${params?.data?.id as string}/edit`)
        }
      ] as CaseManagementRowAction[];
    }
  }, [permissions, navigate]);
}

function CaseManagementDashboardRoute() {
  return <CaseManagementDashboardPage />;
}

function CaseManagementFeedRoute() {
  return <CaseManagementFeedPage appBarHeight={64} />;
}

function CaseManagementSearchDataGridRoute() {
  const rowActions = useCaseManagementDataGridRowActions();
  return <CaseManagementSearchDataGridPage rowActions={rowActions} appBarHeight={64} />;
}

function CaseManagementAssignedToMeRoute() {
  const rowActions = useCaseManagementDataGridRowActions();
  return <CaseManagementAssignedToMeCasesDataGridPage rowActions={rowActions} appBarHeight={64} />;
}

function CaseManagementCreatedByMeRoute() {
  const rowActions = useCaseManagementDataGridRowActions();
  return <CaseManagementCreatedByMeCasesDataGridPage rowActions={rowActions} appBarHeight={64} />;
}

function CaseManagementFollowingRoute() {
  const rowActions = useCaseManagementDataGridRowActions();
  return <CaseManagementFollowedCasesDataGridPage rowActions={rowActions} appBarHeight={64} />;
}

function CaseManagementInvestigationsRoute() {
  const rowActions = useCaseManagementDataGridRowActions();
  return <CaseManagementInvestigationsDataGridPage rowActions={rowActions} appBarHeight={64} />;
}

function CaseManagementSubmissionsRoute() {
  const rowActions = useCaseManagementDataGridRowActions();
  return <CaseManagementSubmissionsDataGridPage rowActions={rowActions} appBarHeight={64} />;
}

function CaseManagementSubscriptionsRoute() {
  const rowActions = useCaseManagementDataGridRowActions();
  return <CaseManagementSubscriptionsDataGridPage rowActions={rowActions} appBarHeight={64} />;
}

function CaseManagementValidationRoute() {
  const rowActions = useCaseManagementDataGridRowActions();
  return <CaseManagementValidationDataGridPage rowActions={rowActions} appBarHeight={64} />;
}

function CaseManagementAddRoute() {
  const navigate = useNavigate();
  return <CaseManagementAddPage onAdded={() => navigate("..")} />;
}

function CaseManagementEditRoute() {
  const { caseId } = useParams<{ caseId: string }>();
  const navigate = useNavigate();
  return <CaseManagementEditPage caseId={caseId} onUpdated={() => navigate("..")} />;
}

function CaseManagementHelpRoute() {
  return <CaseManagementHelpPage />;
}

export default function CaseManagementSpaRouter() {
  return (
    <BrowserRouter basename={CaseManagementConfig.path.value}>
      <PermissionsProvider
        applicationId={caseManagementApplicationId}
        crudPermissionIds={CaseManagementCaseCRUDPermissionIds} // TODO hardcoded to `cases` entity for now
      >
        <CaseManagementRoutes />
      </PermissionsProvider>
    </BrowserRouter>
  );
}
