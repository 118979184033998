import { _case } from "@packages/case-management-queries";
import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography
} from "@packages/theme-mui-v5";

export type CasePersonnelProps = {
  case: _case;
};

function getFullNameFromEmail(email: string) {
  const [firstName, lastName] = email
    .split("@")[0]
    .split(".")
    .map((part) => part.toLowerCase().replace(/^\w/, (c) => c.toUpperCase()));

  // remove empty strings and join with space
  return [firstName, lastName].filter(Boolean).join(" ");
}

function getInitialsFromEmail(email: string) {
  return email.split("@")[0].slice(0, 2).toUpperCase();
}

function PersonnelListItem({ email }: { email: string }) {
  return (
    <ListItem disableGutters>
      <ListItemAvatar>
        <Avatar>{getInitialsFromEmail(email)}</Avatar>
      </ListItemAvatar>
      <ListItemText primary={getFullNameFromEmail(email)} secondary={email} />
    </ListItem>
  );
}

function PersonnelList({
  label,
  items
}: {
  label: string;
  items: Array<{ id: string; email: string }>;
}) {
  return (
    <Box>
      <Typography variant="caption" color="text.secondary">
        {label}
      </Typography>

      <List dense sx={{ width: "100%", maxWidth: 360 }}>
        {items.length === 0 ? (
          <>
            <Typography
              variant="body2"
              color="text.secondary"
              component="div"
              sx={{ margin: "auto" }}
            >
              No {label}
            </Typography>
          </>
        ) : (
          <>
            {items.map((v) => (
              <PersonnelListItem key={v.id} email={v.email} />
            ))}
          </>
        )}
      </List>
    </Box>
  );
}

export function CasePersonnel(props: CasePersonnelProps) {
  return (
    <>
      <PersonnelList
        label="Investigators"
        items={props.case.caseInvestigators.map((i) => ({
          email: i.caseInvestigatorUserId,
          id: i.id
        }))}
      />

      <PersonnelList
        label="Validators"
        items={props.case.caseValidators.map((v) => ({ email: v.caseValidatorUserId, id: v.id }))}
      />

      <PersonnelList
        label="Collaborators"
        items={props.case.caseCollaborators.map((c) => ({ email: c.createdBy, id: c.id }))}
      />
    </>
  );
}
