import { FieldApi, FormApi, ReactFormApi, useForm, useStore } from "@tanstack/react-form";
import { yupValidator } from "@tanstack/yup-form-adapter";
import * as yup from "yup";
import {
  AttachmentForm,
  AttachmentFormRef,
  AttachmentFormValues,
  Box,
  Button,
  Checkbox,
  DateTimePicker,
  Dialog,
  FormControlLabel,
  FormHelperText,
  LoadingButton,
  Paper,
  TextFieldRaw
} from "@packages/theme-mui-v5";
import { getDateValueAsISOString, getFieldStateMUIProps } from "@packages/core";
import { AttachmentType } from "@packages/utils";
import { useEffect, useMemo, useRef, useState } from "react";
import { CaseProcessFocusSelectField } from "./CaseProcessFocusSelectField";
import { CaseTypeCategorySelectField } from "./CaseTypeCategorySelectField";
import { CaseSeverityCategorySelectField } from "./CaseSeverityCategorySelectField";
import { CaseInitiatingActionSelectField } from "./CaseInitiatingActionSelectField";
import { CaseEventCategorySelectField } from "./CaseEventCategorySelectField";
import { CaseImpactCategorySelectField } from "./CaseImpactCategorySelectField";
import {
  AcceleratorAssetsAutocomplete,
  AcceleratorTagsAutocomplete,
  AcceleratorSiteSelect
} from "@packages/accelerator-components";
import { CaseFileAttachmentList } from "./CaseFileAttachmentList";
import { CaseFileAttachmentType, ExistingCaseFileAttachment } from "./CaseManagementTypes";

// TODO temporarily commenting out type error replaced with `any` below
// type CaseFileFile = {
//   attachmentType: Exclude<AttachmentType, AttachmentType.Link>;
//   fileName: string;
//   mimeType: string;
// };
// type CaseFileLink = { attachmentType: AttachmentType.Link; fileName: string; uri: string };
// type CaseFiles = Array<CaseFileFile | CaseFileLink>;

const defaultValuesFallback = {
  caseSitesAssetsTags: [{ siteId: "", assetId: "", tagId: "" }],
  description: "",
  // TODO api takes multiple, original UI takes one?
  caseProcessFocusCategoriesSelections: [
    {
      caseProcessFocusCategoryId: ""
    }
  ],
  caseTypeCategoryId: "",
  caseSeverityCategoryId: "",
  caseInitiatingActionId: "",
  caseEventCategoryId: "",
  caseImpactCategoryId: "",

  isNrocAssistanceRequired: false,
  alarmDate: undefined,
  caseFiles: [] as any // eslint-disable-line @typescript-eslint/no-explicit-any
};

export type CaseManagementFormType = typeof defaultValuesFallback;

export type CaseManagementFormProps = {
  defaultValues?: CaseManagementFormType;
  onSubmit: (values: CaseManagementFormType, attachments?: Array<CaseFileAttachmentType>) => void;

  /** Text to display on the submit button. */
  submitText: string;

  /** If `true`, the create action button will be disabled. */
  isBusy: boolean;
};

export function CaseManagementForm({
  defaultValues = defaultValuesFallback,
  onSubmit,
  submitText = "Create Case",
  isBusy = false
}: CaseManagementFormProps) {
  const [dialog, setDialog] = useState<"attach" | null>(null);

  const form = useForm({
    defaultValues,
    onSubmit: ({ value }) => {
      onSubmit(value, attachments);
    },
    validatorAdapter: yupValidator()
  });

  const caseFiles = useStore(form.store, (state) => state.values.caseFiles);

  /** The initial `defaultValue` for `caseFiles` rom the API, converted to mixed structure that handles new files to be uploaded */
  const initialExistingCaseFiles = useMemo(() => {
    return caseFiles.map((caseFile) => {
      return {
        type: "existing" as const,
        data: caseFile as ExistingCaseFileAttachment
      };
    }) as Array<CaseFileAttachmentType>;
  }, []);

  const [attachments, setAttachments] =
    useState<Array<CaseFileAttachmentType>>(initialExistingCaseFiles);

  /** When the attachments in memory change (added by user), update the `caseFiles` in the form to be sent with the update payload. */
  useEffect(() => {
    const caseFiles = attachments.map((attachment) => {
      if (attachment.type === "existing") {
        // TODO not sure if existing files should remain on the payload
        return attachment.data;
      } else if (attachment.data.attachmentType === AttachmentType.Link) {
        return {
          attachmentType: attachment.data.attachmentType,
          fileName: attachment.data.fileName,
          uri: attachment.data.uri
        };
      } else {
        return {
          attachmentType: attachment.data.attachmentType,
          fileName: attachment.data.fileName,
          mimeType: attachment.data.attachment.type
        };
      }
    });

    form.setFieldValue("caseFiles", caseFiles);
  }, [attachments]);

  return (
    <Paper
      component="form"
      sx={{ p: 2 }}
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        void form.handleSubmit();
      }}
    >
      <form.Field
        name="caseSitesAssetsTags"
        mode="array"
        validators={{
          onChange: yup.array().required("There must be at least one set of Site, Asset, Tag")
        }}
      >
        {(arrayField) => {
          return (
            <>
              <Box display="flex" flexDirection="column" gap={1}>
                {arrayField.state.value?.map((_, i) => {
                  return (
                    <CaseSitesAssetsTagsRow
                      key={`${i}-${arrayField.state.value.length}`}
                      index={i}
                      form={form}
                      arrayField={arrayField}
                    />
                  );
                })}

                <Box display="flex" justifyContent="flex-end">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => arrayField.pushValue({ siteId: "", assetId: "", tagId: "" })}
                  >
                    Add
                  </Button>
                </Box>
              </Box>
            </>
          );
        }}
      </form.Field>

      <form.Field
        name="description"
        validators={{ onChange: yup.string().required("Description is required.") }}
      >
        {(field) => {
          return (
            <TextFieldRaw
              label="Description"
              multiline
              fullWidth
              required
              size="small"
              variant="standard"
              InputLabelProps={{ shrink: true }}
              {...getFieldStateMUIProps(field)}
              value={field.state.value}
              onChange={(e) => field.handleChange(e.target.value)}
            />
          );
        }}
      </form.Field>

      <Box
        sx={{
          display: "grid",
          rowGap: 1,
          columnGap: 4,
          gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))"
        }}
      >
        <form.Field
          name="caseProcessFocusCategoriesSelections[0].caseProcessFocusCategoryId"
          validators={{ onChange: yup.string().required("Required.") }}
        >
          {(field) => {
            return <CaseProcessFocusSelectField {...getFieldStateMUIProps(field)} required />;
          }}
        </form.Field>

        <form.Field
          name="caseTypeCategoryId"
          validators={{ onChange: yup.string().required("Required.") }}
        >
          {(field) => {
            return <CaseTypeCategorySelectField {...getFieldStateMUIProps(field)} required />;
          }}
        </form.Field>

        <form.Field
          name="caseSeverityCategoryId"
          validators={{ onChange: yup.string().required("Required.") }}
        >
          {(field) => {
            return <CaseSeverityCategorySelectField {...getFieldStateMUIProps(field)} required />;
          }}
        </form.Field>

        <form.Field
          name="caseInitiatingActionId"
          validators={{ onChange: yup.string().required("Required.") }}
        >
          {(field) => {
            return <CaseInitiatingActionSelectField {...getFieldStateMUIProps(field)} required />;
          }}
        </form.Field>

        <form.Field
          name="caseEventCategoryId"
          validators={{ onChange: yup.string().required("Required.") }}
        >
          {(field) => {
            return <CaseEventCategorySelectField {...getFieldStateMUIProps(field)} required />;
          }}
        </form.Field>

        <form.Field
          name="caseImpactCategoryId"
          validators={{ onChange: yup.string().required("Required.") }}
        >
          {(field) => {
            return <CaseImpactCategorySelectField {...getFieldStateMUIProps(field)} required />;
          }}
        </form.Field>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column", gap: 1, alignItems: "flex-start" }}>
        <form.Field name="isNrocAssistanceRequired">
          {(field) => {
            const { value, onChange } = getFieldStateMUIProps(field);
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={value}
                    onChange={(event, checked) => {
                      onChange(checked);
                    }}
                  />
                }
                componentsProps={{ typography: { lineHeight: 1 } }}
                label={
                  <>
                    NROC Assistance Required
                    <FormHelperText component="div" sx={{ mt: 0 }}>
                      Leave unchecked to keep at site level.
                    </FormHelperText>
                  </>
                }
              />
            );
          }}
        </form.Field>

        <form.Field
          name="alarmDate"
          validators={{
            onChange: yup.string().datetime("Date and time required").nullable()
          }}
        >
          {(field) => {
            const { onChange, value, ...textFieldProps } = getFieldStateMUIProps(field);
            const valueAsDate = value !== null ? new Date(value) : null;
            return (
              <DateTimePicker
                label="Event Date Time"
                disableFuture
                slotProps={{
                  textField: {
                    ...textFieldProps,
                    variant: "standard",
                    size: "small",
                    helperText:
                      textFieldProps.helperText || "Date and time the event / alarm occurred.",
                    InputLabelProps: { shrink: true }
                  }
                }}
                // adapt component `Date` vs. form ISOString value
                value={valueAsDate}
                onChange={(value) => onChange(getDateValueAsISOString(value))}
              />
            );
          }}
        </form.Field>
      </Box>

      <Box>
        <CaseFileAttachmentList
          attachments={attachments}
          onRemove={(attachment, index) => {
            // removing file from in memory temp attachments
            setAttachments((prev) => prev.filter((_, i) => i !== index));
          }}
        />

        <AttachFilesDialog
          open={dialog === "attach"}
          onClose={() => setDialog(null)}
          onAttach={(data) => {
            // add to in memory temp attachments -- always new / yet to be uploaded
            setAttachments((prev) => [...prev, { type: "new", data }]);
            setDialog(null);
          }}
        />
      </Box>

      <Box display="flex" justifyContent="flex-end" gap={1}>
        <Button variant="outlined" type="button" onClick={() => setDialog("attach")}>
          Add Attachment
        </Button>

        <form.Subscribe selector={(state) => [state.isSubmitting]}>
          {([isSubmitting]) => (
            <LoadingButton
              variant="contained"
              type="submit"
              disabled={isSubmitting || isBusy}
              loading={isSubmitting || isBusy}
            >
              {submitText}
            </LoadingButton>
          )}
        </form.Subscribe>
      </Box>
    </Paper>
  );
}

function CaseSitesAssetsTagsRow({
  index,
  form,
  arrayField
}: {
  index: number;
  form: FormApi<CaseManagementFormType, ReturnType<typeof yupValidator>> &
    ReactFormApi<CaseManagementFormType, ReturnType<typeof yupValidator>>;
  arrayField: FieldApi<
    CaseManagementFormType,
    "caseSitesAssetsTags",
    ReturnType<typeof yupValidator>
  >;
}) {
  // the whole reason this is in a separate component is
  // so that the entire row can be re-rendered when the siteId changes via `useStore` hook
  const siteId = useStore(form.store, (state) => state.values.caseSitesAssetsTags[index].siteId);

  return (
    <Box
      display="grid"
      gridTemplateColumns="1fr 1fr 1fr min-content"
      columnGap={2}
      rowGap={4}
      alignItems="center"
    >
      <form.Field
        name={`caseSitesAssetsTags[${index}].siteId`}
        validators={{ onChange: yup.string().required("Site is required.") }}
      >
        {(field) => {
          return <AcceleratorSiteSelect required {...getFieldStateMUIProps(field)} />;
        }}
      </form.Field>

      <form.Field
        name={`caseSitesAssetsTags[${index}].assetId`}
        validators={{ onChange: yup.string().required("Asset is required.") }}
      >
        {(field) => {
          return (
            <AcceleratorAssetsAutocomplete
              required
              siteId={siteId}
              {...getFieldStateMUIProps(field)}
            />
          );
        }}
      </form.Field>

      <form.Field
        name={`caseSitesAssetsTags[${index}].tagId`}
        validators={{ onChange: yup.string().required("Tag is required.") }}
      >
        {(field) => {
          return <AcceleratorTagsAutocomplete required {...getFieldStateMUIProps(field)} />;
        }}
      </form.Field>

      <Button
        variant="outlined"
        color="primary"
        disabled={arrayField.state.value.length <= 1}
        onClick={() => arrayField.removeValue(index)}
      >
        Remove
      </Button>
    </Box>
  );
}

type AttachFilesDialogProps = {
  open: boolean;
  onClose: () => void;
  onAttach: (values: AttachmentFormValues) => void;
};

/**
 * Wraps the `AttachmentForm` in a dialog to attach files to the case.
 *
 * @deprecated This component was a shortcut and uses an anti-pattern for submitting the form from the parent.
 */
function AttachFilesDialog({ open, onAttach, onClose }: AttachFilesDialogProps) {
  /** @deprecated this is an anti pattern, we should not be using refs to submit forms from parent */
  const formRef = useRef<AttachmentFormRef>();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      disableBackdropClick
      title="Add Attachment"
      actions={[
        {
          variant: "contained",
          text: "Attach",
          action: () => {
            // TODO anti pattern triggering submit with ref
            formRef.current?.onSubmit();
          }
        },
        {
          text: "Discard",
          action: () => onClose(),
          variant: "text"
        }
      ]}
    >
      <AttachmentForm
        ref={formRef} // TODO anti pattern
        loading={false}
        isSuccess={false}
        isError={false}
        onSubmit={onAttach}
      />
    </Dialog>
  );
}
