import { useMemo } from "react";
import { CaseManagementDataGrid } from "./CaseManagementDataGrid";
import { _case } from "@packages/case-management-queries";
import { SearchFilter, useUserProfile } from "@packages/service-api";
import { CasePreviewDrawerPage } from "./CasePreviewDrawer";
import { BaseCaseManagementDataGridPageProps } from "./CaseManagementTypes";

export type CaseManagementAssignedToMeCasesDataGridPageProps = BaseCaseManagementDataGridPageProps;

export function CaseManagementAssignedToMeCasesDataGridPage({
  rowActions,
  appBarHeight
}: CaseManagementAssignedToMeCasesDataGridPageProps) {
  const { email } = useUserProfile();
  const filters = useMemo(() => {
    return {
      "caseActionItems.assignedToUserId": email
    } as SearchFilter<_case>;
  }, [email]);

  const defaultVisibleColumns = useMemo(() => {
    return ["caseId", "description", "createdBy", "description", "status", "StartDate", "dueDate"];
  }, []);

  return (
    <CasePreviewDrawerPage appBarHeight={appBarHeight}>
      {(setSelected) => {
        return (
          <CaseManagementDataGrid
            filters={filters}
            defaultVisibleColumns={defaultVisibleColumns}
            rowActions={rowActions}
            onRowSelected={setSelected}
          />
        );
      }}
    </CasePreviewDrawerPage>
  );
}
