import { _case, usePostCaseSearchInfiniteQuery } from "@packages/case-management-queries";
import { Box, CircularProgress, Alert, LoadingButton, Typography } from "@packages/theme-mui-v5";
import { CaseManagementCaseCard } from "./CaseManagementCaseCard";
import { Fragment, useState } from "react";
import {
  CasePreviewDrawer,
  CasePreviewDrawerProps,
  casePreviewDrawerWidth
} from "./CasePreviewDrawer";

export type CaseManagementFeedPageProps = Pick<CasePreviewDrawerProps, "appBarHeight">;

export function CaseManagementFeedPage({ appBarHeight }: CaseManagementFeedPageProps) {
  const [selected, setSelected] = useState<_case | null>(null);
  const query = usePostCaseSearchInfiniteQuery();

  const totalItemsFetched = Number(
    query.data?.pages.reduce((acc, page) => acc + page.data.length, 0)
  );

  if (query.isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (query.isError) {
    return (
      <Box textAlign="center">
        <Alert severity="error">Error loading case data. Please try again later.</Alert>
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={{
          maxWidth: 1600,
          margin: "0 auto",
          // make room for the case preview drawer
          paddingRight: selected ? `${casePreviewDrawerWidth}px` : 0,
          display: "flex",
          flexDirection: "column"
        }}
      >
        {query.isPending ? (
          <div className="p-8 text-center text-title text-soft">
            <div className="mb-2">Set filters and then perform a search.</div>
            <div className="font-label text-label">Events are retained for 60 days.</div>
          </div>
        ) : totalItemsFetched === 0 ? (
          <div className="p-8 text-center text-title text-soft">
            <div className="mb-2">No events found.</div>
            <div className="font-body mb-2 text-body">Change your filters and try again.</div>
            <div className="font-label text-label">Events are retained for 60 days.</div>
          </div>
        ) : !totalItemsFetched && query.isFetching ? (
          <div className="p-8 text-center text-title text-soft">
            <div>Searching...</div>
          </div>
        ) : totalItemsFetched > 0 ? (
          <Box display="flex" flexDirection="column" gap={4}>
            {query.isLoading ? null : query.isSuccess ? (
              <>
                {query.data.pages.map((page, pageIndex) => {
                  return (
                    <Fragment key={pageIndex}>
                      {page.data.map((_case) => {
                        const isSelected = selected?.id === _case.id;
                        return (
                          <CaseManagementCaseCard
                            key={_case.id}
                            case={_case}
                            onSelect={setSelected}
                            isSelected={isSelected}
                          />
                        );
                      })}
                    </Fragment>
                  );
                })}
              </>
            ) : null}
          </Box>
        ) : null}

        {!!totalItemsFetched && (
          <>
            <Box p={2} display="flex" justifyContent="center">
              {query.hasNextPage ? (
                <LoadingButton
                  variant="contained"
                  size="small"
                  type="button"
                  onClick={() => query.fetchNextPage()}
                  disabled={query.isFetchingNextPage}
                  loading={query.isFetchingNextPage}
                >
                  Load more cases
                </LoadingButton>
              ) : !query.isFetching ? (
                <Typography variant="body2" color="text.secondary">
                  No more cases
                </Typography>
              ) : null}
            </Box>
          </>
        )}
      </Box>

      <CasePreviewDrawer
        open={!!selected}
        case={selected}
        onClose={() => setSelected(null)}
        appBarHeight={appBarHeight}
      />
    </>
  );
}
