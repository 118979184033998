import { setupClientAuth } from "@packages/queries-client-auth";
import { API_CASE_MANAGEMENT } from "@nutrien-operations/config";
export { API_CASE_MANAGEMENT } from "@nutrien-operations/config";

import { client } from "./generated";

export * from "./generated";
export * from "./generated/@tanstack/react-query.gen";
export * from "./custom/hooks";

client.setConfig({ baseUrl: API_CASE_MANAGEMENT });

setupClientAuth(client);
