import { people, postPeopleSearchOptions } from "@packages/commondata-queries";
import { AutocompleteBase, CommonFieldStateProps } from "@packages/core";
import { useMemo, useState } from "react";
import { Box, useDebounce } from "@packages/theme-mui-v5";
import { useQuery } from "@tanstack/react-query";
import { buildSearchPayload } from "@packages/data-grid";

export type CommonDataAutocompleteProps = {
  value: people["email"];
  label: string;
  onChange: (value: people["email"], user: people) => void;
} & CommonFieldStateProps;

export function CommonDataAutocompletePeople({
  value,
  onChange,
  label,
  ...autocompleteProps
}: CommonDataAutocompleteProps) {
  const [searchInput, setSearchInput] = useState("");
  const searchInputDebounced = useDebounce(searchInput, 200);

  const body = buildSearchPayload({
    searchTerm: searchInputDebounced
  }) as Record<string, unknown>;

  const query = useQuery(postPeopleSearchOptions({ body }));
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const options = useMemo(() => query.data?.data || [], [query.data]);

  /**
   * Maps the given `value` (assetId) to a matching fetched option.
   */
  const valueMappedOption = useMemo(() => {
    // TODO handling initial value not yet loaded - see AcceleratorTagsAutcomplete for example
    return options.find((option) => option.email === value) || null;
  }, [value, options]);

  return (
    <AutocompleteBase
      label={label}
      primaryKey="email"
      options={options}
      value={valueMappedOption}
      onChange={onChange}
      searchInput={searchInput}
      {...autocompleteProps}
      ListboxOptionComponent={({ option }) => (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%", fontSize: 14 }}>
          <Box component="b" sx={{ mr: 2 }}>
            {option?.fullName}
          </Box>
          <Box sx={{ fontSize: 10 }}>{option?.userName}</Box>
        </Box>
      )}
      setSearchInput={(value) => {
        setSearchInput(value);
      }}
      getOptionString={(option) => {
        return option?.fullName;
      }}
    />
  );
}
