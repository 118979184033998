import { _case } from "@packages/case-management-queries";
// import { Link as RouterLink } from "react-router-dom";
import {
  // Button,
  Card,
  CardActionArea,
  // CardActions,
  CardContent,
  CardHeader,
  CardProps,
  LabelValue,
  LabelValueGrid
} from "@packages/theme-mui-v5";
import { formatDateTimeShort } from "@packages/core";

export type CaseManagementCaseCardProps = {
  case: _case;
  onSelect: (_case: _case) => void;
  isSelected?: boolean;
} & Pick<CardProps, "sx">;

export function CaseManagementCaseCard({
  case: _case,
  onSelect,
  isSelected = false
}: CaseManagementCaseCardProps) {
  return (
    <Card
      sx={{
        outlineColor: "primary.main",
        outlineOffset: 4,
        outlineStyle: isSelected ? "solid" : "none"
      }}
    >
      <CardActionArea onClick={() => onSelect(_case)}>
        <CardHeader
          title={`Case ###`}
          // action={
          //   <>
          //     {" "}
          //     <Button component={RouterLink} to={`/case/${_case.id}`}>
          //       View Case
          //     </Button>
          //     <Button onClick={() => alert("not yet implemented")}>Pin</Button>
          //     <Button onClick={() => alert("not yet implemented")}>Follow</Button>
          //   </>
          // }
        />
        <CardContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <LabelValueGrid>
            <LabelValue
              label="Severity"
              value={_case.caseSeverityCategory?.titleShort || "Unknown"}
            />
            <LabelValue label="Stage" value={_case.caseStage?.title || "Unknown"} />
            <LabelValue label="Type Category" value={_case.caseTypeCategory?.title || "Unknown"} />
            <LabelValue
              label="Event Category"
              value={_case.caseEventCategory?.title || "Unknown"}
            />
            <LabelValue label="Created By" value={_case.createdBy} />
            <LabelValue label="Created At" value={formatDateTimeShort(_case.createdOn)} />
            <LabelValue label="Modified At" value={formatDateTimeShort(_case.modifiedOn)} />
          </LabelValueGrid>

          <LabelValue label="Description" value={_case.description} />
        </CardContent>

        {/* <CardActions>
          <Button component={RouterLink} to={`/case/${_case.id}`}>
            View Case
          </Button>
          <Button onClick={() => alert("not yet implemented")}>Pin</Button>
          <Button onClick={() => alert("not yet implemented")}>Follow</Button>
        </CardActions> */}
      </CardActionArea>
    </Card>
  );
}
