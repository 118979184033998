import { HttpHandler, http, delay, HttpResponse } from "msw";

import {
  MockResponseAcceleratorAssetsSearch,
  MockResponseAcceleratorPersonasGet,
  MockResponseAcceleratorSitesGet,
  MockResponseAcceleratorTagWeightById,
  MockResponseAcceleratorTagMappedAssetsSearch,
  MockResponseAcceleratorTagsSearch,
  MockResponseTagById
} from "./Accelerator.mocks";

export const acceleratorHandlers: HttpHandler[] = [
  http.get("*/v1/personas", async () => {
    await delay();
    return HttpResponse.json(MockResponseAcceleratorPersonasGet);
  }),
  http.get("*/v1/sites", async () => {
    await delay();
    return HttpResponse.json(MockResponseAcceleratorSitesGet);
  }),
  http.post("*/v1/assets/search", async () => {
    await delay();
    return HttpResponse.json(MockResponseAcceleratorAssetsSearch);
  }),
  http.post("*/v1/tags/search", async () => {
    await delay();
    return HttpResponse.json(MockResponseAcceleratorTagsSearch);
  }),
  http.post("*/v1/tagMappings/search", async () => {
    await delay();
    return HttpResponse.json(MockResponseAcceleratorTagMappedAssetsSearch);
  }),
  http.get("*/v1/tags/*", async () => {
    await delay();
    return HttpResponse.json(MockResponseTagById);
  }),
  http.get("*/v1/tagWeights/*", async () => {
    await delay();
    return HttpResponse.json(MockResponseAcceleratorTagWeightById);
  }),
  http.put("*/v1/tagWeights/*", async () => {
    await delay();
    return HttpResponse.json(MockResponseAcceleratorTagWeightById);
  }),
  http.delete("*/v1/tagWeights/*", async () => {
    await delay();
    return HttpResponse.json(MockResponseAcceleratorTagWeightById);
  })
];
