import { getFieldStateMUIProps } from "@packages/core";
import { Box, Button, LoadingButton, TextFieldRaw } from "@packages/theme-mui-v5";
import { useForm, useStore } from "@tanstack/react-form";
import { z } from "zod";
import { AcceleratorTagsAutocomplete } from "../AcceleratorTagsAutocomplete";
import { AcceleratorPersonaSelect } from "../common/AcceleratorPersonaSelect";
import { AcceleratorTagMappedAssetSelect } from "../common/AcceleratorTagMappedAssetSelect";

const editTagWeightZodSchema = z.object({
  tagId: z.string().min(1, "Tag is required."),
  personaId: z.string().min(1, "Persona is required."),
  assetId: z.string().min(1, "Asset is required."),
  weight: z.number().min(0, "Weight must be a positive number.")
});

export type EditTagWeightFormValues = Required<z.infer<typeof editTagWeightZodSchema>>;

function useEditTagWeightForm({
  onSubmit,
  defaultValues
}: {
  onSubmit: (value: EditTagWeightFormValues) => void;
  defaultValues: EditTagWeightFormValues;
}) {
  return useForm<EditTagWeightFormValues>({
    defaultValues,
    onSubmit: ({ value }) => {
      onSubmit(value);
    },
    validators: {
      onChange: editTagWeightZodSchema
    }
  });
}

type UseEditTagWeightForm = ReturnType<typeof useEditTagWeightForm>;

export type EditTagWeightFormProps = {
  defaultValues: EditTagWeightFormValues;
  children: ({ form }: { form: UseEditTagWeightForm }) => React.ReactNode;
  onSubmit: (values: EditTagWeightFormValues) => void;
};

export function EditTagWeightForm({ children, onSubmit, defaultValues }: EditTagWeightFormProps) {
  const form = useEditTagWeightForm({ onSubmit, defaultValues });

  // for debugging form submit errors
  // const formErrorMap = useStore(form.store, (state) => state.errorMap);
  // console.log(formErrorMap);

  return (
    <form
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        void form.handleSubmit();
      }}
    >
      {/* render prop children with `form` passed back */}
      {children({ form })}
    </form>
  );
}

export function EditTagWeightFormFields({ form }: { form: UseEditTagWeightForm }) {
  /** The tag is used to fetch assets related to tag weights. */
  const tagId = useStore(form.store, (state) => state.values.tagId);

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, p: 2 }}>
        <form.Field name="personaId">
          {(field) => {
            const { onChange, ...textFieldProps } = getFieldStateMUIProps(field);
            return (
              <AcceleratorPersonaSelect
                required
                {...textFieldProps}
                onChange={(value) => onChange(value || "")}
              />
            );
          }}
        </form.Field>

        <form.Field name="tagId" listeners={{ onChange: () => form.setFieldValue("assetId", "") }}>
          {(field) => {
            const { onChange, ...textFieldProps } = getFieldStateMUIProps(field);
            return (
              <AcceleratorTagsAutocomplete
                required
                {...textFieldProps}
                onChange={(value) => onChange(value || "")}
              />
            );
          }}
        </form.Field>

        <form.Field name="assetId">
          {(field) => {
            const { ...textFieldProps } = getFieldStateMUIProps(field);
            return <AcceleratorTagMappedAssetSelect tagId={tagId} required {...textFieldProps} />;
          }}
        </form.Field>

        <form.Field name="weight">
          {(field) => {
            const { value, onChange, ...textFieldProps } = getFieldStateMUIProps(field);
            return (
              <TextFieldRaw
                label="Weight"
                type="number"
                required
                {...textFieldProps}
                value={value}
                onChange={(event) => onChange(Number(event.target.value))}
                // TODO themeprovider defaults
                variant="standard"
                InputLabelProps={{ shrink: true }}
              />
            );
          }}
        </form.Field>
      </Box>
    </>
  );
}

export function EditTagWeightFormActions({
  form,
  onCancel,
  isBusy = false
}: {
  form: UseEditTagWeightForm;
  onCancel: () => void;
  isBusy?: boolean;
}) {
  return (
    <>
      <form.Subscribe selector={(state) => [state.isSubmitting]}>
        {([isSubmitting]) => (
          <LoadingButton
            variant="contained"
            type="submit"
            disabled={isSubmitting || isBusy}
            loading={isSubmitting || isBusy}
          >
            Update Tag Weight
          </LoadingButton>
        )}
      </form.Subscribe>

      <Button variant="outlined" onClick={onCancel}>
        Cancel
      </Button>
    </>
  );
}
