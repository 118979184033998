import { useMutation, useQuery } from "@tanstack/react-query";
import { CaseManagementForm, CaseManagementFormType } from "./CaseManagementForm";
import {
  caseResponse,
  getCasesByIdOptions,
  putCasesByIdMutation
} from "@packages/case-management-queries";
import { Alert, Box, CircularProgress, useSnackbar } from "@packages/theme-mui-v5";
import { useFileUploadMutation } from "@packages/core";

export type CaseManagementEditPageProps = {
  caseId: string;
  onUpdated?: (response: caseResponse) => void;
};

export function CaseManagementEditPage({ caseId, onUpdated }: CaseManagementEditPageProps) {
  const upload = useFileUploadMutation();
  const updateCase = useMutation(putCasesByIdMutation());
  const { enqueueSnackbar } = useSnackbar();

  const caseQuery = useQuery({
    ...getCasesByIdOptions({ path: { id: caseId } }),
    enabled: !!caseId
  });

  if (caseQuery.isLoading) {
    return (
      <Box sx={{ textAlign: "center" }}>
        <CircularProgress />
      </Box>
    );
  } else if (caseQuery.isError) {
    return (
      <Box>
        <Alert severity="error">
          Unfortunately, we were unable to load the case. Please try again.
        </Alert>
      </Box>
    );
  } else if (caseQuery.isPending) {
    return null;
  }

  return (
    <>
      <CaseManagementForm
        defaultValues={caseQuery.data.data as CaseManagementFormType} // TODO openapi types do not include relations
        isBusy={updateCase.isPending}
        submitText="Update Case"
        onSubmit={(values, attachments) => {
          updateCase.mutate(
            { body: values, path: { id: caseId } },
            {
              onSuccess: async (response) => {
                /** After the case is updated, upload all the `new` attachments attachmentLocation presigned urls */
                const caseFiles = response.data.caseFiles || [];
                for (const [index, caseFile] of caseFiles.entries()) {
                  // @ts-expect-error attachmentLocation is not in the caseFile type
                  const presignedUrl = caseFile.attachmentLocation as string;
                  const attachment = attachments.at(index);
                  if (attachment.type === "new") {
                    if (attachment.data.attachmentType !== "Link") {
                      await upload.mutateAsync({
                        presignedUrl,
                        file: attachment.data.attachment
                      });
                    }
                  }
                }

                enqueueSnackbar("Case updated", { variant: "success" });
                onUpdated?.(response);
              },
              onError: () => {
                enqueueSnackbar("Error updating case", { variant: "error" });
              }
            }
          );
        }}
      />
    </>
  );
}
