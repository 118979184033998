import { BarChart, PieChart } from "@mui/icons-material";
import {
  Card,
  CardContent,
  CardHeader,
  CardProps,
  ToggleButtonGroupRaw,
  ToggleButtonRaw
} from "@packages/theme-mui-v5";
import { AgChartProps, AgCharts } from "ag-charts-react";
import { useMemo, useState } from "react";

export type CasesChartCardProps<
  Data extends Array<{ [key: string]: number | string }>,
  LabelKey extends keyof Data[number],
  ValueKey extends keyof Data[number]
> = {
  title: string;
  labelKey: LabelKey;
  valueKey: ValueKey;
  data: Data;
} & CardProps;

export function CasesChartCard<
  Data extends Array<{ [key: string]: number | string }>,
  LabelKey extends keyof Data[number],
  ValueKey extends keyof Data[number]
>({
  title,
  data,
  labelKey,
  valueKey,
  ...cardProps
}: CasesChartCardProps<Data, LabelKey, ValueKey>) {
  // const [chartType, setChartType] = useAtom(chartTypeAtom);
  const [chartType, setChartType] = useState<"pie" | "bar">("pie");

  const chartOptions = useMemo(() => {
    if (chartType === "pie") {
      return {
        data: data as unknown as AgChartProps["options"]["data"],
        series: [{ type: chartType, angleKey: valueKey, legendItemKey: labelKey }]
      } as AgChartProps["options"];
    } else {
      return {
        data,
        series: [{ type: chartType, xKey: labelKey, yKey: valueKey }]
      } as AgChartProps["options"];
    }
  }, [chartType, data, labelKey, valueKey]);

  return (
    <Card {...cardProps}>
      <CardHeader
        title={title}
        action={
          <ToggleButtonGroupRaw
            size="small"
            value={chartType}
            exclusive
            onChange={(event, value) => {
              if (value) {
                setChartType(value);
              }
            }}
            aria-label="chart type"
          >
            <ToggleButtonRaw value="pie" aria-label="pie chart">
              <PieChart />
            </ToggleButtonRaw>
            <ToggleButtonRaw value="bar" aria-label="bar chart">
              <BarChart />
            </ToggleButtonRaw>
          </ToggleButtonGroupRaw>
        }
      />
      <CardContent>
        <AgCharts options={chartOptions} />
      </CardContent>
    </Card>
  );
}
