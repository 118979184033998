import { useMemo, useState } from "react";
import { CaseManagementDataGrid } from "./CaseManagementDataGrid";
import { _case } from "@packages/case-management-queries";
import { CaseManagementSearchForm, CaseManagementSearchFormType } from "./CaseManagementSearchForm";
import { CasePreviewDrawerPage } from "./CasePreviewDrawer";
import { BaseCaseManagementDataGridPageProps } from "./CaseManagementTypes";

export type CaseManagementDataGridPageProps = BaseCaseManagementDataGridPageProps;

export function CaseManagementSearchDataGridPage({
  rowActions,
  appBarHeight
}: BaseCaseManagementDataGridPageProps) {
  const [filters, setFilters] = useState<CaseManagementSearchFormType>();

  const defaultVisibleColumns = useMemo(() => {
    return [
      "caseId",
      "site",
      "asset",
      "description",
      "NROCFocusPerPlan",
      "alarmId",
      "alarmDate",
      "initiatingAction",
      "event",
      "impact",
      "severity",
      "type",
      "openedBy",
      "openedDate",
      "StartDate",
      "dueDate",
      "status"
    ];
  }, []);

  return (
    <>
      <CasePreviewDrawerPage appBarHeight={appBarHeight}>
        {(setSelected) => {
          return (
            <>
              <CaseManagementSearchForm onSubmit={setFilters} />

              {!!Object.keys(filters || {}).length && (
                <CaseManagementDataGrid
                  filters={filters}
                  defaultVisibleColumns={defaultVisibleColumns}
                  rowActions={rowActions}
                  onRowSelected={setSelected}
                />
              )}
            </>
          );
        }}
      </CasePreviewDrawerPage>
    </>
  );
}
