import { useMutation } from "@tanstack/react-query";
import { CaseManagementForm } from "./CaseManagementForm";
import { caseResponse, postCasesMutation } from "@packages/case-management-queries";
import { useSnackbar } from "@packages/theme-mui-v5";
import { useFileUploadMutation } from "@packages/core";

export type CaseManagementAddPageProps = {
  onAdded?: (response: caseResponse) => void;
};

export function CaseManagementAddPage({ onAdded }: CaseManagementAddPageProps) {
  const upload = useFileUploadMutation();
  const createCase = useMutation(postCasesMutation());
  const { enqueueSnackbar } = useSnackbar();

  return (
    <CaseManagementForm
      isBusy={createCase.isPending}
      submitText="Create Case"
      onSubmit={(values, newAttachments) => {
        createCase.mutate(
          { body: values },
          {
            onSuccess: async (response) => {
              /** After the case is updated, upload all the `new` attachments attachmentLocation presigned urls */
              const caseFiles = response.data.caseFiles || [];
              for (const [index, caseFile] of caseFiles.entries()) {
                // @ts-expect-error attachmentLocation is not in the caseFile type
                const presignedUrl = caseFile.attachmentLocation as string;
                const attachment = newAttachments.at(index);
                if (attachment.type === "new") {
                  if (attachment.data.attachmentType !== "Link") {
                    await upload.mutateAsync({
                      presignedUrl,
                      file: attachment.data.attachment
                    });
                  }
                }
              }

              enqueueSnackbar("Case created", { variant: "success" });
              onAdded?.(response);
            },
            onError: () => {
              enqueueSnackbar("Error creating case", { variant: "error" });
            }
          }
        );
      }}
    />
  );
}
