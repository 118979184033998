import { getFieldStateMUIProps } from "@packages/core";
import { Box, Button, LoadingButton, TextFieldRaw } from "@packages/theme-mui-v5";
import { useForm, useStore } from "@tanstack/react-form";
import { z } from "zod";
import { AcceleratorTagsAutocomplete } from "../AcceleratorTagsAutocomplete";
import { AcceleratorPersonaSelect } from "../common/AcceleratorPersonaSelect";
import { AcceleratorTagMappedAssetsAutocomplete } from "../common/AcceleratorTagMappedAssetsAutocomplete";
import { useEffect } from "react";

const addManyTagWeightsZodSchema = z.object({
  tagId: z.string().min(1, "Tag is required."),
  personaId: z.string().min(1, "Persona is required."),
  assetIds: z.string().array().min(1, "At least one asset is required"),
  weight: z.number().min(0, "Weight must be a positive number.")
});

export type AddManyTagWeightsFormValues = Required<z.infer<typeof addManyTagWeightsZodSchema>>;

const defaultValues: AddManyTagWeightsFormValues = {
  tagId: "",
  personaId: "",
  assetIds: [],
  weight: 0
};

function useAddManyTagWeightsForm({
  onSubmit
}: {
  onSubmit: (value: AddManyTagWeightsFormValues) => void;
}) {
  return useForm<AddManyTagWeightsFormValues>({
    defaultValues,
    onSubmit: ({ value }) => {
      onSubmit(value);
    },
    validators: {
      onChange: addManyTagWeightsZodSchema
    }
  });
}

type UseAddManyTagWeightsForm = ReturnType<typeof useAddManyTagWeightsForm>;

export type AddManyTagWeightsFormProps = {
  children: ({ form }: { form: UseAddManyTagWeightsForm }) => React.ReactNode;
  onSubmit: (values: AddManyTagWeightsFormValues) => void;
};

export function AddManyTagWeightsForm({ children, onSubmit }: AddManyTagWeightsFormProps) {
  const form = useAddManyTagWeightsForm({ onSubmit });

  // for debugging form submit errors
  // const formErrorMap = useStore(form.store, (state) => state.errorMap);
  // console.log(formErrorMap);

  return (
    <form
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        void form.handleSubmit();
      }}
    >
      {/* render prop children with `form` passed back */}
      {children({ form })}
    </form>
  );
}

export function AddManyTagWeightsFormFields({ form }: { form: UseAddManyTagWeightsForm }) {
  /** The tag is used to fetch assets related to tag weights. */
  const tagId = useStore(form.store, (state) => state.values.tagId);

  // clear assetIds when tagId changes
  useEffect(() => {
    if (tagId === "" && form.getFieldValue("assetIds").length > 0) {
      form.setFieldValue("assetIds", []);
    }
  }, [tagId, form]);

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, p: 2 }}>
        <form.Field name="personaId">
          {(field) => {
            const { onChange, ...textFieldProps } = getFieldStateMUIProps(field);
            return (
              <AcceleratorPersonaSelect
                required
                {...textFieldProps}
                onChange={(value) => onChange(value || "")}
              />
            );
          }}
        </form.Field>

        <form.Field name="tagId">
          {(field) => {
            const { onChange, ...textFieldProps } = getFieldStateMUIProps(field);
            return (
              <AcceleratorTagsAutocomplete
                required
                {...textFieldProps}
                onChange={(value) => onChange(value || "")}
              />
            );
          }}
        </form.Field>

        <form.Field name="assetIds" mode="array">
          {(field) => {
            const { ...textFieldProps } = getFieldStateMUIProps(field);
            return (
              <AcceleratorTagMappedAssetsAutocomplete tagId={tagId} required {...textFieldProps} />
            );
          }}
        </form.Field>

        <form.Field name="weight">
          {(field) => {
            const { value, onChange, ...textFieldProps } = getFieldStateMUIProps(field);
            return (
              <TextFieldRaw
                label="Weight"
                type="number"
                required
                {...textFieldProps}
                value={value}
                onChange={(event) => onChange(Number(event.target.value))}
                // TODO themeprovider defaults
                variant="standard"
                InputLabelProps={{ shrink: true }}
              />
            );
          }}
        </form.Field>
      </Box>
    </>
  );
}

export function AddManyTagWeightsFormActions({
  form,
  onCancel,
  isBusy = false
}: {
  form: UseAddManyTagWeightsForm;
  onCancel: () => void;
  isBusy?: boolean;
}) {
  return (
    <>
      <form.Subscribe selector={(state) => [state.isSubmitting]}>
        {([isSubmitting]) => (
          <LoadingButton
            variant="contained"
            type="submit"
            disabled={isSubmitting || isBusy}
            loading={isSubmitting || isBusy}
          >
            Add Tag Weights
          </LoadingButton>
        )}
      </form.Subscribe>

      <Button variant="outlined" onClick={onCancel}>
        Cancel
      </Button>
    </>
  );
}
