import {
  TextFieldRaw as TextField,
  TextFieldRawProps as TextFieldProps,
  MenuItem,
  CircularProgress,
  InputAdornment
} from "@packages/theme-mui-v5";
import { useQuery } from "@tanstack/react-query";
import { getSitesOptions } from "@packages/accelerator-queries";

export type AcceleratorSiteSelectProps = Pick<
  TextFieldProps,
  "onBlur" | "value" | "error" | "helperText" | "required"
> & {
  onChange: (siteId: string) => void;
};

export function AcceleratorSiteSelect({ onChange, ...textFieldProps }: AcceleratorSiteSelectProps) {
  const query = useQuery(getSitesOptions());
  const endAdornment = query.isLoading ? (
    <InputAdornment position="end">
      <CircularProgress size={16} />
    </InputAdornment>
  ) : null;

  return (
    <TextField
      select
      label="Site"
      {...textFieldProps}
      onChange={(e) => onChange(e.target.value)}
      InputProps={{ endAdornment }}
      // TODO themeprovider defaults
      variant="standard"
      margin="none"
      InputLabelProps={{ shrink: true }}
      sx={{
        mb: 0,
        // make room for loading spinner end adornment + select dropdown arrow
        ".MuiInputBase-inputAdornedEnd": { paddingRight: "56px !important" },
        ".MuiInputAdornment-root.MuiInputAdornment-positionEnd": {
          position: "absolute",
          padding: 0,
          right: "32px"
        }
      }}
    >
      <MenuItem value="">
        <em>None</em>
      </MenuItem>

      {query.data?.data?.map((site, index) => (
        <MenuItem key={index} value={site.id}>
          {site.name}
        </MenuItem>
      ))}
    </TextField>
  );
}
