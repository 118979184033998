/**
 * Formats a date string into a localized date format (MM/DD/YY).
 * @param {string | null | undefined}dateString
 * @returns {string}
 */
export const formatDate = (dateString: string | null | undefined): string => {
  if (!dateString) return "N/A";
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", {
    year: "2-digit",
    month: "numeric",
    day: "numeric"
  });
};

export function formatTimeShort(dateString: string | null | undefined): string {
  if (!dateString) return "N/A";
  const date = new Date(dateString);
  return date.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric"
  });
}

export function formatDateTimeShort(dateString: string | null | undefined): string {
  if (!dateString) return "N/A";
  const date = new Date(dateString);
  let dateStr = date.toLocaleString("en-US", {
    year: "2-digit",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric"
  });

  dateStr = dateStr.replace(",", "");

  return dateStr;
}
