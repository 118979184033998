import { HttpHandler, http, delay, HttpResponse } from "msw";

import { acceleratorHandlers } from "@packages/accelerator-components";

import {
  MockResponseCaseEventCategories,
  MockResponseCaseImpactCategories,
  MockResponseCaseInitiatingActions,
  MockResponseCaseProcessFocusCategories,
  MockResponseCaseSeverityCategories,
  MockResponseCaseTypeCategories,
  MockResponseCaseSearch,
  MockResponseEditCaseById
} from "./CaseManagement.mocks";

export const caseManagementHandlers: HttpHandler[] = [
  ...acceleratorHandlers,

  http.get("*/v1/caseProcessFocusCategories", async () => {
    await delay();
    return HttpResponse.json(MockResponseCaseProcessFocusCategories);
  }),
  http.get("*/v1/caseSeverityCategories", async () => {
    await delay();
    return HttpResponse.json(MockResponseCaseSeverityCategories);
  }),
  http.get("*/v1/caseTypeCategories", async () => {
    await delay();
    return HttpResponse.json(MockResponseCaseTypeCategories);
  }),
  http.get("*/v1/caseInitiatingActions", async () => {
    await delay();
    return HttpResponse.json(MockResponseCaseInitiatingActions);
  }),
  http.get("*/v1/caseEventCategories", async () => {
    await delay();
    return HttpResponse.json(MockResponseCaseEventCategories);
  }),
  http.get("*/v1/caseImpactCategories", async () => {
    await delay();
    return HttpResponse.json(MockResponseCaseImpactCategories);
  }),

  http.get("*/v1/cases/search", async () => {
    await delay();
    return HttpResponse.json(MockResponseCaseSearch);
  }),

  // :id is available as (req) => req.params.id
  http.get("*/v1/cases/:id", async () => {
    await delay();
    return HttpResponse.json(MockResponseEditCaseById);
  }),

  http.post("*/v1/cases/search", async () => {
    await delay();
    return HttpResponse.json(MockResponseCaseSearch);
  })
];
