import { AttachmentFormValues, Box, BoxProps } from "@packages/theme-mui-v5";
import { CaseFileAttachmentType } from "./CaseManagementTypes";
import { CaseFileAttachment } from "./CaseFileAttachment";

export type CaseFileAttachmentListProps = BoxProps & {
  attachments: Array<CaseFileAttachmentType>;
  onRemove: (attachment: AttachmentFormValues, index: number) => void;
};

export function CaseFileAttachmentList({
  attachments,
  onRemove,
  ...boxProps
}: CaseFileAttachmentListProps) {
  return (
    <Box display="flex" gap={2} {...boxProps}>
      {attachments.map((attachment, index) => {
        return (
          <CaseFileAttachment
            key={index}
            attachment={attachment}
            onRemove={(attachment) => onRemove(attachment, index)}
          />
        );
      })}
    </Box>
  );
}
