import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { postCasesSearch } from "../generated";

const LIMIT = 10;

export function usePostCaseSearchInfiniteQuery() {
  return useInfiniteQuery({
    initialPageParam: undefined,
    staleTime: 0, // no caching
    queryKey: ["PostCaseSearchInfinite"], // TODO build queryKey from args
    queryFn: async ({ pageParam, signal }) => {
      const size = LIMIT;
      const from = size * (pageParam - 1) || 0;

      // TODO pull args from queryKey param

      const body = {
        size,
        from,
        // query: {
        //   bool: {
        //     should: [],
        //     must: [],
        //     must_not: [],
        //     filter: []
        //   }
        // },
        sort: [
          {
            createdOn: {
              order: "desc",
              unmapped_type: "string"
            }
          }
        ]
      };

      // const payload = await buildSearchPayload({
      //   // searchTerm: debouncedSearchValue,
      //   // filter,
      //   options: {
      //     from: params.request.startRow,
      //     size: size || CACHE_BLOCK_SIZE
      //   },
      //   sortModel: getDataGridSortModel(params.request.sortModel, skipKeywordList, sortConfig)
      // });

      const response = await postCasesSearch({
        body,
        signal,
        throwOnError: true
      });

      return response.data;
    },

    getNextPageParam: (lastPage, allPages) => {
      // lastPage.hasMore is not reliable and will return true even on filtered result sets
      const totalItems = (allPages.length - 1) * LIMIT + lastPage.data.length;
      const hasMore = totalItems < lastPage.total;

      if (hasMore) {
        // TODO if we get duplicates may need a better cursor such as modifedOn
        // const lastItem = lastPage.data[lastPage.data.length - 1];
        // return lastItem?.updatedAt;
        return allPages.length + 1;
      }

      return undefined;
    }
  });
}

/**
 * @TODO aggregate all the cases and wire into dashboard charts, waiting for search aggregation endpoint
 */
export function useAggregateCasesByStageQuery() {
  return useQuery({
    queryKey: ["AggregateCasesByStage"],
    queryFn: async () => {
      const response = await postCasesSearch({
        body: {
          size: 0,
          aggs: {
            stages: {
              terms: {
                field: "caseStage.title.keyword", // no type ?
                size: 100
              }
            }
          }
        },
        throwOnError: true
      });

      // @ts-expect-error response strucutre should include aggregations
      return response.data.aggregations.stages.buckets;
    }
  });
}
