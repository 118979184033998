import { HttpHandler, http, delay, HttpResponse } from "msw";

import { MockResponseCommonDataPeople } from "./CommonData.mocks";

export const commonDataHandlers: HttpHandler[] = [
  http.post("*/v1/people/search", async () => {
    await delay();
    return HttpResponse.json(MockResponseCommonDataPeople);
  })
];
