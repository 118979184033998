import { useMemo } from "react";
import {
  MenuItem,
  TextFieldRaw as TextField,
  TextFieldRawProps as TextFieldProps
} from "@packages/theme-mui-v5";

export type CaseStatusSelectFieldProps = Pick<
  TextFieldProps,
  "onBlur" | "value" | "error" | "helperText" | "required" | "sx"
> & {
  onChange?: (caseSeverityCategoryId: string) => void;
};

export function CaseStatusSelectField({ onChange, ...textFieldProps }: CaseStatusSelectFieldProps) {
  // TODO: New status lists
  const options = useMemo(() => {
    return [
      {
        title: "Not Yet Started",
        id: "Not Yet Started"
      },
      {
        title: "In Progress",
        id: "In Progress"
      },
      {
        title: "Postponed",
        id: "Postponed"
      },
      {
        title: "Overdue",
        id: "Overdue"
      },
      {
        title: "Completed",
        id: "Completed"
      },
      {
        title: "Submitted",
        id: "Submitted"
      }
    ];
  }, []);

  return (
    <TextField
      select
      size="small"
      variant="standard"
      margin="normal"
      label="Status"
      InputLabelProps={{ shrink: true }}
      {...textFieldProps}
      onChange={onChange ? (e) => onChange(e.target.value) : undefined}
    >
      <MenuItem value="">
        <em>None</em>
      </MenuItem>

      {options.map((value) => {
        return (
          <MenuItem key={value.id} value={value.id}>
            {value.title}
          </MenuItem>
        );
      })}
    </TextField>
  );
}
