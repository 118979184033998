import { useCallback, useMemo, useState } from "react";
import { ApiListResponse, DataGridSearchParams, SearchFilter } from "@packages/service-api";
import { useDebounce, useSnackbar } from "@packages/theme-mui-v5";

import { getDataGridSortModel } from "./utils";
import { CACHE_BLOCK_SIZE } from "./AgGrid";
import {
  CsvExportParams,
  ExcelExportParams,
  GridReadyEvent,
  IServerSideDatasource,
  IServerSideGetRowsParams,
  SortModelItem
} from "ag-grid-community";
import { CSVParser, deepClone } from "@packages/utils";

type DataGridSearch = {
  setSearchTerm: (term: string) => void;
  exportDataAsCsv?: (grid: GridReadyEvent, csvExportParams: CsvExportParams) => void;
  exportDataAsExcel?: (grid: GridReadyEvent, excelExportParams: ExcelExportParams) => void;
  serverSideDatasource: IServerSideDatasource;
  debouncedSearchValue?: string;
};

type CSVConfig = {
  field?: string;
  headerName?: string;
  valueFormatter?: (data) => void;
};

type DataGridSearchParamsProps<T> = DataGridSearchParams<T> & {
  searchTerm?: string;
  filter?: SearchFilter<T>;
  skipKeywordList?: string[];
  sortConfig?: { [key: string]: string };
  search: (searchParams: DataGridSearchParams<T>) => Promise<ApiListResponse<T>>;
  exportBlockSize?: number;
  csvConfig?: CSVConfig[];
};

export async function downloadCSV(data, csvConfig, fileName) {
  // Convert data to CSV
  const headers = csvConfig.map((config) => config.headerName).join(",");
  const getNestedObject = (nestedObj, pathArr) => {
    return pathArr.reduce(
      (obj, key) => (obj && obj[key] !== "undefined" ? obj[key] : undefined),
      nestedObj
    );
  };
  const rows = data.map((row) => {
    return csvConfig.map((col) => {
      const key = col.field.split(".");
      const value = key?.length ? getNestedObject(row, key) : row;
      return col.valueFormatter ? col.valueFormatter(value) : value;
    });
  });
  const csvOpts = {
    header: false
  };
  const parser = new CSVParser(csvOpts);
  const csvContent = parser.parse(rows);

  const csv = headers.concat("\n", csvContent);
  // Create Blob and Download
  const blob = new Blob([csv], { type: "text/csv" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = fileName || "data.csv";
  link.click();
  URL.revokeObjectURL(url); // Cleanup
}

export const useGetServerSideDataSource = <T>({
  search,
  skipKeywordList,
  sortConfig,
  filters,
  size,
  csvConfig,
  exportBlockSize
}: DataGridSearchParamsProps<T>): DataGridSearch => {
  const [searchTerm, setSearchTerm] = useState<string>("");

  const { enqueueSnackbar } = useSnackbar();

  const debouncedSearchValue = useDebounce(searchTerm, 300);

  const serverSideDatasource: IServerSideDatasource = useMemo(() => {
    return {
      getRows: async (params: IServerSideGetRowsParams) => {
        try {
          params.api.showLoadingOverlay();
          const filter = deepClone(filters) || {};
          Object.keys(params.request.filterModel).map((key) => {
            if (params.request.filterModel[key].values?.length) {
              filter[key] = params.request.filterModel[key].values;
            }
          });

          const nextRows = await search({
            searchTerm: debouncedSearchValue,
            filters: filter,
            from: params.request.startRow,
            size: size || CACHE_BLOCK_SIZE,
            sortModel: getDataGridSortModel(params.request.sortModel, skipKeywordList, sortConfig)
          });

          params.success({ rowData: nextRows?.data, rowCount: nextRows?.total });
          if (!nextRows?.data?.length) {
            params.api.showNoRowsOverlay();
          } else {
            params.api.hideOverlay();
          }
        } catch (error) {
          params.fail();
          params.api.hideOverlay();
        }
      }
    };
  }, [debouncedSearchValue, filters, skipKeywordList, sortConfig]);

  const getAllData = useCallback(
    async (params: GridReadyEvent) => {
      const filter = deepClone(filters) || {};
      const filterModel = params.api.getFilterModel();
      Object.keys(filterModel).map((key) => {
        if (filterModel[key].values?.length) {
          filter[key] = filterModel[key].values;
        }
      });
      const pageSize = exportBlockSize || 500;
      const allData = [];
      const totalData = params.api.getDisplayedRowCount();
      const sortModel = params.api.getColumnState().filter((s) => s.sort) as SortModelItem[];
      const dataGridSortModel = getDataGridSortModel(sortModel, skipKeywordList, sortConfig);

      const totalPage = Math.ceil(totalData / pageSize);
      const reqQueue = [];

      for (let currentPage = 0; currentPage < totalPage; currentPage++) {
        reqQueue.push(
          search({
            searchTerm: debouncedSearchValue,
            filters: filter,
            from: currentPage ? currentPage * pageSize : 0,
            size: pageSize,
            sortModel: dataGridSortModel
          })
        );
      }

      await Promise.all(reqQueue).then((result) => {
        result.forEach((data) => {
          allData.push(...data.data);
        });
      });

      return allData;
    },
    [debouncedSearchValue, filters, skipKeywordList, sortConfig, exportBlockSize, csvConfig]
  );

  const exportDataAsCsv = useCallback(
    async (params: GridReadyEvent, csvExportParams: CsvExportParams) => {
      try {
        const allData = await getAllData(params);
        await downloadCSV(allData, csvConfig, csvExportParams?.fileName);
        enqueueSnackbar(`Csv file ${csvExportParams?.fileName} downloaded successfully.`, {
          variant: "success"
        });
      } catch (error) {
        enqueueSnackbar(`Unable to create ${csvExportParams?.fileName} file. Please try again.`, {
          variant: "error"
        });
      }
    },
    [getAllData]
  );

  const exportDataAsExcel = useCallback(
    async (params: GridReadyEvent, excelExportParams: ExcelExportParams) => {
      try {
        params.api.showLoadingOverlay();
        const allData = await getAllData(params);
        await params.api.applyServerSideRowData({
          successParams: {
            rowData: allData,
            rowCount: allData?.length
          }
        });
        await params?.api?.exportDataAsExcel(excelExportParams);
        params.api.hideOverlay();
        enqueueSnackbar(`Excel file ${excelExportParams?.fileName} downloaded successfully.`, {
          variant: "success"
        });
      } catch (error) {
        enqueueSnackbar(`Unable to create ${excelExportParams?.fileName} file. Please try again.`, {
          variant: "error"
        });
      }
    },
    [getAllData]
  );

  return {
    setSearchTerm,
    exportDataAsCsv,
    exportDataAsExcel,
    serverSideDatasource,
    debouncedSearchValue
  };
};
