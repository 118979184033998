import { useMemo } from "react";
import { CaseManagementDataGrid } from "./CaseManagementDataGrid";
import { _case } from "@packages/case-management-queries";
import { SearchFilter } from "@packages/service-api";
import { BaseCaseManagementDataGridPageProps } from "./CaseManagementTypes";
import { CasePreviewDrawerPage } from "./CasePreviewDrawer";

export type CaseManagementSubscriptionsDataGridPageProps = BaseCaseManagementDataGridPageProps;

export function CaseManagementSubscriptionsDataGridPage({
  rowActions,
  appBarHeight
}: CaseManagementSubscriptionsDataGridPageProps) {
  // TODO: This filter should come from user preferences.
  const filters = useMemo(() => {
    return {
      "caseStage.title": "Submitted"
    } as SearchFilter<_case>;
  }, []);

  const defaultVisibleColumns = useMemo(() => {
    return [
      "caseId",
      "site",
      "asset",
      "description",
      "initiatingAction",
      "event",
      "impact",
      "severity",
      "type",
      "openedBy",
      "openedDate",
      "status"
    ];
  }, []);

  return (
    <CasePreviewDrawerPage appBarHeight={appBarHeight}>
      {(setSelected) => {
        return (
          <CaseManagementDataGrid
            filters={filters}
            defaultVisibleColumns={defaultVisibleColumns}
            rowActions={rowActions}
            onRowSelected={setSelected}
          />
        );
      }}
    </CasePreviewDrawerPage>
  );
}
