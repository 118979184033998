import {
  ApplicationIcon,
  AttachmentFormValues,
  Box,
  Button,
  ButtonProps,
  IconButton
} from "@packages/theme-mui-v5";
import { Clear } from "@mui/icons-material";
import { CaseFileAttachmentType } from "./CaseManagementTypes";
import { useEffect, useState } from "react";
import { AttachmentType } from "@packages/utils";

export type CaseFileAttachmentProps = {
  attachment: CaseFileAttachmentType;
  onRemove?: (attachment: AttachmentFormValues) => void;
};

export function CaseFileAttachment({ attachment, onRemove }: CaseFileAttachmentProps) {
  if (!attachment) {
    return null;
  }

  const isLink = attachment.data.attachmentType === AttachmentType.Link;

  if (attachment.type === "existing") {
    return (
      <Button
        component="a"
        href={attachment.data.attachmentLocation}
        target="_blank"
        rel="noreferrer"
        startIcon={
          <ApplicationIcon name={getAttachmentTypeIconName(attachment.data.attachmentType)} />
        }
      >
        {attachment.data.fileName}
      </Button>

      // TODO onRemove
      //   {onRemove && (
      //     <IconButton onClick={() => onRemove(attachment.data)}>
      //       <Clear />
      //     </IconButton>
      //   )}
    );
  }

  return (
    <Box display="flex" gap={1} alignItems="center">
      {isLink ? (
        <Button
          component="a"
          href={attachment.data.uri}
          target="_blank"
          rel="noreferrer"
          startIcon={<ApplicationIcon name="link" />}
        >
          {attachment.data.fileName}
        </Button>
      ) : (
        <BlobUrlButton
          file={attachment.data.attachment}
          startIcon={
            <ApplicationIcon name={getAttachmentTypeIconName(attachment.data.attachmentType)} />
          }
        >
          {attachment.data.fileName}
        </BlobUrlButton>
      )}

      {onRemove && (
        <IconButton onClick={() => onRemove(attachment.data)}>
          <Clear />
        </IconButton>
      )}
    </Box>
  );
}

function getAttachmentTypeIconName(attachmentType: AttachmentType) {
  switch (attachmentType) {
    case AttachmentType.Image:
      return "image";
    case AttachmentType.Audio:
      return "audio";
    case AttachmentType.Video:
      return "video";
    case AttachmentType.Document:
      return "text";
    case AttachmentType.Link:
    default:
      return "link";
  }
}

/** Takes a `file`, generatates a blob url, and renders a `Button` that will open the blob in a new tab. */
function BlobUrlButton({ file, ...buttonProps }: { file: File } & ButtonProps) {
  const [url, setUrl] = useState<string | null>(null);

  useEffect(() => {
    const url = URL.createObjectURL(file);
    setUrl(url);

    return () => {
      URL.revokeObjectURL(url);
    };
  }, [file]);

  if (!url) {
    return <>Loading...</>;
  }

  return <Button component="a" href={url} target="_blank" rel="noreferrer" {...buttonProps} />;
}
