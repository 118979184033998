export const MockResponseCommonDataPeople = {
  data: [
    {
      personId: "c365af77-af05-414a-96cf-d53f8c6d6edb",
      userName: "jbrett",
      lastName: "Brett",
      firstName: "John",
      siteId: "ba17173e-320b-494b-ae56-648f94c6a2b3",
      jobTitle: "Crop Consultant",
      supervisorId: "mwomack",
      email: "John.Brett@nutrien.com",
      isActive: "true",
      isContractor: "false",
      createdOn: "2024-09-30T16:06:34.569818Z",
      createdBy: "AD",
      modifiedOn: "2024-09-30T16:06:34.569818Z",
      modifiedBy: "AD",
      isSysAdmin: "false",
      bypassSync: "false",
      isApplicationUser: "false",
      fullName: "John Brett"
    },
    {
      personId: "f6890bc2-eddd-4e13-891b-5ae258b5fed9",
      employeeId: "01045249",
      userName: "br9003988",
      lastName: "Dach",
      firstName: "Brett",
      siteId: "2d2adeef-d697-48b0-af80-70fd81fb9651",
      jobTitle: "Technician, Loadout",
      supervisorId: "st01046551",
      email: "brett.dach@nutrien.com",
      isActive: "false",
      isContractor: "false",
      createdOn: "2022-05-18T14:26:59.16Z",
      createdBy: "AD",
      modifiedOn: "2022-08-16T17:41:25.852021Z",
      modifiedBy: "AD",
      isSysAdmin: "false",
      bypassSync: "false",
      isApplicationUser: "false",
      fullName: "Brett Dach"
    },
    {
      personId: "8c45b259-c078-4f83-8edc-df4d6707184a",
      userName: "br9519000",
      lastName: "Elmgren",
      firstName: "Brett",
      siteId: "5e5d10a2-4eac-495e-b08d-f817b41d755e",
      jobTitle: "(Contractor) Senior Leadership Consultant (CAD)",
      supervisorId: "RSosiak",
      email: "Brett.Elmgren@nutrien.com",
      isActive: "false",
      isContractor: "false",
      createdOn: "2022-05-18T14:26:19.413Z",
      createdBy: "AD",
      modifiedOn: "2022-09-06T15:40:07.437049Z",
      modifiedBy: "AD",
      isSysAdmin: "false",
      bypassSync: "false",
      isApplicationUser: "false",
      fullName: "Brett Elmgren"
    },
    {
      personId: "57ead4a4-9e4a-457a-acce-f348c9d59c30",
      userName: "br7502439",
      lastName: "Nesbitt",
      firstName: "Brett",
      siteId: "99cd1c21-ca2a-489b-b4c4-ef46b8ae4bbf",
      jobTitle: "Operations Manager",
      supervisorId: "AOrcutt",
      email: "brett.nesbitt@nutrien.com",
      isActive: "true",
      isContractor: "false",
      createdOn: "2024-09-30T16:06:34.569818Z",
      createdBy: "AD",
      modifiedOn: "2024-09-30T16:06:34.569818Z",
      modifiedBy: "AD",
      isSysAdmin: "false",
      bypassSync: "false",
      isApplicationUser: "false",
      fullName: "Brett Nesbitt"
    },
    {
      personId: "6795bbde-9e8e-419b-a21e-317292ae2303",
      userName: "brennb",
      lastName: "Brennan",
      firstName: "Brett",
      siteId: "b0239b71-3e37-485c-ae3b-30f2f292fbb3",
      jobTitle: "Merchandise Sales Assistant",
      supervisorId: "norgap",
      email: "brettb@greatnorthernrural.com.au",
      isActive: "true",
      isContractor: "false",
      createdOn: "2024-09-30T16:06:34.569818Z",
      createdBy: "AD",
      modifiedOn: "2024-09-30T16:06:34.569818Z",
      modifiedBy: "AD",
      isSysAdmin: "false",
      bypassSync: "false",
      isApplicationUser: "false",
      fullName: "Brett Brennan"
    },
    {
      personId: "3b2b2890-dc63-4800-8a6a-86b53466cb6d",
      userName: "br9826311",
      lastName: "Bieber",
      firstName: "Brett",
      siteId: "4d27bce2-01ec-44c0-9aeb-a4ed4cbf39a8",
      supervisorId: "GSeverin",
      isActive: "false",
      isContractor: "true",
      createdOn: "2023-05-17T21:58:23.106871Z",
      createdBy: "AD",
      modifiedOn: "2023-12-22T00:25:29.481518Z",
      modifiedBy: "AD",
      isSysAdmin: "false",
      bypassSync: "false",
      isApplicationUser: "false",
      fullName: "Brett Bieber"
    },
    {
      personId: "af6fd14e-f885-404a-b37d-9bc815e1b34b",
      userName: "kuhnb",
      lastName: "Kuhn",
      firstName: "Brett",
      middleName: "L",
      siteId: "b0239b71-3e37-485c-ae3b-30f2f292fbb3",
      jobTitle: "Storeperson Delivery Driver",
      supervisorId: "francisr",
      email: "Brett.Kuhn@Nutrien.com.au",
      isActive: "true",
      isContractor: "false",
      createdOn: "2024-09-30T16:06:34.569818Z",
      createdBy: "AD",
      modifiedOn: "2024-09-30T16:06:34.569818Z",
      modifiedBy: "AD",
      isSysAdmin: "false",
      bypassSync: "false",
      isApplicationUser: "false",
      fullName: "Brett Kuhn"
    },
    {
      personId: "68076d0b-0627-44a2-aa8e-e571f8ce08e2",
      userName: "Lgn1Bg",
      lastName: "Gobolos",
      firstName: "Brett",
      siteId: "e764835c-f13e-487e-a6d8-7d1f39783a28",
      jobTitle: "Mine Construction Supv",
      supervisorId: "ptl2ln",
      email: "Brett.Gobolos@nutrien.com",
      isActive: "true",
      isContractor: "false",
      createdOn: "2022-05-18T14:26:26.01Z",
      createdBy: "AD",
      modifiedOn: "2024-09-30T16:06:34.569818Z",
      modifiedBy: "AD",
      isSysAdmin: "false",
      bypassSync: "false",
      isApplicationUser: "false",
      fullName: "Brett Gobolos"
    },
    {
      personId: "f675a559-914b-447d-9670-a4b41e186485",
      employeeId: "019604",
      userName: "ptr3BR",
      lastName: "Rittaler",
      firstName: "Brett",
      siteId: "9addfce4-0492-4623-9525-6d162f7e6aff",
      jobTitle: "Welder",
      supervisorId: "ptr4CD",
      email: "Brett.Rittaler@nutrien.com",
      isActive: "true",
      isContractor: "false",
      createdOn: "2022-05-18T14:27:03.473Z",
      createdBy: "AD",
      modifiedOn: "2024-09-30T16:06:34.569818Z",
      modifiedBy: "AD",
      isSysAdmin: "false",
      bypassSync: "false",
      isApplicationUser: "false",
      fullName: "Brett Rittaler"
    },
    {
      personId: "b541c4ed-d118-467d-9dcd-74b05aa6a9d4",
      employeeId: "016121",
      userName: "aln2bxb",
      lastName: "Browatzke",
      firstName: "Brett",
      siteId: "f2b4720a-c5e2-462f-81b4-c70bde4dd5e8",
      jobTitle: "Mine Engineer III",
      supervisorId: "pth5cs",
      email: "Brett.Browatzke@nutrien.com",
      isActive: "true",
      isContractor: "false",
      createdOn: "2022-05-18T14:26:19.257Z",
      createdBy: "AD",
      modifiedOn: "2024-09-30T16:06:34.569818Z",
      modifiedBy: "AD",
      isSysAdmin: "false",
      bypassSync: "false",
      isApplicationUser: "false",
      fullName: "Brett Browatzke"
    }
  ],
  hasMore: false,
  total: 10
};
