import { useForm } from "@tanstack/react-form";
import { yupValidator } from "@tanstack/yup-form-adapter";
import {
  Box,
  Button,
  DateTimePicker,
  LoadingButton,
  Paper,
  TextFieldRaw
} from "@packages/theme-mui-v5";
import { CommonDataAutocompletePeople } from "@packages/commondata";
import { getDateValueAsISOString, getFieldStateMUIProps } from "@packages/core";
import { CaseStatusSelectField } from "./CaseStatusSelectField";

const defaultValuesFallback = {
  createdBy: "",
  assignedBy: "",
  status: "",
  caseId: "",
  startDate: "",
  endDate: "",
  dueDateFrom: "",
  dueDateTo: ""
};

export type CaseManagementSearchFormType = typeof defaultValuesFallback;

export type CaseManagementSearchFormProps = {
  defaultValues?: CaseManagementSearchFormType;
  onSubmit?: (values: CaseManagementSearchFormType) => void;

  /** Text to display on the submit button. */
  submitText?: string;

  /** If `true`, the create action button will be disabled. */
  isBusy?: boolean;
};

export function CaseManagementSearchForm({
  defaultValues = defaultValuesFallback,
  onSubmit,
  submitText = "Search",
  isBusy = false
}: CaseManagementSearchFormProps) {
  const form = useForm({
    defaultValues,
    onSubmit: ({ value }) => onSubmit(value),
    validatorAdapter: yupValidator()
  });

  return (
    <Paper
      component="form"
      sx={{ p: 2 }}
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        void form.handleSubmit();
      }}
    >
      <Box
        sx={{
          display: "grid",
          rowGap: 1,
          columnGap: 4,
          alignItems: "center",
          gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
          mb: 2
        }}
      >
        <form.Field name="createdBy">
          {(field) => {
            return (
              <CommonDataAutocompletePeople
                label="Created By"
                {...getFieldStateMUIProps(field)}
                onChange={(_, user) => field.handleChange(user.email)}
              />
            );
          }}
        </form.Field>
        <form.Field name="assignedBy">
          {(field) => {
            return (
              <CommonDataAutocompletePeople
                label="Assigned By"
                {...getFieldStateMUIProps(field)}
                onChange={(_, user) => field.handleChange(user.email)}
              />
            );
          }}
        </form.Field>
        <form.Field name="status">
          {(field) => {
            return (
              <CaseStatusSelectField {...getFieldStateMUIProps(field)} value={field.state.value} />
            );
          }}
        </form.Field>
        <form.Field name="caseId">
          {(field) => {
            return (
              <TextFieldRaw
                label="Case ID"
                size="small"
                variant="standard"
                margin="normal"
                InputLabelProps={{ shrink: true }}
                {...getFieldStateMUIProps(field)}
                value={field.state.value}
                onChange={(e) => field.handleChange(e.target.value)}
              />
            );
          }}
        </form.Field>
      </Box>
      <Box
        sx={{
          display: "grid",
          rowGap: 1,
          columnGap: 4,
          alignItems: "center",
          gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))"
        }}
      >
        <form.Field name="startDate">
          {(field) => {
            const { onChange, value, ...textFieldProps } = getFieldStateMUIProps(field);
            const valueAsDate = value !== null ? new Date(value) : null;
            return (
              <DateTimePicker
                label="Start Date From"
                disableFuture
                slotProps={{
                  textField: {
                    ...textFieldProps,
                    variant: "standard",
                    size: "small",
                    helperText: textFieldProps.helperText,
                    InputLabelProps: { shrink: true }
                  }
                }}
                // adapt component `Date` vs. form ISOString value
                value={valueAsDate}
                onChange={(value) => onChange(getDateValueAsISOString(value))}
              />
            );
          }}
        </form.Field>
        <form.Field name="endDate">
          {(field) => {
            const { onChange, value, ...textFieldProps } = getFieldStateMUIProps(field);
            const valueAsDate = value !== null ? new Date(value) : null;
            return (
              <DateTimePicker
                label="End Date To"
                disableFuture
                slotProps={{
                  textField: {
                    ...textFieldProps,
                    variant: "standard",
                    size: "small",
                    helperText: textFieldProps.helperText,
                    InputLabelProps: { shrink: true }
                  }
                }}
                // adapt component `Date` vs. form ISOString value
                value={valueAsDate}
                onChange={(value) => onChange(getDateValueAsISOString(value))}
              />
            );
          }}
        </form.Field>

        <form.Field name="dueDateFrom">
          {(field) => {
            const { onChange, value, ...textFieldProps } = getFieldStateMUIProps(field);
            const valueAsDate = value !== null ? new Date(value) : null;
            return (
              <DateTimePicker
                label="Due Date From"
                disableFuture
                slotProps={{
                  textField: {
                    ...textFieldProps,
                    variant: "standard",
                    size: "small",
                    helperText: textFieldProps.helperText,
                    InputLabelProps: { shrink: true }
                  }
                }}
                // adapt component `Date` vs. form ISOString value
                value={valueAsDate}
                onChange={(value) => onChange(getDateValueAsISOString(value))}
              />
            );
          }}
        </form.Field>
        <form.Field name="dueDateTo">
          {(field) => {
            const { onChange, value, ...textFieldProps } = getFieldStateMUIProps(field);
            const valueAsDate = value !== null ? new Date(value) : null;
            return (
              <DateTimePicker
                label="Due Date To"
                disableFuture
                slotProps={{
                  textField: {
                    ...textFieldProps,
                    variant: "standard",
                    size: "small",
                    helperText: textFieldProps.helperText,
                    InputLabelProps: { shrink: true }
                  }
                }}
                // adapt component `Date` vs. form ISOString value
                value={valueAsDate}
                onChange={(value) => onChange(getDateValueAsISOString(value))}
              />
            );
          }}
        </form.Field>
      </Box>
      <Box sx={{ display: "flex", gap: 2 }}>
        <form.Subscribe selector={(state) => [state.isSubmitting]}>
          {([isSubmitting]) => (
            <LoadingButton
              variant="contained"
              type="submit"
              disabled={isSubmitting || isBusy}
              loading={isSubmitting || isBusy}
            >
              {submitText}
            </LoadingButton>
          )}
        </form.Subscribe>
        <Button color="error" onClick={() => form.reset()}>
          Clear
        </Button>
      </Box>
    </Paper>
  );
}
