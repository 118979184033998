// caseCommunications

import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineDot,
  TimelineContent,
  Typography,
  timelineItemClasses
} from "@packages/theme-mui-v5";
import { _case } from "@packages/case-management-queries";
import { formatDateTimeShort } from "@packages/core";
import { useMemo } from "react";

export type CaseCommunicationsTimelineProps = {
  case: _case;
};

export function CaseCommunicationsTimeline(props: CaseCommunicationsTimelineProps) {
  const sorted = useMemo(() => {
    return props.case.caseCommunications.sort((a, b) => {
      return new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime();
    });
  }, [props.case]);

  if (sorted.length === 0) {
    return (
      <Typography
        p={2}
        variant="body2"
        color="text.secondary"
        component="div"
        sx={{ margin: "auto" }}
      >
        No Posts
      </Typography>
    );
  }

  return (
    <Timeline
      position="right"
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0
        }
      }}
    >
      {sorted.map((stage, index) => {
        // const isFirst = index === 0;
        // const isLast = index === sorted.length - 1;
        return (
          <TimelineItem key={index} /* sx={{ display: "flex", justifyContent: "space-between" }} */>
            <TimelineSeparator>
              <TimelineConnector /* hidden={isFirst} */ />
              <TimelineDot color="primary" /* variant="outlined" */>
                {/* <InsertChartRounded /> */}
              </TimelineDot>
              <TimelineConnector /* invisi={isLast}  */ />
            </TimelineSeparator>
            <TimelineContent sx={{ py: "12px", px: 2 }}>
              <Typography variant="caption" color="text.secondary">
                {formatDateTimeShort(stage.createdOn)}
              </Typography>
              <Typography variant="body2">{stage.text || "No comment provided."}</Typography>
              <Typography variant="caption" color="text.secondary">
                {stage.createdBy}
              </Typography>
            </TimelineContent>
          </TimelineItem>
        );
      })}
    </Timeline>
  );
}
