import { Box } from "@packages/theme-mui-v5";
import { CasesChartCard } from "./CasesChartCard";

const mockChartData = {
  title: "Cases By Stage (Mocked)",
  labelKey: "stage" as const,
  valueKey: "total" as const,
  data: [
    { stage: "Submitted", total: 4 },
    { stage: "Assigned", total: 4 },
    { stage: "Validate", total: 3 },
    { stage: "Investigate", total: 24 },
    { stage: "Collaborate", total: 2 },
    { stage: "Closed", total: 5 }
  ]
};

export function CaseManagementDashboardPage() {
  return (
    <Box
      display="flex"
      flexDirection="row"
      gap={2}
      flexWrap="wrap"
      sx={{ maxWidth: 1600, margin: "0 auto" }}
    >
      {Array.from({ length: 8 }).map((_, index) => {
        return (
          <CasesChartCard key={index} {...mockChartData} sx={{ flex: 1, flexBasis: "320px" }} />
        );
      })}
    </Box>
  );
}
