export const MockResponseAcceleratorPersonasGet = {
  data: [
    {
      code: "FE",
      createdOn: "2024-06-11T20:57:33.954047Z",
      deletedOn: null,
      modifiedOn: "2024-06-11T20:57:33.954047Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "Fire Equipment Engineer",
      description: null,
      id: "38330c43-7b3c-40cc-9bae-ee15c0d39f12"
    },
    {
      code: "OP",
      createdOn: "2024-06-11T20:57:33.954047Z",
      deletedOn: null,
      modifiedOn: "2024-06-11T20:57:33.954047Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "Operations Engineer",
      description: null,
      id: "3e36e56e-8ed2-4bd5-9578-02fd1c4692ef"
    },
    {
      code: "AE",
      createdOn: "2024-01-01T01:00:00Z",
      deletedOn: null,
      modifiedOn: "2024-01-01T01:00:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "Ammonia Engineer/Synthesis Gas Engineer",
      description: null,
      id: "608e976b-9906-4a47-bcd1-d5e961c54ef3"
    },
    {
      code: "WT",
      createdOn: "2024-06-11T20:57:33.954047Z",
      deletedOn: null,
      modifiedOn: "2024-06-11T20:57:33.954047Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "Water Treatment Engineer",
      description: null,
      id: "6a1827e5-ce66-4819-b1ab-54f142eb6eac"
    },
    {
      code: "RE",
      createdOn: "2024-01-01T01:00:00Z",
      deletedOn: null,
      modifiedOn: "2024-01-01T01:00:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "Rotating Equipment Engineer",
      description: null,
      id: "abafbcee-a3e4-42ee-a3c4-f6c355578493"
    },
    {
      code: "IC",
      createdOn: "2024-06-11T20:57:33.954047Z",
      deletedOn: null,
      modifiedOn: "2024-06-11T20:57:33.954047Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "Instrument and Process Control Engineer",
      description: null,
      id: "ed009b83-44f2-4dee-a355-08e56235bce1"
    }
  ],
  hasMore: false,
  total: 6
};

export const MockResponseAcceleratorSitesGet = {
  data: [
    {
      siteCode: "FNO",
      plotly: "http://10.202.34.100:8090/FNO?asset_name=@AssetCode",
      powerBi:
        "https://app.powerbi.com/groups/me/apps/ad325a73-3cd7-49cb-8de2-370ac15601d5/reports/2cda8ec6-0d5a-4697-9a68-d981866cdacd/ReportSectionf2ba6970b8310a3aaa7a?filter=Site/site eq 'FNO' and Asset_Train/train eq '@Train' and Asset/asset_id eq '@AssetCode' and Asset/tag_nroc_id eq '@Identifier'",
      sharePoint:
        "https://ui.sharepoint.com/sites/FNO/SitePages/Eve-Search.aspx?eveSearchQuery=FunctionalLocation:@AssetCode*",
      timeZone: "Mountain Standard Time",
      createdOn: "2024-01-01T01:00:00Z",
      deletedOn: null,
      modifiedOn: "2024-01-01T01:00:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "Fort Saskatchewan",
      description: null,
      id: "239f8641-274f-4c4d-af84-f036b4824fa1"
    },
    {
      siteCode: "CNO",
      plotly: null,
      powerBi: null,
      sharePoint:
        "https://ui.sharepoint.com/sites/CNO/SitePages/Eve-Search.aspx?eveSearchQuery=((DcDisciplineDescription:PRO*) AND (DocumentSubType:PID*) AND FunctionalLocation:@AssetCode*)",
      timeZone: "Mountain Standard Time",
      createdOn: "2024-01-01T01:00:00Z",
      deletedOn: null,
      modifiedOn: "2024-01-01T01:00:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "Carseland",
      description: null,
      id: "2ba1d47e-d82d-4ae2-a04a-ca20399a4da7"
    },
    {
      siteCode: "JNO",
      plotly: null,
      powerBi: null,
      sharePoint: null,
      timeZone: "Mountain Standard Time",
      createdOn: "2024-01-01T01:00:00Z",
      deletedOn: null,
      modifiedOn: "2024-01-01T01:00:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "Joffre",
      description: null,
      id: "2d2adeef-d697-48b0-af80-70fd81fb9651"
    },
    {
      siteCode: "GSM",
      plotly: "http://10.202.34.100:8060/GSM?asset_name=@AssetCode",
      powerBi:
        "https://app.powerbi.com/groups/me/apps/ad325a73-3cd7-49cb-8de2-370ac15601d5/reports/2cda8ec6-0d5a-4697-9a68-d981866cdacd/ReportSectionf2ba6970b8310a3aaa7a?filter=Site/site eq 'GSM' and Asset_Train/train eq '@Train' and Asset/asset_id eq '@AssetCode' and Asset/tag_nroc_id eq '@Identifier'",
      sharePoint:
        "https://ui.sharepoint.com/sites/gen/SitePages/Meridian-Search.aspx?searchquery=AssetNumber:@AssetCode*",
      timeZone: "Central Standard Time",
      createdOn: "2024-01-01T01:00:00Z",
      deletedOn: null,
      modifiedOn: "2024-01-01T01:00:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "Geismar",
      description: null,
      id: "3cc78be2-ffa8-49c7-a790-69cc8123893c"
    },
    {
      siteCode: "ALL",
      plotly: null,
      powerBi: null,
      sharePoint: null,
      timeZone: null,
      createdOn: "2024-01-01T01:00:00Z",
      deletedOn: null,
      modifiedOn: "2024-01-01T01:00:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "All Sites",
      description: null,
      id: "413798a5-1805-40c7-a8a5-6bd332eea9e5"
    },
    {
      siteCode: "AGM",
      plotly: "http://10.202.34.100:9010/AGM?asset_name=@AssetCode",
      powerBi:
        "https://app.powerbi.com/groups/f500b677-ce3a-428f-92eb-7b06b0cfa8a3/reports/205e7d68-b706-4fd7-9d91-35e37d58ef1a/ReportSection?filter=AGS_All/Train eq '@Train' and AGS_All/TagNROCID eq '@Identifier'",
      sharePoint:
        "https://ui.sharepoint.com/sites/Augusta/SitePages/EVE-Search.aspx?searchquery=AssetNumber:@AssetCode*",
      timeZone: "Eastern Standard Time",
      createdOn: "2024-01-01T01:00:00Z",
      deletedOn: null,
      modifiedOn: "2024-01-01T01:00:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "Augusta",
      description: null,
      id: "7fd4b725-26a4-4aa5-b66b-a117ac6c3b68"
    },
    {
      siteCode: "TRM",
      plotly: "http://10.202.34.100:9040/TRM?asset_name=@AssetCode",
      powerBi:
        "https://app.powerbi.com/groups/f500b677-ce3a-428f-92eb-7b06b0cfa8a3/reports/8d08d148-22f8-428a-a496-a12d6e56a66b/ReportSection?filter=TRN_All/Train eq '@Train' and TRN_All/TagAssetID eq '@AssetCode' and TRN_All/TagNROCID eq '@Identifier'",
      sharePoint: null,
      timeZone: "Atlantic Standard Time",
      createdOn: "2024-01-01T01:00:00Z",
      deletedOn: null,
      modifiedOn: "2024-01-01T01:00:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "Trinidad",
      description: null,
      id: "95b4e211-37dd-4e69-83f1-ee60e01f2e44"
    },
    {
      siteCode: "AUR",
      plotly: null,
      powerBi: null,
      sharePoint: null,
      timeZone: "Eastern Standard Time",
      createdOn: "2024-01-01T01:00:00Z",
      deletedOn: null,
      modifiedOn: "2024-01-01T01:00:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "Aurora",
      description: null,
      id: "9d667010-544d-418a-8306-759ab7a3070b"
    },
    {
      siteCode: "KFO",
      plotly: null,
      powerBi: null,
      sharePoint: null,
      timeZone: "Pacific Standard Time",
      createdOn: "2024-01-01T01:00:00Z",
      deletedOn: null,
      modifiedOn: "2024-01-01T01:00:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "Kennewick",
      description: null,
      id: "bdab7915-d9aa-4ac7-9825-9aa31535244a"
    },
    {
      siteCode: "RNO",
      plotly: "http://10.202.34.100:9030/RNO?asset_name=@AssetCode",
      powerBi: null,
      sharePoint:
        "https://ui.sharepoint.com/sites/rfo/SitePages/EVE-Search.aspx?eveSearchQuery=FunctionalLocation:@AssetCode*",
      timeZone: "Mountain Standard Time",
      createdOn: "2024-01-01T01:00:00Z",
      deletedOn: null,
      modifiedOn: "2024-01-01T01:00:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "Redwater",
      description: null,
      id: "c27ef737-f553-47d1-badf-fba9205d2b9d"
    },
    {
      siteCode: "WSM",
      plotly: null,
      powerBi: null,
      sharePoint: null,
      timeZone: "Eastern Standard Time",
      createdOn: "2024-01-01T01:00:00Z",
      deletedOn: null,
      modifiedOn: "2024-01-01T01:00:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "White Springs",
      description: null,
      id: "d032a2e0-c630-43f1-a009-8c1e9c0debc3"
    },
    {
      siteCode: "LIM",
      plotly: "http://10.202.34.100:8030/LIM?asset_name=@AssetCode",
      powerBi: null,
      sharePoint:
        "https://ui.sharepoint.com/sites/lim/SitePages/EVE-Search.aspx?searchquery=@AssetCode*",
      timeZone: "Eastern Standard Time",
      createdOn: "2024-01-01T01:00:00Z",
      deletedOn: null,
      modifiedOn: "2024-01-01T01:00:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "Lima",
      description: null,
      id: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6"
    },
    {
      siteCode: "BNO",
      plotly: null,
      powerBi: null,
      sharePoint: null,
      timeZone: "Central Standard Time",
      createdOn: "2024-01-01T01:00:00Z",
      deletedOn: null,
      modifiedOn: "2024-01-01T01:00:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "Borger",
      description: null,
      id: "ee7aaf0e-7655-4371-bed7-dbfb489e5aef"
    },
    {
      siteCode: "ALN",
      plotly: null,
      powerBi: null,
      sharePoint: null,
      timeZone: "Canada Central Standard Time",
      createdOn: "2024-01-01T01:00:00Z",
      deletedOn: null,
      modifiedOn: "2024-01-01T01:00:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "Allan",
      description: null,
      id: "f2b4720a-c5e2-462f-81b4-c70bde4dd5e8"
    },
    {
      siteCode: "VAN",
      plotly: null,
      powerBi: null,
      sharePoint: null,
      timeZone: "Canada Central Standard Time",
      createdOn: "2024-01-01T01:00:00Z",
      deletedOn: null,
      modifiedOn: "2024-01-01T01:00:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "Vanscoy",
      description: null,
      id: "f6522b41-79f5-4349-a8df-55e62f0e5152"
    }
  ],
  hasMore: false,
  total: 15
};

export const MockResponseAcceleratorAssetsSearch = {
  data: [
    {
      assetTypeId: "63df1548-6f86-4dcd-bd0d-bc8c4cec1f93",
      assetSubTypeId: "7f7cb553-9dc2-407d-87cf-7e6a1d29a3e1",
      productionUnitId: "60de2bb9-de7a-4d6f-ab83-8c8fa6276e30",
      siteId: "239f8641-274f-4c4d-af84-f036b4824fa1",
      subAreaId: null,
      plantId: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15",
      assetType: {
        createdOn: "2024-10-16T18:25:24.463181Z",
        deletedOn: null,
        modifiedOn: "2024-10-16T18:25:24.463181Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Motors & Generators",
        description: "Motors & Generators - Synced by Accelerator V2 data migration task",
        id: "63df1548-6f86-4dcd-bd0d-bc8c4cec1f93"
      },
      assetSubType: {
        assetTypeId: "63df1548-6f86-4dcd-bd0d-bc8c4cec1f93",
        assetType: null,
        createdOn: "2021-10-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2022-02-04T12:30:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Induction Motor",
        description: "Induction Motor - Synced by Accelerator V2 data migration task",
        id: "7f7cb553-9dc2-407d-87cf-7e6a1d29a3e1"
      },
      productionUnit: {
        plantId: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15",
        plant: null,
        createdOn: "2024-01-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2024-01-01T01:00:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Granulation",
        description: null,
        id: "60de2bb9-de7a-4d6f-ab83-8c8fa6276e30"
      },
      site: {
        siteCode: "FNO",
        plotly: "http://10.202.34.100:8090/FNO?asset_name=@AssetCode",
        powerBi:
          "https://app.powerbi.com/groups/me/apps/ad325a73-3cd7-49cb-8de2-370ac15601d5/reports/2cda8ec6-0d5a-4697-9a68-d981866cdacd/ReportSectionf2ba6970b8310a3aaa7a?filter=Site/site eq 'FNO' and Asset_Train/train eq '@Train' and Asset/asset_id eq '@AssetCode' and Asset/tag_nroc_id eq '@Identifier'",
        sharePoint:
          "https://ui.sharepoint.com/sites/FNO/SitePages/Eve-Search.aspx?eveSearchQuery=FunctionalLocation:@AssetCode*",
        timeZone: "Mountain Standard Time",
        createdOn: "2024-01-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2024-01-01T01:00:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Fort Saskatchewan",
        description: null,
        id: "239f8641-274f-4c4d-af84-f036b4824fa1"
      },
      subArea: null,
      plant: {
        createdOn: "2024-01-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2024-01-01T01:00:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Urea",
        description: null,
        id: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15"
      },
      assetCode: "FS-B254M",
      plantArea: null,
      train: "B254",
      trainPosition: "1",
      relatedAssetIds: [],
      relatedAssets: [],
      createdOn: "2022-12-08T00:31:00Z",
      deletedOn: null,
      modifiedOn: "2022-12-08T00:31:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "B254M",
      description: "FLUID AIR FAN MOTOR COOLER/AMMETER",
      id: "97ab8ee0-3ef9-7b9c-05bb-13d4e1d0971f"
    },
    {
      assetTypeId: "63df1548-6f86-4dcd-bd0d-bc8c4cec1f93",
      assetSubTypeId: null,
      productionUnitId: "fb7babb2-350a-409e-8927-718c1cc71498",
      siteId: "239f8641-274f-4c4d-af84-f036b4824fa1",
      subAreaId: null,
      plantId: "f049670b-cb07-4669-83f7-548304691587",
      assetType: {
        createdOn: "2024-10-16T18:25:24.463181Z",
        deletedOn: null,
        modifiedOn: "2024-10-16T18:25:24.463181Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Motors & Generators",
        description: "Motors & Generators - Synced by Accelerator V2 data migration task",
        id: "63df1548-6f86-4dcd-bd0d-bc8c4cec1f93"
      },
      assetSubType: null,
      productionUnit: {
        plantId: "f049670b-cb07-4669-83f7-548304691587",
        plant: null,
        createdOn: "2024-01-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2024-01-01T01:00:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Ammonia",
        description: null,
        id: "fb7babb2-350a-409e-8927-718c1cc71498"
      },
      site: {
        siteCode: "FNO",
        plotly: "http://10.202.34.100:8090/FNO?asset_name=@AssetCode",
        powerBi:
          "https://app.powerbi.com/groups/me/apps/ad325a73-3cd7-49cb-8de2-370ac15601d5/reports/2cda8ec6-0d5a-4697-9a68-d981866cdacd/ReportSectionf2ba6970b8310a3aaa7a?filter=Site/site eq 'FNO' and Asset_Train/train eq '@Train' and Asset/asset_id eq '@AssetCode' and Asset/tag_nroc_id eq '@Identifier'",
        sharePoint:
          "https://ui.sharepoint.com/sites/FNO/SitePages/Eve-Search.aspx?eveSearchQuery=FunctionalLocation:@AssetCode*",
        timeZone: "Mountain Standard Time",
        createdOn: "2024-01-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2024-01-01T01:00:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Fort Saskatchewan",
        description: null,
        id: "239f8641-274f-4c4d-af84-f036b4824fa1"
      },
      subArea: null,
      plant: {
        createdOn: "2024-01-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2024-01-01T01:00:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Ammonia",
        description: null,
        id: "f049670b-cb07-4669-83f7-548304691587"
      },
      assetCode: "FS-2103LAJ1M",
      plantArea: null,
      train: null,
      trainPosition: null,
      relatedAssetIds: [],
      relatedAssets: [],
      createdOn: "2022-12-08T00:31:00Z",
      deletedOn: null,
      modifiedOn: "2022-12-08T00:31:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "2103LAJ1M",
      description: "SC: COMPRESSOR FIRST AMM. STORAGE 2103LA",
      id: "f3b66a5a-44b4-7101-9af2-84ec70bd928d"
    },
    {
      assetTypeId: "88e28015-0299-40cb-d0f4-08da769a9021",
      assetSubTypeId: "26b62d25-2ea0-fa12-7e48-2812e62849d6",
      productionUnitId: "9364f296-763e-43b0-ad6c-81f53f2b5dd7",
      siteId: "95b4e211-37dd-4e69-83f1-ee60e01f2e44",
      subAreaId: "4c7ca1af-e3bc-4045-b997-ac799b98edc1",
      plantId: "f049670b-cb07-4669-83f7-548304691587",
      assetType: {
        createdOn: "2022-08-05T04:26:00Z",
        deletedOn: null,
        modifiedOn: "2022-08-05T04:26:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Valve",
        description: "Valve - Synced by Accelerator V2 data migration task",
        id: "88e28015-0299-40cb-d0f4-08da769a9021"
      },
      assetSubType: {
        assetTypeId: "88e28015-0299-40cb-d0f4-08da769a9021",
        assetType: null,
        createdOn: "2022-09-26T18:41:00Z",
        deletedOn: null,
        modifiedOn: "2022-09-27T13:33:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Shutoff Valve / Motor",
        description: "Shutoff Valve / Motor - Synced by Accelerator V2 data migration task",
        id: "26b62d25-2ea0-fa12-7e48-2812e62849d6"
      },
      productionUnit: {
        plantId: "f049670b-cb07-4669-83f7-548304691587",
        plant: null,
        createdOn: "2024-01-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2024-01-01T01:00:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Ammonia 2",
        description: null,
        id: "9364f296-763e-43b0-ad6c-81f53f2b5dd7"
      },
      site: {
        siteCode: "TRM",
        plotly: "http://10.202.34.100:9040/TRM?asset_name=@AssetCode",
        powerBi:
          "https://app.powerbi.com/groups/f500b677-ce3a-428f-92eb-7b06b0cfa8a3/reports/8d08d148-22f8-428a-a496-a12d6e56a66b/ReportSection?filter=TRN_All/Train eq '@Train' and TRN_All/TagAssetID eq '@AssetCode' and TRN_All/TagNROCID eq '@Identifier'",
        sharePoint: null,
        timeZone: "Atlantic Standard Time",
        createdOn: "2024-01-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2024-01-01T01:00:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Trinidad",
        description: null,
        id: "95b4e211-37dd-4e69-83f1-ee60e01f2e44"
      },
      subArea: {
        productionUnitId: "9364f296-763e-43b0-ad6c-81f53f2b5dd7",
        productionUnit: null,
        createdOn: "2022-02-20T22:45:00Z",
        deletedOn: null,
        modifiedOn: "2022-02-20T22:45:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "SynGas Compression",
        description: null,
        id: "4c7ca1af-e3bc-4045-b997-ac799b98edc1"
      },
      plant: {
        createdOn: "2024-01-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2024-01-01T01:00:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Ammonia",
        description: null,
        id: "f049670b-cb07-4669-83f7-548304691587"
      },
      assetCode: "HCV11---2",
      plantArea: null,
      train: null,
      trainPosition: null,
      relatedAssetIds: [],
      relatedAssets: [],
      createdOn: "2022-08-19T18:40:00Z",
      deletedOn: null,
      modifiedOn: "2023-12-07T23:08:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "HCV-11",
      description: "SYN. GAS TO AMMONICA CONVERTER 105D",
      id: "71dfe722-5283-4fda-b3ad-08da821230dd"
    },
    {
      assetTypeId: "63df1548-6f86-4dcd-bd0d-bc8c4cec1f93",
      assetSubTypeId: "7f7cb553-9dc2-407d-87cf-7e6a1d29a3e1",
      productionUnitId: "60de2bb9-de7a-4d6f-ab83-8c8fa6276e30",
      siteId: "239f8641-274f-4c4d-af84-f036b4824fa1",
      subAreaId: null,
      plantId: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15",
      assetType: {
        createdOn: "2024-10-16T18:25:24.463181Z",
        deletedOn: null,
        modifiedOn: "2024-10-16T18:25:24.463181Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Motors & Generators",
        description: "Motors & Generators - Synced by Accelerator V2 data migration task",
        id: "63df1548-6f86-4dcd-bd0d-bc8c4cec1f93"
      },
      assetSubType: {
        assetTypeId: "63df1548-6f86-4dcd-bd0d-bc8c4cec1f93",
        assetType: null,
        createdOn: "2021-10-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2022-02-04T12:30:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Induction Motor",
        description: "Induction Motor - Synced by Accelerator V2 data migration task",
        id: "7f7cb553-9dc2-407d-87cf-7e6a1d29a3e1"
      },
      productionUnit: {
        plantId: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15",
        plant: null,
        createdOn: "2024-01-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2024-01-01T01:00:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Granulation",
        description: null,
        id: "60de2bb9-de7a-4d6f-ab83-8c8fa6276e30"
      },
      site: {
        siteCode: "FNO",
        plotly: "http://10.202.34.100:8090/FNO?asset_name=@AssetCode",
        powerBi:
          "https://app.powerbi.com/groups/me/apps/ad325a73-3cd7-49cb-8de2-370ac15601d5/reports/2cda8ec6-0d5a-4697-9a68-d981866cdacd/ReportSectionf2ba6970b8310a3aaa7a?filter=Site/site eq 'FNO' and Asset_Train/train eq '@Train' and Asset/asset_id eq '@AssetCode' and Asset/tag_nroc_id eq '@Identifier'",
        sharePoint:
          "https://ui.sharepoint.com/sites/FNO/SitePages/Eve-Search.aspx?eveSearchQuery=FunctionalLocation:@AssetCode*",
        timeZone: "Mountain Standard Time",
        createdOn: "2024-01-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2024-01-01T01:00:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Fort Saskatchewan",
        description: null,
        id: "239f8641-274f-4c4d-af84-f036b4824fa1"
      },
      subArea: null,
      plant: {
        createdOn: "2024-01-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2024-01-01T01:00:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Urea",
        description: null,
        id: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15"
      },
      assetCode: "FS-B204M",
      plantArea: null,
      train: "B204",
      trainPosition: "1",
      relatedAssetIds: [],
      relatedAssets: [],
      createdOn: "2022-12-08T00:31:00Z",
      deletedOn: null,
      modifiedOn: "2022-12-08T00:31:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "B204M",
      description: "FLUID AIR FAN MOTOR COOLER/AMMETER",
      id: "496f5292-9cf0-46df-94af-b424c49803db"
    },
    {
      assetTypeId: "d5d9dc10-331f-4d0b-84f1-8942fb7ba23e",
      assetSubTypeId: "2fdfcbda-6924-44c4-bb8e-f3401c57af23",
      productionUnitId: null,
      siteId: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6",
      subAreaId: "e6e1a145-3320-4bb6-926e-0eef31d8490c",
      plantId: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15",
      assetType: {
        createdOn: "2024-10-16T18:25:24.463181Z",
        deletedOn: null,
        modifiedOn: "2024-10-16T18:25:24.463181Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Process Pumps",
        description: "Process Pumps - Synced by Accelerator V2 data migration task",
        id: "d5d9dc10-331f-4d0b-84f1-8942fb7ba23e"
      },
      assetSubType: {
        assetTypeId: "d5d9dc10-331f-4d0b-84f1-8942fb7ba23e",
        assetType: null,
        createdOn: "2021-10-01T02:00:00Z",
        deletedOn: null,
        modifiedOn: "2022-02-04T12:30:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Gear/Screw Positive Displacement Pump",
        description:
          "Gear/Screw Positive Displacement Pump - Synced by Accelerator V2 data migration task",
        id: "2fdfcbda-6924-44c4-bb8e-f3401c57af23"
      },
      productionUnit: null,
      site: {
        siteCode: "LIM",
        plotly: "http://10.202.34.100:8030/LIM?asset_name=@AssetCode",
        powerBi: null,
        sharePoint:
          "https://ui.sharepoint.com/sites/lim/SitePages/EVE-Search.aspx?searchquery=@AssetCode*",
        timeZone: "Eastern Standard Time",
        createdOn: "2024-01-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2024-01-01T01:00:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Lima",
        description: null,
        id: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6"
      },
      subArea: {
        productionUnitId: "5d73c2c1-52a2-4467-919c-9c00d236a3b6",
        productionUnit: null,
        createdOn: "2020-10-15T17:46:00Z",
        deletedOn: null,
        modifiedOn: "2020-10-15T17:46:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Ammonia Feed",
        description: null,
        id: "e6e1a145-3320-4bb6-926e-0eef31d8490c"
      },
      plant: {
        createdOn: "2024-01-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2024-01-01T01:00:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Urea",
        description: null,
        id: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15"
      },
      assetCode: "IP132G.I",
      plantArea: null,
      train: "IP132G MAIN SEAL WATER PUMP",
      trainPosition: "2",
      relatedAssetIds: [
        "6c98edac-9e8c-22ee-e986-0a64aa2d8dfb",
        "06984f68-0695-ed5c-7230-35682d086165",
        "b92f8948-7b4f-1199-2399-719039f94c3d",
        "27a67a9a-cd59-5c49-36cf-99df674643ea",
        "cdec59c9-ded6-64c6-3014-776b4bbc07de",
        "47545cbc-58a4-0824-a3b5-34e2383d92ba"
      ],
      relatedAssets: [
        {
          assetTypeId: "10942d1e-02d1-4a3c-82bc-8bb47bd2a0d3",
          assetSubTypeId: "4d4437d4-c444-4d55-8686-5fe3bf4a32fc",
          productionUnitId: null,
          siteId: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6",
          subAreaId: "e6e1a145-3320-4bb6-926e-0eef31d8490c",
          plantId: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15",
          assetType: {
            createdOn: "2024-10-16T18:25:24.463181Z",
            deletedOn: null,
            modifiedOn: "2024-10-16T18:25:24.463181Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Gearbox",
            description: "Gearbox - Synced by Accelerator V2 data migration task",
            id: "10942d1e-02d1-4a3c-82bc-8bb47bd2a0d3"
          },
          assetSubType: {
            assetTypeId: "10942d1e-02d1-4a3c-82bc-8bb47bd2a0d3",
            assetType: null,
            createdOn: "2021-10-01T02:00:00Z",
            deletedOn: null,
            modifiedOn: "2022-02-04T12:30:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Single Stage Speed Increaser Gearbox",
            description:
              "Single Stage Speed Increaser Gearbox - Synced by Accelerator V2 data migration task",
            id: "4d4437d4-c444-4d55-8686-5fe3bf4a32fc"
          },
          productionUnit: null,
          site: {
            siteCode: "LIM",
            plotly: "http://10.202.34.100:8030/LIM?asset_name=@AssetCode",
            powerBi: null,
            sharePoint:
              "https://ui.sharepoint.com/sites/lim/SitePages/EVE-Search.aspx?searchquery=@AssetCode*",
            timeZone: "Eastern Standard Time",
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Lima",
            description: null,
            id: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6"
          },
          subArea: {
            productionUnitId: "5d73c2c1-52a2-4467-919c-9c00d236a3b6",
            productionUnit: null,
            createdOn: "2020-10-15T17:46:00Z",
            deletedOn: null,
            modifiedOn: "2020-10-15T17:46:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Ammonia Feed",
            description: null,
            id: "e6e1a145-3320-4bb6-926e-0eef31d8490c"
          },
          plant: {
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Urea",
            description: null,
            id: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15"
          },
          assetCode: "IPG132A.I",
          plantArea: null,
          createdOn: "2022-12-08T00:31:00Z",
          deletedOn: null,
          modifiedOn: "2022-12-08T00:31:00Z",
          createdBy: "sa",
          deletedBy: null,
          modifiedBy: "sa",
          name: "AMMONIA FEED PUMP A SPEED INCREASER GEAR, 741228",
          description: "GEAR, AMMONIA FEED PUMP A SPEED INCREASER, 741228",
          id: "6c98edac-9e8c-22ee-e986-0a64aa2d8dfb"
        },
        {
          assetTypeId: "10942d1e-02d1-4a3c-82bc-8bb47bd2a0d3",
          assetSubTypeId: "4d4437d4-c444-4d55-8686-5fe3bf4a32fc",
          productionUnitId: null,
          siteId: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6",
          subAreaId: "e6e1a145-3320-4bb6-926e-0eef31d8490c",
          plantId: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15",
          assetType: {
            createdOn: "2024-10-16T18:25:24.463181Z",
            deletedOn: null,
            modifiedOn: "2024-10-16T18:25:24.463181Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Gearbox",
            description: "Gearbox - Synced by Accelerator V2 data migration task",
            id: "10942d1e-02d1-4a3c-82bc-8bb47bd2a0d3"
          },
          assetSubType: {
            assetTypeId: "10942d1e-02d1-4a3c-82bc-8bb47bd2a0d3",
            assetType: null,
            createdOn: "2021-10-01T02:00:00Z",
            deletedOn: null,
            modifiedOn: "2022-02-04T12:30:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Single Stage Speed Increaser Gearbox",
            description:
              "Single Stage Speed Increaser Gearbox - Synced by Accelerator V2 data migration task",
            id: "4d4437d4-c444-4d55-8686-5fe3bf4a32fc"
          },
          productionUnit: null,
          site: {
            siteCode: "LIM",
            plotly: "http://10.202.34.100:8030/LIM?asset_name=@AssetCode",
            powerBi: null,
            sharePoint:
              "https://ui.sharepoint.com/sites/lim/SitePages/EVE-Search.aspx?searchquery=@AssetCode*",
            timeZone: "Eastern Standard Time",
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Lima",
            description: null,
            id: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6"
          },
          subArea: {
            productionUnitId: "5d73c2c1-52a2-4467-919c-9c00d236a3b6",
            productionUnit: null,
            createdOn: "2020-10-15T17:46:00Z",
            deletedOn: null,
            modifiedOn: "2020-10-15T17:46:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Ammonia Feed",
            description: null,
            id: "e6e1a145-3320-4bb6-926e-0eef31d8490c"
          },
          plant: {
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Urea",
            description: null,
            id: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15"
          },
          assetCode: "IPG132B.I",
          plantArea: null,
          createdOn: "2022-12-08T00:31:00Z",
          deletedOn: null,
          modifiedOn: "2022-12-08T00:31:00Z",
          createdBy: "sa",
          deletedBy: null,
          modifiedBy: "sa",
          name: "AMMONIA FEED PUMP B SPEED INCREASER GEAR, 741229",
          description: "GEAR, AMMONIA FEED PUMP B SPEED INCREASER, 741229",
          id: "06984f68-0695-ed5c-7230-35682d086165"
        },
        {
          assetTypeId: "e0e82e9a-e795-475d-8590-52a0819f1912",
          assetSubTypeId: "b37ee5ef-c5b7-4901-afbb-483b29c9b3ac",
          productionUnitId: null,
          siteId: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6",
          subAreaId: "e6e1a145-3320-4bb6-926e-0eef31d8490c",
          plantId: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15",
          assetType: {
            createdOn: "2024-10-16T18:25:24.463181Z",
            deletedOn: null,
            modifiedOn: "2024-10-16T18:25:24.463181Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Steam Turbines",
            description: "Steam Turbines - Synced by Accelerator V2 data migration task",
            id: "e0e82e9a-e795-475d-8590-52a0819f1912"
          },
          assetSubType: {
            assetTypeId: "e0e82e9a-e795-475d-8590-52a0819f1912",
            assetType: null,
            createdOn: "2021-10-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2022-02-04T12:30:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Horizontal Single Stage Back Pressure Steam Turbine",
            description:
              "Horizontal Single Stage Back Pressure Steam Turbine - Synced by Accelerator V2 data migration task",
            id: "b37ee5ef-c5b7-4901-afbb-483b29c9b3ac"
          },
          productionUnit: null,
          site: {
            siteCode: "LIM",
            plotly: "http://10.202.34.100:8030/LIM?asset_name=@AssetCode",
            powerBi: null,
            sharePoint:
              "https://ui.sharepoint.com/sites/lim/SitePages/EVE-Search.aspx?searchquery=@AssetCode*",
            timeZone: "Eastern Standard Time",
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Lima",
            description: null,
            id: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6"
          },
          subArea: {
            productionUnitId: "5d73c2c1-52a2-4467-919c-9c00d236a3b6",
            productionUnit: null,
            createdOn: "2020-10-15T17:46:00Z",
            deletedOn: null,
            modifiedOn: "2020-10-15T17:46:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Ammonia Feed",
            description: null,
            id: "e6e1a145-3320-4bb6-926e-0eef31d8490c"
          },
          plant: {
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Urea",
            description: null,
            id: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15"
          },
          assetCode: "IT132A.I",
          plantArea: null,
          createdOn: "2022-12-08T00:31:00Z",
          deletedOn: null,
          modifiedOn: "2022-12-08T00:31:00Z",
          createdBy: "sa",
          deletedBy: null,
          modifiedBy: "sa",
          name: "AMMONIA FEED PUMP A TURBINE, 711232",
          description: "TURBINE, AMMONIA FEED PUMP A, 711232",
          id: "b92f8948-7b4f-1199-2399-719039f94c3d"
        },
        {
          assetTypeId: "d5d9dc10-331f-4d0b-84f1-8942fb7ba23e",
          assetSubTypeId: "cb424770-8956-4ab7-9170-e73070860ee6",
          productionUnitId: null,
          siteId: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6",
          subAreaId: "e6e1a145-3320-4bb6-926e-0eef31d8490c",
          plantId: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15",
          assetType: {
            createdOn: "2024-10-16T18:25:24.463181Z",
            deletedOn: null,
            modifiedOn: "2024-10-16T18:25:24.463181Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Process Pumps",
            description: "Process Pumps - Synced by Accelerator V2 data migration task",
            id: "d5d9dc10-331f-4d0b-84f1-8942fb7ba23e"
          },
          assetSubType: {
            assetTypeId: "d5d9dc10-331f-4d0b-84f1-8942fb7ba23e",
            assetType: null,
            createdOn: "2021-10-01T00:00:00Z",
            deletedOn: null,
            modifiedOn: "2022-02-04T12:30:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Between Bearings Centrifugal Pump",
            description:
              "Between Bearings Centrifugal Pump - Synced by Accelerator V2 data migration task",
            id: "cb424770-8956-4ab7-9170-e73070860ee6"
          },
          productionUnit: null,
          site: {
            siteCode: "LIM",
            plotly: "http://10.202.34.100:8030/LIM?asset_name=@AssetCode",
            powerBi: null,
            sharePoint:
              "https://ui.sharepoint.com/sites/lim/SitePages/EVE-Search.aspx?searchquery=@AssetCode*",
            timeZone: "Eastern Standard Time",
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Lima",
            description: null,
            id: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6"
          },
          subArea: {
            productionUnitId: "5d73c2c1-52a2-4467-919c-9c00d236a3b6",
            productionUnit: null,
            createdOn: "2020-10-15T17:46:00Z",
            deletedOn: null,
            modifiedOn: "2020-10-15T17:46:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Ammonia Feed",
            description: null,
            id: "e6e1a145-3320-4bb6-926e-0eef31d8490c"
          },
          plant: {
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Urea",
            description: null,
            id: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15"
          },
          assetCode: "IP132A.I",
          plantArea: null,
          createdOn: "2022-12-08T00:31:00Z",
          deletedOn: null,
          modifiedOn: "2022-12-08T00:31:00Z",
          createdBy: "sa",
          deletedBy: null,
          modifiedBy: "sa",
          name: "EBARA AMMONIA FEED A PUMP (NORTH), 701230",
          description: "PUMP, EBARA AMMONIA FEED A (NORTH), 701230",
          id: "27a67a9a-cd59-5c49-36cf-99df674643ea"
        },
        {
          assetTypeId: "63df1548-6f86-4dcd-bd0d-bc8c4cec1f93",
          assetSubTypeId: "7f7cb553-9dc2-407d-87cf-7e6a1d29a3e1",
          productionUnitId: null,
          siteId: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6",
          subAreaId: "e6e1a145-3320-4bb6-926e-0eef31d8490c",
          plantId: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15",
          assetType: {
            createdOn: "2024-10-16T18:25:24.463181Z",
            deletedOn: null,
            modifiedOn: "2024-10-16T18:25:24.463181Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Motors & Generators",
            description: "Motors & Generators - Synced by Accelerator V2 data migration task",
            id: "63df1548-6f86-4dcd-bd0d-bc8c4cec1f93"
          },
          assetSubType: {
            assetTypeId: "63df1548-6f86-4dcd-bd0d-bc8c4cec1f93",
            assetType: null,
            createdOn: "2021-10-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2022-02-04T12:30:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Induction Motor",
            description: "Induction Motor - Synced by Accelerator V2 data migration task",
            id: "7f7cb553-9dc2-407d-87cf-7e6a1d29a3e1"
          },
          productionUnit: null,
          site: {
            siteCode: "LIM",
            plotly: "http://10.202.34.100:8030/LIM?asset_name=@AssetCode",
            powerBi: null,
            sharePoint:
              "https://ui.sharepoint.com/sites/lim/SitePages/EVE-Search.aspx?searchquery=@AssetCode*",
            timeZone: "Eastern Standard Time",
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Lima",
            description: null,
            id: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6"
          },
          subArea: {
            productionUnitId: "5d73c2c1-52a2-4467-919c-9c00d236a3b6",
            productionUnit: null,
            createdOn: "2020-10-15T17:46:00Z",
            deletedOn: null,
            modifiedOn: "2020-10-15T17:46:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Ammonia Feed",
            description: null,
            id: "e6e1a145-3320-4bb6-926e-0eef31d8490c"
          },
          plant: {
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Urea",
            description: null,
            id: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15"
          },
          assetCode: "IPM132B.I",
          plantArea: null,
          createdOn: "2022-12-08T00:31:00Z",
          deletedOn: null,
          modifiedOn: "2022-12-08T00:31:00Z",
          createdBy: "sa",
          deletedBy: null,
          modifiedBy: "sa",
          name: "AMMONIA FEED PUMP B MOTOR, 721233",
          description: "MOTOR, AMMONIA FEED PUMP B, 721233",
          id: "cdec59c9-ded6-64c6-3014-776b4bbc07de"
        },
        {
          assetTypeId: "d5d9dc10-331f-4d0b-84f1-8942fb7ba23e",
          assetSubTypeId: "cb424770-8956-4ab7-9170-e73070860ee6",
          productionUnitId: null,
          siteId: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6",
          subAreaId: "e6e1a145-3320-4bb6-926e-0eef31d8490c",
          plantId: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15",
          assetType: {
            createdOn: "2024-10-16T18:25:24.463181Z",
            deletedOn: null,
            modifiedOn: "2024-10-16T18:25:24.463181Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Process Pumps",
            description: "Process Pumps - Synced by Accelerator V2 data migration task",
            id: "d5d9dc10-331f-4d0b-84f1-8942fb7ba23e"
          },
          assetSubType: {
            assetTypeId: "d5d9dc10-331f-4d0b-84f1-8942fb7ba23e",
            assetType: null,
            createdOn: "2021-10-01T00:00:00Z",
            deletedOn: null,
            modifiedOn: "2022-02-04T12:30:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Between Bearings Centrifugal Pump",
            description:
              "Between Bearings Centrifugal Pump - Synced by Accelerator V2 data migration task",
            id: "cb424770-8956-4ab7-9170-e73070860ee6"
          },
          productionUnit: null,
          site: {
            siteCode: "LIM",
            plotly: "http://10.202.34.100:8030/LIM?asset_name=@AssetCode",
            powerBi: null,
            sharePoint:
              "https://ui.sharepoint.com/sites/lim/SitePages/EVE-Search.aspx?searchquery=@AssetCode*",
            timeZone: "Eastern Standard Time",
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Lima",
            description: null,
            id: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6"
          },
          subArea: {
            productionUnitId: "5d73c2c1-52a2-4467-919c-9c00d236a3b6",
            productionUnit: null,
            createdOn: "2020-10-15T17:46:00Z",
            deletedOn: null,
            modifiedOn: "2020-10-15T17:46:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Ammonia Feed",
            description: null,
            id: "e6e1a145-3320-4bb6-926e-0eef31d8490c"
          },
          plant: {
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Urea",
            description: null,
            id: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15"
          },
          assetCode: "IP132B.I",
          plantArea: null,
          createdOn: "2022-12-08T00:31:00Z",
          deletedOn: null,
          modifiedOn: "2022-12-08T00:31:00Z",
          createdBy: "sa",
          deletedBy: null,
          modifiedBy: "sa",
          name: "EBARA AMMONIA FEED B PUMP (SOUTH), 701231",
          description: "PUMP, EBARA AMMONIA FEED B (SOUTH), 701231",
          id: "47545cbc-58a4-0824-a3b5-34e2383d92ba"
        }
      ],
      createdOn: "2022-12-08T00:31:00Z",
      deletedOn: null,
      modifiedOn: "2022-12-08T00:31:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "AMMONIA FEED PUMPS A/B SEAL WATER MAIN PUMP, 701255",
      description: "PUMP, AMMONIA FEED PUMPS A/B SEAL WATER MAIN, 701255",
      id: "5e634c92-b8c7-4c53-817c-9d0aa4a3e9e1"
    },
    {
      assetTypeId: "63f8d8d9-f22b-4185-9618-fec5c17f6da9",
      assetSubTypeId: "2913d08f-45f7-49fc-a0aa-254626a7e55e",
      productionUnitId: null,
      siteId: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6",
      subAreaId: null,
      plantId: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15",
      assetType: {
        createdOn: "2024-10-16T18:25:24.463181Z",
        deletedOn: null,
        modifiedOn: "2024-10-16T18:25:24.463181Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Lube and Seal Oil System",
        description: "Lube and Seal Oil System - Synced by Accelerator V2 data migration task",
        id: "63f8d8d9-f22b-4185-9618-fec5c17f6da9"
      },
      assetSubType: {
        assetTypeId: "63f8d8d9-f22b-4185-9618-fec5c17f6da9",
        assetType: null,
        createdOn: "2021-10-01T08:00:00Z",
        deletedOn: null,
        modifiedOn: "2022-02-04T12:30:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Seal Water System",
        description: "Seal Water System - Synced by Accelerator V2 data migration task",
        id: "2913d08f-45f7-49fc-a0aa-254626a7e55e"
      },
      productionUnit: null,
      site: {
        siteCode: "LIM",
        plotly: "http://10.202.34.100:8030/LIM?asset_name=@AssetCode",
        powerBi: null,
        sharePoint:
          "https://ui.sharepoint.com/sites/lim/SitePages/EVE-Search.aspx?searchquery=@AssetCode*",
        timeZone: "Eastern Standard Time",
        createdOn: "2024-01-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2024-01-01T01:00:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Lima",
        description: null,
        id: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6"
      },
      subArea: null,
      plant: {
        createdOn: "2024-01-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2024-01-01T01:00:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Urea",
        description: null,
        id: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15"
      },
      assetCode: "IPU132E.I",
      plantArea: null,
      train: null,
      trainPosition: null,
      relatedAssetIds: [
        "cdec59c9-ded6-64c6-3014-776b4bbc07de",
        "6c98edac-9e8c-22ee-e986-0a64aa2d8dfb",
        "06984f68-0695-ed5c-7230-35682d086165",
        "27a67a9a-cd59-5c49-36cf-99df674643ea",
        "47545cbc-58a4-0824-a3b5-34e2383d92ba",
        "b92f8948-7b4f-1199-2399-719039f94c3d"
      ],
      relatedAssets: [
        {
          assetTypeId: "63df1548-6f86-4dcd-bd0d-bc8c4cec1f93",
          assetSubTypeId: "7f7cb553-9dc2-407d-87cf-7e6a1d29a3e1",
          productionUnitId: null,
          siteId: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6",
          subAreaId: "e6e1a145-3320-4bb6-926e-0eef31d8490c",
          plantId: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15",
          assetType: {
            createdOn: "2024-10-16T18:25:24.463181Z",
            deletedOn: null,
            modifiedOn: "2024-10-16T18:25:24.463181Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Motors & Generators",
            description: "Motors & Generators - Synced by Accelerator V2 data migration task",
            id: "63df1548-6f86-4dcd-bd0d-bc8c4cec1f93"
          },
          assetSubType: {
            assetTypeId: "63df1548-6f86-4dcd-bd0d-bc8c4cec1f93",
            assetType: null,
            createdOn: "2021-10-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2022-02-04T12:30:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Induction Motor",
            description: "Induction Motor - Synced by Accelerator V2 data migration task",
            id: "7f7cb553-9dc2-407d-87cf-7e6a1d29a3e1"
          },
          productionUnit: null,
          site: {
            siteCode: "LIM",
            plotly: "http://10.202.34.100:8030/LIM?asset_name=@AssetCode",
            powerBi: null,
            sharePoint:
              "https://ui.sharepoint.com/sites/lim/SitePages/EVE-Search.aspx?searchquery=@AssetCode*",
            timeZone: "Eastern Standard Time",
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Lima",
            description: null,
            id: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6"
          },
          subArea: {
            productionUnitId: "5d73c2c1-52a2-4467-919c-9c00d236a3b6",
            productionUnit: null,
            createdOn: "2020-10-15T17:46:00Z",
            deletedOn: null,
            modifiedOn: "2020-10-15T17:46:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Ammonia Feed",
            description: null,
            id: "e6e1a145-3320-4bb6-926e-0eef31d8490c"
          },
          plant: {
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Urea",
            description: null,
            id: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15"
          },
          assetCode: "IPM132B.I",
          plantArea: null,
          createdOn: "2022-12-08T00:31:00Z",
          deletedOn: null,
          modifiedOn: "2022-12-08T00:31:00Z",
          createdBy: "sa",
          deletedBy: null,
          modifiedBy: "sa",
          name: "AMMONIA FEED PUMP B MOTOR, 721233",
          description: "MOTOR, AMMONIA FEED PUMP B, 721233",
          id: "cdec59c9-ded6-64c6-3014-776b4bbc07de"
        },
        {
          assetTypeId: "10942d1e-02d1-4a3c-82bc-8bb47bd2a0d3",
          assetSubTypeId: "4d4437d4-c444-4d55-8686-5fe3bf4a32fc",
          productionUnitId: null,
          siteId: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6",
          subAreaId: "e6e1a145-3320-4bb6-926e-0eef31d8490c",
          plantId: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15",
          assetType: {
            createdOn: "2024-10-16T18:25:24.463181Z",
            deletedOn: null,
            modifiedOn: "2024-10-16T18:25:24.463181Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Gearbox",
            description: "Gearbox - Synced by Accelerator V2 data migration task",
            id: "10942d1e-02d1-4a3c-82bc-8bb47bd2a0d3"
          },
          assetSubType: {
            assetTypeId: "10942d1e-02d1-4a3c-82bc-8bb47bd2a0d3",
            assetType: null,
            createdOn: "2021-10-01T02:00:00Z",
            deletedOn: null,
            modifiedOn: "2022-02-04T12:30:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Single Stage Speed Increaser Gearbox",
            description:
              "Single Stage Speed Increaser Gearbox - Synced by Accelerator V2 data migration task",
            id: "4d4437d4-c444-4d55-8686-5fe3bf4a32fc"
          },
          productionUnit: null,
          site: {
            siteCode: "LIM",
            plotly: "http://10.202.34.100:8030/LIM?asset_name=@AssetCode",
            powerBi: null,
            sharePoint:
              "https://ui.sharepoint.com/sites/lim/SitePages/EVE-Search.aspx?searchquery=@AssetCode*",
            timeZone: "Eastern Standard Time",
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Lima",
            description: null,
            id: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6"
          },
          subArea: {
            productionUnitId: "5d73c2c1-52a2-4467-919c-9c00d236a3b6",
            productionUnit: null,
            createdOn: "2020-10-15T17:46:00Z",
            deletedOn: null,
            modifiedOn: "2020-10-15T17:46:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Ammonia Feed",
            description: null,
            id: "e6e1a145-3320-4bb6-926e-0eef31d8490c"
          },
          plant: {
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Urea",
            description: null,
            id: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15"
          },
          assetCode: "IPG132A.I",
          plantArea: null,
          createdOn: "2022-12-08T00:31:00Z",
          deletedOn: null,
          modifiedOn: "2022-12-08T00:31:00Z",
          createdBy: "sa",
          deletedBy: null,
          modifiedBy: "sa",
          name: "AMMONIA FEED PUMP A SPEED INCREASER GEAR, 741228",
          description: "GEAR, AMMONIA FEED PUMP A SPEED INCREASER, 741228",
          id: "6c98edac-9e8c-22ee-e986-0a64aa2d8dfb"
        },
        {
          assetTypeId: "10942d1e-02d1-4a3c-82bc-8bb47bd2a0d3",
          assetSubTypeId: "4d4437d4-c444-4d55-8686-5fe3bf4a32fc",
          productionUnitId: null,
          siteId: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6",
          subAreaId: "e6e1a145-3320-4bb6-926e-0eef31d8490c",
          plantId: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15",
          assetType: {
            createdOn: "2024-10-16T18:25:24.463181Z",
            deletedOn: null,
            modifiedOn: "2024-10-16T18:25:24.463181Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Gearbox",
            description: "Gearbox - Synced by Accelerator V2 data migration task",
            id: "10942d1e-02d1-4a3c-82bc-8bb47bd2a0d3"
          },
          assetSubType: {
            assetTypeId: "10942d1e-02d1-4a3c-82bc-8bb47bd2a0d3",
            assetType: null,
            createdOn: "2021-10-01T02:00:00Z",
            deletedOn: null,
            modifiedOn: "2022-02-04T12:30:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Single Stage Speed Increaser Gearbox",
            description:
              "Single Stage Speed Increaser Gearbox - Synced by Accelerator V2 data migration task",
            id: "4d4437d4-c444-4d55-8686-5fe3bf4a32fc"
          },
          productionUnit: null,
          site: {
            siteCode: "LIM",
            plotly: "http://10.202.34.100:8030/LIM?asset_name=@AssetCode",
            powerBi: null,
            sharePoint:
              "https://ui.sharepoint.com/sites/lim/SitePages/EVE-Search.aspx?searchquery=@AssetCode*",
            timeZone: "Eastern Standard Time",
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Lima",
            description: null,
            id: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6"
          },
          subArea: {
            productionUnitId: "5d73c2c1-52a2-4467-919c-9c00d236a3b6",
            productionUnit: null,
            createdOn: "2020-10-15T17:46:00Z",
            deletedOn: null,
            modifiedOn: "2020-10-15T17:46:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Ammonia Feed",
            description: null,
            id: "e6e1a145-3320-4bb6-926e-0eef31d8490c"
          },
          plant: {
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Urea",
            description: null,
            id: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15"
          },
          assetCode: "IPG132B.I",
          plantArea: null,
          createdOn: "2022-12-08T00:31:00Z",
          deletedOn: null,
          modifiedOn: "2022-12-08T00:31:00Z",
          createdBy: "sa",
          deletedBy: null,
          modifiedBy: "sa",
          name: "AMMONIA FEED PUMP B SPEED INCREASER GEAR, 741229",
          description: "GEAR, AMMONIA FEED PUMP B SPEED INCREASER, 741229",
          id: "06984f68-0695-ed5c-7230-35682d086165"
        },
        {
          assetTypeId: "d5d9dc10-331f-4d0b-84f1-8942fb7ba23e",
          assetSubTypeId: "cb424770-8956-4ab7-9170-e73070860ee6",
          productionUnitId: null,
          siteId: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6",
          subAreaId: "e6e1a145-3320-4bb6-926e-0eef31d8490c",
          plantId: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15",
          assetType: {
            createdOn: "2024-10-16T18:25:24.463181Z",
            deletedOn: null,
            modifiedOn: "2024-10-16T18:25:24.463181Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Process Pumps",
            description: "Process Pumps - Synced by Accelerator V2 data migration task",
            id: "d5d9dc10-331f-4d0b-84f1-8942fb7ba23e"
          },
          assetSubType: {
            assetTypeId: "d5d9dc10-331f-4d0b-84f1-8942fb7ba23e",
            assetType: null,
            createdOn: "2021-10-01T00:00:00Z",
            deletedOn: null,
            modifiedOn: "2022-02-04T12:30:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Between Bearings Centrifugal Pump",
            description:
              "Between Bearings Centrifugal Pump - Synced by Accelerator V2 data migration task",
            id: "cb424770-8956-4ab7-9170-e73070860ee6"
          },
          productionUnit: null,
          site: {
            siteCode: "LIM",
            plotly: "http://10.202.34.100:8030/LIM?asset_name=@AssetCode",
            powerBi: null,
            sharePoint:
              "https://ui.sharepoint.com/sites/lim/SitePages/EVE-Search.aspx?searchquery=@AssetCode*",
            timeZone: "Eastern Standard Time",
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Lima",
            description: null,
            id: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6"
          },
          subArea: {
            productionUnitId: "5d73c2c1-52a2-4467-919c-9c00d236a3b6",
            productionUnit: null,
            createdOn: "2020-10-15T17:46:00Z",
            deletedOn: null,
            modifiedOn: "2020-10-15T17:46:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Ammonia Feed",
            description: null,
            id: "e6e1a145-3320-4bb6-926e-0eef31d8490c"
          },
          plant: {
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Urea",
            description: null,
            id: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15"
          },
          assetCode: "IP132A.I",
          plantArea: null,
          createdOn: "2022-12-08T00:31:00Z",
          deletedOn: null,
          modifiedOn: "2022-12-08T00:31:00Z",
          createdBy: "sa",
          deletedBy: null,
          modifiedBy: "sa",
          name: "EBARA AMMONIA FEED A PUMP (NORTH), 701230",
          description: "PUMP, EBARA AMMONIA FEED A (NORTH), 701230",
          id: "27a67a9a-cd59-5c49-36cf-99df674643ea"
        },
        {
          assetTypeId: "d5d9dc10-331f-4d0b-84f1-8942fb7ba23e",
          assetSubTypeId: "cb424770-8956-4ab7-9170-e73070860ee6",
          productionUnitId: null,
          siteId: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6",
          subAreaId: "e6e1a145-3320-4bb6-926e-0eef31d8490c",
          plantId: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15",
          assetType: {
            createdOn: "2024-10-16T18:25:24.463181Z",
            deletedOn: null,
            modifiedOn: "2024-10-16T18:25:24.463181Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Process Pumps",
            description: "Process Pumps - Synced by Accelerator V2 data migration task",
            id: "d5d9dc10-331f-4d0b-84f1-8942fb7ba23e"
          },
          assetSubType: {
            assetTypeId: "d5d9dc10-331f-4d0b-84f1-8942fb7ba23e",
            assetType: null,
            createdOn: "2021-10-01T00:00:00Z",
            deletedOn: null,
            modifiedOn: "2022-02-04T12:30:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Between Bearings Centrifugal Pump",
            description:
              "Between Bearings Centrifugal Pump - Synced by Accelerator V2 data migration task",
            id: "cb424770-8956-4ab7-9170-e73070860ee6"
          },
          productionUnit: null,
          site: {
            siteCode: "LIM",
            plotly: "http://10.202.34.100:8030/LIM?asset_name=@AssetCode",
            powerBi: null,
            sharePoint:
              "https://ui.sharepoint.com/sites/lim/SitePages/EVE-Search.aspx?searchquery=@AssetCode*",
            timeZone: "Eastern Standard Time",
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Lima",
            description: null,
            id: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6"
          },
          subArea: {
            productionUnitId: "5d73c2c1-52a2-4467-919c-9c00d236a3b6",
            productionUnit: null,
            createdOn: "2020-10-15T17:46:00Z",
            deletedOn: null,
            modifiedOn: "2020-10-15T17:46:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Ammonia Feed",
            description: null,
            id: "e6e1a145-3320-4bb6-926e-0eef31d8490c"
          },
          plant: {
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Urea",
            description: null,
            id: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15"
          },
          assetCode: "IP132B.I",
          plantArea: null,
          createdOn: "2022-12-08T00:31:00Z",
          deletedOn: null,
          modifiedOn: "2022-12-08T00:31:00Z",
          createdBy: "sa",
          deletedBy: null,
          modifiedBy: "sa",
          name: "EBARA AMMONIA FEED B PUMP (SOUTH), 701231",
          description: "PUMP, EBARA AMMONIA FEED B (SOUTH), 701231",
          id: "47545cbc-58a4-0824-a3b5-34e2383d92ba"
        },
        {
          assetTypeId: "e0e82e9a-e795-475d-8590-52a0819f1912",
          assetSubTypeId: "b37ee5ef-c5b7-4901-afbb-483b29c9b3ac",
          productionUnitId: null,
          siteId: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6",
          subAreaId: "e6e1a145-3320-4bb6-926e-0eef31d8490c",
          plantId: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15",
          assetType: {
            createdOn: "2024-10-16T18:25:24.463181Z",
            deletedOn: null,
            modifiedOn: "2024-10-16T18:25:24.463181Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Steam Turbines",
            description: "Steam Turbines - Synced by Accelerator V2 data migration task",
            id: "e0e82e9a-e795-475d-8590-52a0819f1912"
          },
          assetSubType: {
            assetTypeId: "e0e82e9a-e795-475d-8590-52a0819f1912",
            assetType: null,
            createdOn: "2021-10-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2022-02-04T12:30:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Horizontal Single Stage Back Pressure Steam Turbine",
            description:
              "Horizontal Single Stage Back Pressure Steam Turbine - Synced by Accelerator V2 data migration task",
            id: "b37ee5ef-c5b7-4901-afbb-483b29c9b3ac"
          },
          productionUnit: null,
          site: {
            siteCode: "LIM",
            plotly: "http://10.202.34.100:8030/LIM?asset_name=@AssetCode",
            powerBi: null,
            sharePoint:
              "https://ui.sharepoint.com/sites/lim/SitePages/EVE-Search.aspx?searchquery=@AssetCode*",
            timeZone: "Eastern Standard Time",
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Lima",
            description: null,
            id: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6"
          },
          subArea: {
            productionUnitId: "5d73c2c1-52a2-4467-919c-9c00d236a3b6",
            productionUnit: null,
            createdOn: "2020-10-15T17:46:00Z",
            deletedOn: null,
            modifiedOn: "2020-10-15T17:46:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Ammonia Feed",
            description: null,
            id: "e6e1a145-3320-4bb6-926e-0eef31d8490c"
          },
          plant: {
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Urea",
            description: null,
            id: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15"
          },
          assetCode: "IT132A.I",
          plantArea: null,
          createdOn: "2022-12-08T00:31:00Z",
          deletedOn: null,
          modifiedOn: "2022-12-08T00:31:00Z",
          createdBy: "sa",
          deletedBy: null,
          modifiedBy: "sa",
          name: "AMMONIA FEED PUMP A TURBINE, 711232",
          description: "TURBINE, AMMONIA FEED PUMP A, 711232",
          id: "b92f8948-7b4f-1199-2399-719039f94c3d"
        }
      ],
      createdOn: "2022-12-08T00:31:00Z",
      deletedOn: null,
      modifiedOn: "2022-12-08T00:31:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "AMMONIA FEED PUMPS A/B SEAL WATER SKID, 851272",
      description: "SKID, AMMONIA FEED PUMPS A/B SEAL WATER SKID, 851272",
      id: "ab4589ac-5af5-e455-8d9a-e40ae1c0ed43"
    },
    {
      assetTypeId: "63df1548-6f86-4dcd-bd0d-bc8c4cec1f93",
      assetSubTypeId: "7f7cb553-9dc2-407d-87cf-7e6a1d29a3e1",
      productionUnitId: null,
      siteId: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6",
      subAreaId: "e6e1a145-3320-4bb6-926e-0eef31d8490c",
      plantId: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15",
      assetType: {
        createdOn: "2024-10-16T18:25:24.463181Z",
        deletedOn: null,
        modifiedOn: "2024-10-16T18:25:24.463181Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Motors & Generators",
        description: "Motors & Generators - Synced by Accelerator V2 data migration task",
        id: "63df1548-6f86-4dcd-bd0d-bc8c4cec1f93"
      },
      assetSubType: {
        assetTypeId: "63df1548-6f86-4dcd-bd0d-bc8c4cec1f93",
        assetType: null,
        createdOn: "2021-10-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2022-02-04T12:30:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Induction Motor",
        description: "Induction Motor - Synced by Accelerator V2 data migration task",
        id: "7f7cb553-9dc2-407d-87cf-7e6a1d29a3e1"
      },
      productionUnit: null,
      site: {
        siteCode: "LIM",
        plotly: "http://10.202.34.100:8030/LIM?asset_name=@AssetCode",
        powerBi: null,
        sharePoint:
          "https://ui.sharepoint.com/sites/lim/SitePages/EVE-Search.aspx?searchquery=@AssetCode*",
        timeZone: "Eastern Standard Time",
        createdOn: "2024-01-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2024-01-01T01:00:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Lima",
        description: null,
        id: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6"
      },
      subArea: {
        productionUnitId: "5d73c2c1-52a2-4467-919c-9c00d236a3b6",
        productionUnit: null,
        createdOn: "2020-10-15T17:46:00Z",
        deletedOn: null,
        modifiedOn: "2020-10-15T17:46:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Ammonia Feed",
        description: null,
        id: "e6e1a145-3320-4bb6-926e-0eef31d8490c"
      },
      plant: {
        createdOn: "2024-01-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2024-01-01T01:00:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Urea",
        description: null,
        id: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15"
      },
      assetCode: "IPM132G.I",
      plantArea: null,
      train: "IP132G MAIN SEAL WATER PUMP",
      trainPosition: "1",
      relatedAssetIds: [
        "06984f68-0695-ed5c-7230-35682d086165",
        "6c98edac-9e8c-22ee-e986-0a64aa2d8dfb",
        "47545cbc-58a4-0824-a3b5-34e2383d92ba",
        "27a67a9a-cd59-5c49-36cf-99df674643ea",
        "cdec59c9-ded6-64c6-3014-776b4bbc07de",
        "b92f8948-7b4f-1199-2399-719039f94c3d"
      ],
      relatedAssets: [
        {
          assetTypeId: "10942d1e-02d1-4a3c-82bc-8bb47bd2a0d3",
          assetSubTypeId: "4d4437d4-c444-4d55-8686-5fe3bf4a32fc",
          productionUnitId: null,
          siteId: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6",
          subAreaId: "e6e1a145-3320-4bb6-926e-0eef31d8490c",
          plantId: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15",
          assetType: {
            createdOn: "2024-10-16T18:25:24.463181Z",
            deletedOn: null,
            modifiedOn: "2024-10-16T18:25:24.463181Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Gearbox",
            description: "Gearbox - Synced by Accelerator V2 data migration task",
            id: "10942d1e-02d1-4a3c-82bc-8bb47bd2a0d3"
          },
          assetSubType: {
            assetTypeId: "10942d1e-02d1-4a3c-82bc-8bb47bd2a0d3",
            assetType: null,
            createdOn: "2021-10-01T02:00:00Z",
            deletedOn: null,
            modifiedOn: "2022-02-04T12:30:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Single Stage Speed Increaser Gearbox",
            description:
              "Single Stage Speed Increaser Gearbox - Synced by Accelerator V2 data migration task",
            id: "4d4437d4-c444-4d55-8686-5fe3bf4a32fc"
          },
          productionUnit: null,
          site: {
            siteCode: "LIM",
            plotly: "http://10.202.34.100:8030/LIM?asset_name=@AssetCode",
            powerBi: null,
            sharePoint:
              "https://ui.sharepoint.com/sites/lim/SitePages/EVE-Search.aspx?searchquery=@AssetCode*",
            timeZone: "Eastern Standard Time",
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Lima",
            description: null,
            id: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6"
          },
          subArea: {
            productionUnitId: "5d73c2c1-52a2-4467-919c-9c00d236a3b6",
            productionUnit: null,
            createdOn: "2020-10-15T17:46:00Z",
            deletedOn: null,
            modifiedOn: "2020-10-15T17:46:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Ammonia Feed",
            description: null,
            id: "e6e1a145-3320-4bb6-926e-0eef31d8490c"
          },
          plant: {
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Urea",
            description: null,
            id: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15"
          },
          assetCode: "IPG132B.I",
          plantArea: null,
          createdOn: "2022-12-08T00:31:00Z",
          deletedOn: null,
          modifiedOn: "2022-12-08T00:31:00Z",
          createdBy: "sa",
          deletedBy: null,
          modifiedBy: "sa",
          name: "AMMONIA FEED PUMP B SPEED INCREASER GEAR, 741229",
          description: "GEAR, AMMONIA FEED PUMP B SPEED INCREASER, 741229",
          id: "06984f68-0695-ed5c-7230-35682d086165"
        },
        {
          assetTypeId: "10942d1e-02d1-4a3c-82bc-8bb47bd2a0d3",
          assetSubTypeId: "4d4437d4-c444-4d55-8686-5fe3bf4a32fc",
          productionUnitId: null,
          siteId: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6",
          subAreaId: "e6e1a145-3320-4bb6-926e-0eef31d8490c",
          plantId: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15",
          assetType: {
            createdOn: "2024-10-16T18:25:24.463181Z",
            deletedOn: null,
            modifiedOn: "2024-10-16T18:25:24.463181Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Gearbox",
            description: "Gearbox - Synced by Accelerator V2 data migration task",
            id: "10942d1e-02d1-4a3c-82bc-8bb47bd2a0d3"
          },
          assetSubType: {
            assetTypeId: "10942d1e-02d1-4a3c-82bc-8bb47bd2a0d3",
            assetType: null,
            createdOn: "2021-10-01T02:00:00Z",
            deletedOn: null,
            modifiedOn: "2022-02-04T12:30:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Single Stage Speed Increaser Gearbox",
            description:
              "Single Stage Speed Increaser Gearbox - Synced by Accelerator V2 data migration task",
            id: "4d4437d4-c444-4d55-8686-5fe3bf4a32fc"
          },
          productionUnit: null,
          site: {
            siteCode: "LIM",
            plotly: "http://10.202.34.100:8030/LIM?asset_name=@AssetCode",
            powerBi: null,
            sharePoint:
              "https://ui.sharepoint.com/sites/lim/SitePages/EVE-Search.aspx?searchquery=@AssetCode*",
            timeZone: "Eastern Standard Time",
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Lima",
            description: null,
            id: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6"
          },
          subArea: {
            productionUnitId: "5d73c2c1-52a2-4467-919c-9c00d236a3b6",
            productionUnit: null,
            createdOn: "2020-10-15T17:46:00Z",
            deletedOn: null,
            modifiedOn: "2020-10-15T17:46:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Ammonia Feed",
            description: null,
            id: "e6e1a145-3320-4bb6-926e-0eef31d8490c"
          },
          plant: {
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Urea",
            description: null,
            id: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15"
          },
          assetCode: "IPG132A.I",
          plantArea: null,
          createdOn: "2022-12-08T00:31:00Z",
          deletedOn: null,
          modifiedOn: "2022-12-08T00:31:00Z",
          createdBy: "sa",
          deletedBy: null,
          modifiedBy: "sa",
          name: "AMMONIA FEED PUMP A SPEED INCREASER GEAR, 741228",
          description: "GEAR, AMMONIA FEED PUMP A SPEED INCREASER, 741228",
          id: "6c98edac-9e8c-22ee-e986-0a64aa2d8dfb"
        },
        {
          assetTypeId: "d5d9dc10-331f-4d0b-84f1-8942fb7ba23e",
          assetSubTypeId: "cb424770-8956-4ab7-9170-e73070860ee6",
          productionUnitId: null,
          siteId: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6",
          subAreaId: "e6e1a145-3320-4bb6-926e-0eef31d8490c",
          plantId: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15",
          assetType: {
            createdOn: "2024-10-16T18:25:24.463181Z",
            deletedOn: null,
            modifiedOn: "2024-10-16T18:25:24.463181Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Process Pumps",
            description: "Process Pumps - Synced by Accelerator V2 data migration task",
            id: "d5d9dc10-331f-4d0b-84f1-8942fb7ba23e"
          },
          assetSubType: {
            assetTypeId: "d5d9dc10-331f-4d0b-84f1-8942fb7ba23e",
            assetType: null,
            createdOn: "2021-10-01T00:00:00Z",
            deletedOn: null,
            modifiedOn: "2022-02-04T12:30:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Between Bearings Centrifugal Pump",
            description:
              "Between Bearings Centrifugal Pump - Synced by Accelerator V2 data migration task",
            id: "cb424770-8956-4ab7-9170-e73070860ee6"
          },
          productionUnit: null,
          site: {
            siteCode: "LIM",
            plotly: "http://10.202.34.100:8030/LIM?asset_name=@AssetCode",
            powerBi: null,
            sharePoint:
              "https://ui.sharepoint.com/sites/lim/SitePages/EVE-Search.aspx?searchquery=@AssetCode*",
            timeZone: "Eastern Standard Time",
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Lima",
            description: null,
            id: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6"
          },
          subArea: {
            productionUnitId: "5d73c2c1-52a2-4467-919c-9c00d236a3b6",
            productionUnit: null,
            createdOn: "2020-10-15T17:46:00Z",
            deletedOn: null,
            modifiedOn: "2020-10-15T17:46:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Ammonia Feed",
            description: null,
            id: "e6e1a145-3320-4bb6-926e-0eef31d8490c"
          },
          plant: {
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Urea",
            description: null,
            id: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15"
          },
          assetCode: "IP132B.I",
          plantArea: null,
          createdOn: "2022-12-08T00:31:00Z",
          deletedOn: null,
          modifiedOn: "2022-12-08T00:31:00Z",
          createdBy: "sa",
          deletedBy: null,
          modifiedBy: "sa",
          name: "EBARA AMMONIA FEED B PUMP (SOUTH), 701231",
          description: "PUMP, EBARA AMMONIA FEED B (SOUTH), 701231",
          id: "47545cbc-58a4-0824-a3b5-34e2383d92ba"
        },
        {
          assetTypeId: "d5d9dc10-331f-4d0b-84f1-8942fb7ba23e",
          assetSubTypeId: "cb424770-8956-4ab7-9170-e73070860ee6",
          productionUnitId: null,
          siteId: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6",
          subAreaId: "e6e1a145-3320-4bb6-926e-0eef31d8490c",
          plantId: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15",
          assetType: {
            createdOn: "2024-10-16T18:25:24.463181Z",
            deletedOn: null,
            modifiedOn: "2024-10-16T18:25:24.463181Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Process Pumps",
            description: "Process Pumps - Synced by Accelerator V2 data migration task",
            id: "d5d9dc10-331f-4d0b-84f1-8942fb7ba23e"
          },
          assetSubType: {
            assetTypeId: "d5d9dc10-331f-4d0b-84f1-8942fb7ba23e",
            assetType: null,
            createdOn: "2021-10-01T00:00:00Z",
            deletedOn: null,
            modifiedOn: "2022-02-04T12:30:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Between Bearings Centrifugal Pump",
            description:
              "Between Bearings Centrifugal Pump - Synced by Accelerator V2 data migration task",
            id: "cb424770-8956-4ab7-9170-e73070860ee6"
          },
          productionUnit: null,
          site: {
            siteCode: "LIM",
            plotly: "http://10.202.34.100:8030/LIM?asset_name=@AssetCode",
            powerBi: null,
            sharePoint:
              "https://ui.sharepoint.com/sites/lim/SitePages/EVE-Search.aspx?searchquery=@AssetCode*",
            timeZone: "Eastern Standard Time",
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Lima",
            description: null,
            id: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6"
          },
          subArea: {
            productionUnitId: "5d73c2c1-52a2-4467-919c-9c00d236a3b6",
            productionUnit: null,
            createdOn: "2020-10-15T17:46:00Z",
            deletedOn: null,
            modifiedOn: "2020-10-15T17:46:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Ammonia Feed",
            description: null,
            id: "e6e1a145-3320-4bb6-926e-0eef31d8490c"
          },
          plant: {
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Urea",
            description: null,
            id: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15"
          },
          assetCode: "IP132A.I",
          plantArea: null,
          createdOn: "2022-12-08T00:31:00Z",
          deletedOn: null,
          modifiedOn: "2022-12-08T00:31:00Z",
          createdBy: "sa",
          deletedBy: null,
          modifiedBy: "sa",
          name: "EBARA AMMONIA FEED A PUMP (NORTH), 701230",
          description: "PUMP, EBARA AMMONIA FEED A (NORTH), 701230",
          id: "27a67a9a-cd59-5c49-36cf-99df674643ea"
        },
        {
          assetTypeId: "63df1548-6f86-4dcd-bd0d-bc8c4cec1f93",
          assetSubTypeId: "7f7cb553-9dc2-407d-87cf-7e6a1d29a3e1",
          productionUnitId: null,
          siteId: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6",
          subAreaId: "e6e1a145-3320-4bb6-926e-0eef31d8490c",
          plantId: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15",
          assetType: {
            createdOn: "2024-10-16T18:25:24.463181Z",
            deletedOn: null,
            modifiedOn: "2024-10-16T18:25:24.463181Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Motors & Generators",
            description: "Motors & Generators - Synced by Accelerator V2 data migration task",
            id: "63df1548-6f86-4dcd-bd0d-bc8c4cec1f93"
          },
          assetSubType: {
            assetTypeId: "63df1548-6f86-4dcd-bd0d-bc8c4cec1f93",
            assetType: null,
            createdOn: "2021-10-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2022-02-04T12:30:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Induction Motor",
            description: "Induction Motor - Synced by Accelerator V2 data migration task",
            id: "7f7cb553-9dc2-407d-87cf-7e6a1d29a3e1"
          },
          productionUnit: null,
          site: {
            siteCode: "LIM",
            plotly: "http://10.202.34.100:8030/LIM?asset_name=@AssetCode",
            powerBi: null,
            sharePoint:
              "https://ui.sharepoint.com/sites/lim/SitePages/EVE-Search.aspx?searchquery=@AssetCode*",
            timeZone: "Eastern Standard Time",
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Lima",
            description: null,
            id: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6"
          },
          subArea: {
            productionUnitId: "5d73c2c1-52a2-4467-919c-9c00d236a3b6",
            productionUnit: null,
            createdOn: "2020-10-15T17:46:00Z",
            deletedOn: null,
            modifiedOn: "2020-10-15T17:46:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Ammonia Feed",
            description: null,
            id: "e6e1a145-3320-4bb6-926e-0eef31d8490c"
          },
          plant: {
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Urea",
            description: null,
            id: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15"
          },
          assetCode: "IPM132B.I",
          plantArea: null,
          createdOn: "2022-12-08T00:31:00Z",
          deletedOn: null,
          modifiedOn: "2022-12-08T00:31:00Z",
          createdBy: "sa",
          deletedBy: null,
          modifiedBy: "sa",
          name: "AMMONIA FEED PUMP B MOTOR, 721233",
          description: "MOTOR, AMMONIA FEED PUMP B, 721233",
          id: "cdec59c9-ded6-64c6-3014-776b4bbc07de"
        },
        {
          assetTypeId: "e0e82e9a-e795-475d-8590-52a0819f1912",
          assetSubTypeId: "b37ee5ef-c5b7-4901-afbb-483b29c9b3ac",
          productionUnitId: null,
          siteId: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6",
          subAreaId: "e6e1a145-3320-4bb6-926e-0eef31d8490c",
          plantId: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15",
          assetType: {
            createdOn: "2024-10-16T18:25:24.463181Z",
            deletedOn: null,
            modifiedOn: "2024-10-16T18:25:24.463181Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Steam Turbines",
            description: "Steam Turbines - Synced by Accelerator V2 data migration task",
            id: "e0e82e9a-e795-475d-8590-52a0819f1912"
          },
          assetSubType: {
            assetTypeId: "e0e82e9a-e795-475d-8590-52a0819f1912",
            assetType: null,
            createdOn: "2021-10-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2022-02-04T12:30:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Horizontal Single Stage Back Pressure Steam Turbine",
            description:
              "Horizontal Single Stage Back Pressure Steam Turbine - Synced by Accelerator V2 data migration task",
            id: "b37ee5ef-c5b7-4901-afbb-483b29c9b3ac"
          },
          productionUnit: null,
          site: {
            siteCode: "LIM",
            plotly: "http://10.202.34.100:8030/LIM?asset_name=@AssetCode",
            powerBi: null,
            sharePoint:
              "https://ui.sharepoint.com/sites/lim/SitePages/EVE-Search.aspx?searchquery=@AssetCode*",
            timeZone: "Eastern Standard Time",
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Lima",
            description: null,
            id: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6"
          },
          subArea: {
            productionUnitId: "5d73c2c1-52a2-4467-919c-9c00d236a3b6",
            productionUnit: null,
            createdOn: "2020-10-15T17:46:00Z",
            deletedOn: null,
            modifiedOn: "2020-10-15T17:46:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Ammonia Feed",
            description: null,
            id: "e6e1a145-3320-4bb6-926e-0eef31d8490c"
          },
          plant: {
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Urea",
            description: null,
            id: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15"
          },
          assetCode: "IT132A.I",
          plantArea: null,
          createdOn: "2022-12-08T00:31:00Z",
          deletedOn: null,
          modifiedOn: "2022-12-08T00:31:00Z",
          createdBy: "sa",
          deletedBy: null,
          modifiedBy: "sa",
          name: "AMMONIA FEED PUMP A TURBINE, 711232",
          description: "TURBINE, AMMONIA FEED PUMP A, 711232",
          id: "b92f8948-7b4f-1199-2399-719039f94c3d"
        }
      ],
      createdOn: "2022-12-08T00:31:00Z",
      deletedOn: null,
      modifiedOn: "2022-12-08T00:31:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "AMMONIA FEED PUMPS A/B SEAL WATER PUMP MAIN MOTOR, 721257",
      description: "MOTOR, AMMONIA FEED PUMPS A/B SEAL WATER PUMP MAIN, 721257",
      id: "dbddcc24-ed9a-32b2-ed8b-37bc4d4d5ee2"
    },
    {
      assetTypeId: "8a5b2820-06f9-48e8-05dd-08da7666d9e3",
      assetSubTypeId: "3e659964-a941-48b6-95e1-08da795739db",
      productionUnitId: "9364f296-763e-43b0-ad6c-81f53f2b5dd7",
      siteId: "95b4e211-37dd-4e69-83f1-ee60e01f2e44",
      subAreaId: "b8eb1c5c-d6b7-4399-8970-c01b439f48c2",
      plantId: "f049670b-cb07-4669-83f7-548304691587",
      assetType: {
        createdOn: "2022-08-04T22:16:00Z",
        deletedOn: null,
        modifiedOn: "2022-08-04T22:16:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Column",
        description: "Column - Synced by Accelerator V2 data migration task",
        id: "8a5b2820-06f9-48e8-05dd-08da7666d9e3"
      },
      assetSubType: {
        assetTypeId: "8a5b2820-06f9-48e8-05dd-08da7666d9e3",
        assetType: null,
        createdOn: "2022-08-08T16:01:00Z",
        deletedOn: null,
        modifiedOn: "2022-08-08T16:01:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Ammonia Absorber",
        description: "Ammonia Absorber - Synced by Accelerator V2 data migration task",
        id: "3e659964-a941-48b6-95e1-08da795739db"
      },
      productionUnit: {
        plantId: "f049670b-cb07-4669-83f7-548304691587",
        plant: null,
        createdOn: "2024-01-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2024-01-01T01:00:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Ammonia 2",
        description: null,
        id: "9364f296-763e-43b0-ad6c-81f53f2b5dd7"
      },
      site: {
        siteCode: "TRM",
        plotly: "http://10.202.34.100:9040/TRM?asset_name=@AssetCode",
        powerBi:
          "https://app.powerbi.com/groups/f500b677-ce3a-428f-92eb-7b06b0cfa8a3/reports/8d08d148-22f8-428a-a496-a12d6e56a66b/ReportSection?filter=TRN_All/Train eq '@Train' and TRN_All/TagAssetID eq '@AssetCode' and TRN_All/TagNROCID eq '@Identifier'",
        sharePoint: null,
        timeZone: "Atlantic Standard Time",
        createdOn: "2024-01-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2024-01-01T01:00:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Trinidad",
        description: null,
        id: "95b4e211-37dd-4e69-83f1-ee60e01f2e44"
      },
      subArea: {
        productionUnitId: "9364f296-763e-43b0-ad6c-81f53f2b5dd7",
        productionUnit: null,
        createdOn: "2022-07-26T15:09:00Z",
        deletedOn: null,
        modifiedOn: "2022-07-26T15:09:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Purge Gas Recovery",
        description: null,
        id: "b8eb1c5c-d6b7-4399-8970-c01b439f48c2"
      },
      plant: {
        createdOn: "2024-01-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2024-01-01T01:00:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Ammonia",
        description: null,
        id: "f049670b-cb07-4669-83f7-548304691587"
      },
      assetCode: "C200----2",
      plantArea: null,
      train: null,
      trainPosition: null,
      relatedAssetIds: [],
      relatedAssets: [],
      createdOn: "2022-08-19T18:40:00Z",
      deletedOn: null,
      modifiedOn: "2023-12-07T23:08:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "C-200",
      description: "ARU STRIPPER COLUMN",
      id: "237460dd-86a1-4f34-cbd9-08da821230c4"
    },
    {
      assetTypeId: "245dd5e5-23a8-40a7-559f-08da763918e7",
      assetSubTypeId: "d4b07892-a031-416a-52e9-08da7669bdb4",
      productionUnitId: "fb7babb2-350a-409e-8927-718c1cc71498",
      siteId: "3cc78be2-ffa8-49c7-a790-69cc8123893c",
      subAreaId: "6a6e4d2d-9cd3-43fa-ad76-194fb3c2d7dc",
      plantId: "f049670b-cb07-4669-83f7-548304691587",
      assetType: {
        createdOn: "2022-08-04T16:48:00Z",
        deletedOn: null,
        modifiedOn: "2022-08-04T16:48:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Reactor",
        description: "Reactor - Synced by Accelerator V2 data migration task",
        id: "245dd5e5-23a8-40a7-559f-08da763918e7"
      },
      assetSubType: {
        assetTypeId: "245dd5e5-23a8-40a7-559f-08da763918e7",
        assetType: null,
        createdOn: "2022-08-04T22:36:00Z",
        deletedOn: null,
        modifiedOn: "2022-08-04T22:36:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Ammonia Converter",
        description: "Ammonia Converter - Synced by Accelerator V2 data migration task",
        id: "d4b07892-a031-416a-52e9-08da7669bdb4"
      },
      productionUnit: {
        plantId: "f049670b-cb07-4669-83f7-548304691587",
        plant: null,
        createdOn: "2024-01-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2024-01-01T01:00:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Ammonia",
        description: null,
        id: "fb7babb2-350a-409e-8927-718c1cc71498"
      },
      site: {
        siteCode: "GSM",
        plotly: "http://10.202.34.100:8060/GSM?asset_name=@AssetCode",
        powerBi:
          "https://app.powerbi.com/groups/me/apps/ad325a73-3cd7-49cb-8de2-370ac15601d5/reports/2cda8ec6-0d5a-4697-9a68-d981866cdacd/ReportSectionf2ba6970b8310a3aaa7a?filter=Site/site eq 'GSM' and Asset_Train/train eq '@Train' and Asset/asset_id eq '@AssetCode' and Asset/tag_nroc_id eq '@Identifier'",
        sharePoint:
          "https://ui.sharepoint.com/sites/gen/SitePages/Meridian-Search.aspx?searchquery=AssetNumber:@AssetCode*",
        timeZone: "Central Standard Time",
        createdOn: "2024-01-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2024-01-01T01:00:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Geismar",
        description: null,
        id: "3cc78be2-ffa8-49c7-a790-69cc8123893c"
      },
      subArea: {
        productionUnitId: "fb7babb2-350a-409e-8927-718c1cc71498",
        productionUnit: null,
        createdOn: "2020-10-15T17:45:00Z",
        deletedOn: null,
        modifiedOn: "2020-10-15T17:45:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Ammonia Synthesis",
        description: null,
        id: "6a6e4d2d-9cd3-43fa-ad76-194fb3c2d7dc"
      },
      plant: {
        createdOn: "2024-01-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2024-01-01T01:00:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Ammonia",
        description: null,
        id: "f049670b-cb07-4669-83f7-548304691587"
      },
      assetCode: "100VE017.GSM",
      plantArea: null,
      train: null,
      trainPosition: null,
      relatedAssetIds: [],
      relatedAssets: [],
      createdOn: "2022-08-30T21:08:00Z",
      deletedOn: null,
      modifiedOn: "2024-01-09T20:49:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "105D",
      description: "AMMONIA, SYNTHESIS, CONVERTER",
      id: "776da670-a019-4587-8ab5-08da8acbb962"
    },
    {
      assetTypeId: "8a5b2820-06f9-48e8-05dd-08da7666d9e3",
      assetSubTypeId: "01e653d7-3b25-4bf7-fcfc-08da820d5241",
      productionUnitId: "fb7babb2-350a-409e-8927-718c1cc71498",
      siteId: "ee7aaf0e-7655-4371-bed7-dbfb489e5aef",
      subAreaId: "896cdf38-d7ed-4358-85e8-b8af49ce636f",
      plantId: "f049670b-cb07-4669-83f7-548304691587",
      assetType: {
        createdOn: "2022-08-04T22:16:00Z",
        deletedOn: null,
        modifiedOn: "2022-08-04T22:16:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Column",
        description: "Column - Synced by Accelerator V2 data migration task",
        id: "8a5b2820-06f9-48e8-05dd-08da7666d9e3"
      },
      assetSubType: {
        assetTypeId: "8a5b2820-06f9-48e8-05dd-08da7666d9e3",
        assetType: null,
        createdOn: "2022-08-19T18:05:00Z",
        deletedOn: null,
        modifiedOn: "2022-08-19T18:05:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Ammonia Scrubber",
        description: "Ammonia Scrubber - Synced by Accelerator V2 data migration task",
        id: "01e653d7-3b25-4bf7-fcfc-08da820d5241"
      },
      productionUnit: {
        plantId: "f049670b-cb07-4669-83f7-548304691587",
        plant: null,
        createdOn: "2024-01-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2024-01-01T01:00:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Ammonia",
        description: null,
        id: "fb7babb2-350a-409e-8927-718c1cc71498"
      },
      site: {
        siteCode: "BNO",
        plotly: null,
        powerBi: null,
        sharePoint: null,
        timeZone: "Central Standard Time",
        createdOn: "2024-01-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2024-01-01T01:00:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Borger",
        description: null,
        id: "ee7aaf0e-7655-4371-bed7-dbfb489e5aef"
      },
      subArea: {
        productionUnitId: "fb7babb2-350a-409e-8927-718c1cc71498",
        productionUnit: null,
        createdOn: "2022-07-26T14:59:00Z",
        deletedOn: null,
        modifiedOn: "2022-07-26T14:59:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Purge Gas Recovery",
        description: null,
        id: "896cdf38-d7ed-4358-85e8-b8af49ce636f"
      },
      plant: {
        createdOn: "2024-01-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2024-01-01T01:00:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Ammonia",
        description: null,
        id: "f049670b-cb07-4669-83f7-548304691587"
      },
      assetCode: "BG-1201E",
      plantArea: null,
      train: null,
      trainPosition: null,
      relatedAssetIds: [],
      relatedAssets: [],
      createdOn: "2022-08-30T20:50:00Z",
      deletedOn: null,
      modifiedOn: "2023-12-07T18:43:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "1201E",
      description: "1201E H2 Unit High Press Gas Scrub",
      id: "aa0ff55f-f1b8-42a2-f13b-08da8ac93e07"
    },
    {
      assetTypeId: "d5d9dc10-331f-4d0b-84f1-8942fb7ba23e",
      assetSubTypeId: "2fdfcbda-6924-44c4-bb8e-f3401c57af23",
      productionUnitId: null,
      siteId: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6",
      subAreaId: "e6e1a145-3320-4bb6-926e-0eef31d8490c",
      plantId: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15",
      assetType: {
        createdOn: "2024-10-16T18:25:24.463181Z",
        deletedOn: null,
        modifiedOn: "2024-10-16T18:25:24.463181Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Process Pumps",
        description: "Process Pumps - Synced by Accelerator V2 data migration task",
        id: "d5d9dc10-331f-4d0b-84f1-8942fb7ba23e"
      },
      assetSubType: {
        assetTypeId: "d5d9dc10-331f-4d0b-84f1-8942fb7ba23e",
        assetType: null,
        createdOn: "2021-10-01T02:00:00Z",
        deletedOn: null,
        modifiedOn: "2022-02-04T12:30:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Gear/Screw Positive Displacement Pump",
        description:
          "Gear/Screw Positive Displacement Pump - Synced by Accelerator V2 data migration task",
        id: "2fdfcbda-6924-44c4-bb8e-f3401c57af23"
      },
      productionUnit: null,
      site: {
        siteCode: "LIM",
        plotly: "http://10.202.34.100:8030/LIM?asset_name=@AssetCode",
        powerBi: null,
        sharePoint:
          "https://ui.sharepoint.com/sites/lim/SitePages/EVE-Search.aspx?searchquery=@AssetCode*",
        timeZone: "Eastern Standard Time",
        createdOn: "2024-01-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2024-01-01T01:00:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Lima",
        description: null,
        id: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6"
      },
      subArea: {
        productionUnitId: "5d73c2c1-52a2-4467-919c-9c00d236a3b6",
        productionUnit: null,
        createdOn: "2020-10-15T17:46:00Z",
        deletedOn: null,
        modifiedOn: "2020-10-15T17:46:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Ammonia Feed",
        description: null,
        id: "e6e1a145-3320-4bb6-926e-0eef31d8490c"
      },
      plant: {
        createdOn: "2024-01-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2024-01-01T01:00:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Urea",
        description: null,
        id: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15"
      },
      assetCode: "IP132H.I",
      plantArea: null,
      train: "IP132H AUX SEAL WATER PUMP",
      trainPosition: "2",
      relatedAssetIds: [
        "27a67a9a-cd59-5c49-36cf-99df674643ea",
        "b92f8948-7b4f-1199-2399-719039f94c3d",
        "06984f68-0695-ed5c-7230-35682d086165",
        "47545cbc-58a4-0824-a3b5-34e2383d92ba",
        "cdec59c9-ded6-64c6-3014-776b4bbc07de",
        "6c98edac-9e8c-22ee-e986-0a64aa2d8dfb"
      ],
      relatedAssets: [
        {
          assetTypeId: "d5d9dc10-331f-4d0b-84f1-8942fb7ba23e",
          assetSubTypeId: "cb424770-8956-4ab7-9170-e73070860ee6",
          productionUnitId: null,
          siteId: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6",
          subAreaId: "e6e1a145-3320-4bb6-926e-0eef31d8490c",
          plantId: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15",
          assetType: {
            createdOn: "2024-10-16T18:25:24.463181Z",
            deletedOn: null,
            modifiedOn: "2024-10-16T18:25:24.463181Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Process Pumps",
            description: "Process Pumps - Synced by Accelerator V2 data migration task",
            id: "d5d9dc10-331f-4d0b-84f1-8942fb7ba23e"
          },
          assetSubType: {
            assetTypeId: "d5d9dc10-331f-4d0b-84f1-8942fb7ba23e",
            assetType: null,
            createdOn: "2021-10-01T00:00:00Z",
            deletedOn: null,
            modifiedOn: "2022-02-04T12:30:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Between Bearings Centrifugal Pump",
            description:
              "Between Bearings Centrifugal Pump - Synced by Accelerator V2 data migration task",
            id: "cb424770-8956-4ab7-9170-e73070860ee6"
          },
          productionUnit: null,
          site: {
            siteCode: "LIM",
            plotly: "http://10.202.34.100:8030/LIM?asset_name=@AssetCode",
            powerBi: null,
            sharePoint:
              "https://ui.sharepoint.com/sites/lim/SitePages/EVE-Search.aspx?searchquery=@AssetCode*",
            timeZone: "Eastern Standard Time",
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Lima",
            description: null,
            id: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6"
          },
          subArea: {
            productionUnitId: "5d73c2c1-52a2-4467-919c-9c00d236a3b6",
            productionUnit: null,
            createdOn: "2020-10-15T17:46:00Z",
            deletedOn: null,
            modifiedOn: "2020-10-15T17:46:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Ammonia Feed",
            description: null,
            id: "e6e1a145-3320-4bb6-926e-0eef31d8490c"
          },
          plant: {
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Urea",
            description: null,
            id: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15"
          },
          assetCode: "IP132A.I",
          plantArea: null,
          createdOn: "2022-12-08T00:31:00Z",
          deletedOn: null,
          modifiedOn: "2022-12-08T00:31:00Z",
          createdBy: "sa",
          deletedBy: null,
          modifiedBy: "sa",
          name: "EBARA AMMONIA FEED A PUMP (NORTH), 701230",
          description: "PUMP, EBARA AMMONIA FEED A (NORTH), 701230",
          id: "27a67a9a-cd59-5c49-36cf-99df674643ea"
        },
        {
          assetTypeId: "e0e82e9a-e795-475d-8590-52a0819f1912",
          assetSubTypeId: "b37ee5ef-c5b7-4901-afbb-483b29c9b3ac",
          productionUnitId: null,
          siteId: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6",
          subAreaId: "e6e1a145-3320-4bb6-926e-0eef31d8490c",
          plantId: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15",
          assetType: {
            createdOn: "2024-10-16T18:25:24.463181Z",
            deletedOn: null,
            modifiedOn: "2024-10-16T18:25:24.463181Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Steam Turbines",
            description: "Steam Turbines - Synced by Accelerator V2 data migration task",
            id: "e0e82e9a-e795-475d-8590-52a0819f1912"
          },
          assetSubType: {
            assetTypeId: "e0e82e9a-e795-475d-8590-52a0819f1912",
            assetType: null,
            createdOn: "2021-10-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2022-02-04T12:30:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Horizontal Single Stage Back Pressure Steam Turbine",
            description:
              "Horizontal Single Stage Back Pressure Steam Turbine - Synced by Accelerator V2 data migration task",
            id: "b37ee5ef-c5b7-4901-afbb-483b29c9b3ac"
          },
          productionUnit: null,
          site: {
            siteCode: "LIM",
            plotly: "http://10.202.34.100:8030/LIM?asset_name=@AssetCode",
            powerBi: null,
            sharePoint:
              "https://ui.sharepoint.com/sites/lim/SitePages/EVE-Search.aspx?searchquery=@AssetCode*",
            timeZone: "Eastern Standard Time",
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Lima",
            description: null,
            id: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6"
          },
          subArea: {
            productionUnitId: "5d73c2c1-52a2-4467-919c-9c00d236a3b6",
            productionUnit: null,
            createdOn: "2020-10-15T17:46:00Z",
            deletedOn: null,
            modifiedOn: "2020-10-15T17:46:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Ammonia Feed",
            description: null,
            id: "e6e1a145-3320-4bb6-926e-0eef31d8490c"
          },
          plant: {
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Urea",
            description: null,
            id: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15"
          },
          assetCode: "IT132A.I",
          plantArea: null,
          createdOn: "2022-12-08T00:31:00Z",
          deletedOn: null,
          modifiedOn: "2022-12-08T00:31:00Z",
          createdBy: "sa",
          deletedBy: null,
          modifiedBy: "sa",
          name: "AMMONIA FEED PUMP A TURBINE, 711232",
          description: "TURBINE, AMMONIA FEED PUMP A, 711232",
          id: "b92f8948-7b4f-1199-2399-719039f94c3d"
        },
        {
          assetTypeId: "10942d1e-02d1-4a3c-82bc-8bb47bd2a0d3",
          assetSubTypeId: "4d4437d4-c444-4d55-8686-5fe3bf4a32fc",
          productionUnitId: null,
          siteId: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6",
          subAreaId: "e6e1a145-3320-4bb6-926e-0eef31d8490c",
          plantId: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15",
          assetType: {
            createdOn: "2024-10-16T18:25:24.463181Z",
            deletedOn: null,
            modifiedOn: "2024-10-16T18:25:24.463181Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Gearbox",
            description: "Gearbox - Synced by Accelerator V2 data migration task",
            id: "10942d1e-02d1-4a3c-82bc-8bb47bd2a0d3"
          },
          assetSubType: {
            assetTypeId: "10942d1e-02d1-4a3c-82bc-8bb47bd2a0d3",
            assetType: null,
            createdOn: "2021-10-01T02:00:00Z",
            deletedOn: null,
            modifiedOn: "2022-02-04T12:30:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Single Stage Speed Increaser Gearbox",
            description:
              "Single Stage Speed Increaser Gearbox - Synced by Accelerator V2 data migration task",
            id: "4d4437d4-c444-4d55-8686-5fe3bf4a32fc"
          },
          productionUnit: null,
          site: {
            siteCode: "LIM",
            plotly: "http://10.202.34.100:8030/LIM?asset_name=@AssetCode",
            powerBi: null,
            sharePoint:
              "https://ui.sharepoint.com/sites/lim/SitePages/EVE-Search.aspx?searchquery=@AssetCode*",
            timeZone: "Eastern Standard Time",
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Lima",
            description: null,
            id: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6"
          },
          subArea: {
            productionUnitId: "5d73c2c1-52a2-4467-919c-9c00d236a3b6",
            productionUnit: null,
            createdOn: "2020-10-15T17:46:00Z",
            deletedOn: null,
            modifiedOn: "2020-10-15T17:46:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Ammonia Feed",
            description: null,
            id: "e6e1a145-3320-4bb6-926e-0eef31d8490c"
          },
          plant: {
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Urea",
            description: null,
            id: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15"
          },
          assetCode: "IPG132B.I",
          plantArea: null,
          createdOn: "2022-12-08T00:31:00Z",
          deletedOn: null,
          modifiedOn: "2022-12-08T00:31:00Z",
          createdBy: "sa",
          deletedBy: null,
          modifiedBy: "sa",
          name: "AMMONIA FEED PUMP B SPEED INCREASER GEAR, 741229",
          description: "GEAR, AMMONIA FEED PUMP B SPEED INCREASER, 741229",
          id: "06984f68-0695-ed5c-7230-35682d086165"
        },
        {
          assetTypeId: "d5d9dc10-331f-4d0b-84f1-8942fb7ba23e",
          assetSubTypeId: "cb424770-8956-4ab7-9170-e73070860ee6",
          productionUnitId: null,
          siteId: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6",
          subAreaId: "e6e1a145-3320-4bb6-926e-0eef31d8490c",
          plantId: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15",
          assetType: {
            createdOn: "2024-10-16T18:25:24.463181Z",
            deletedOn: null,
            modifiedOn: "2024-10-16T18:25:24.463181Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Process Pumps",
            description: "Process Pumps - Synced by Accelerator V2 data migration task",
            id: "d5d9dc10-331f-4d0b-84f1-8942fb7ba23e"
          },
          assetSubType: {
            assetTypeId: "d5d9dc10-331f-4d0b-84f1-8942fb7ba23e",
            assetType: null,
            createdOn: "2021-10-01T00:00:00Z",
            deletedOn: null,
            modifiedOn: "2022-02-04T12:30:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Between Bearings Centrifugal Pump",
            description:
              "Between Bearings Centrifugal Pump - Synced by Accelerator V2 data migration task",
            id: "cb424770-8956-4ab7-9170-e73070860ee6"
          },
          productionUnit: null,
          site: {
            siteCode: "LIM",
            plotly: "http://10.202.34.100:8030/LIM?asset_name=@AssetCode",
            powerBi: null,
            sharePoint:
              "https://ui.sharepoint.com/sites/lim/SitePages/EVE-Search.aspx?searchquery=@AssetCode*",
            timeZone: "Eastern Standard Time",
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Lima",
            description: null,
            id: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6"
          },
          subArea: {
            productionUnitId: "5d73c2c1-52a2-4467-919c-9c00d236a3b6",
            productionUnit: null,
            createdOn: "2020-10-15T17:46:00Z",
            deletedOn: null,
            modifiedOn: "2020-10-15T17:46:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Ammonia Feed",
            description: null,
            id: "e6e1a145-3320-4bb6-926e-0eef31d8490c"
          },
          plant: {
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Urea",
            description: null,
            id: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15"
          },
          assetCode: "IP132B.I",
          plantArea: null,
          createdOn: "2022-12-08T00:31:00Z",
          deletedOn: null,
          modifiedOn: "2022-12-08T00:31:00Z",
          createdBy: "sa",
          deletedBy: null,
          modifiedBy: "sa",
          name: "EBARA AMMONIA FEED B PUMP (SOUTH), 701231",
          description: "PUMP, EBARA AMMONIA FEED B (SOUTH), 701231",
          id: "47545cbc-58a4-0824-a3b5-34e2383d92ba"
        },
        {
          assetTypeId: "63df1548-6f86-4dcd-bd0d-bc8c4cec1f93",
          assetSubTypeId: "7f7cb553-9dc2-407d-87cf-7e6a1d29a3e1",
          productionUnitId: null,
          siteId: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6",
          subAreaId: "e6e1a145-3320-4bb6-926e-0eef31d8490c",
          plantId: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15",
          assetType: {
            createdOn: "2024-10-16T18:25:24.463181Z",
            deletedOn: null,
            modifiedOn: "2024-10-16T18:25:24.463181Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Motors & Generators",
            description: "Motors & Generators - Synced by Accelerator V2 data migration task",
            id: "63df1548-6f86-4dcd-bd0d-bc8c4cec1f93"
          },
          assetSubType: {
            assetTypeId: "63df1548-6f86-4dcd-bd0d-bc8c4cec1f93",
            assetType: null,
            createdOn: "2021-10-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2022-02-04T12:30:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Induction Motor",
            description: "Induction Motor - Synced by Accelerator V2 data migration task",
            id: "7f7cb553-9dc2-407d-87cf-7e6a1d29a3e1"
          },
          productionUnit: null,
          site: {
            siteCode: "LIM",
            plotly: "http://10.202.34.100:8030/LIM?asset_name=@AssetCode",
            powerBi: null,
            sharePoint:
              "https://ui.sharepoint.com/sites/lim/SitePages/EVE-Search.aspx?searchquery=@AssetCode*",
            timeZone: "Eastern Standard Time",
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Lima",
            description: null,
            id: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6"
          },
          subArea: {
            productionUnitId: "5d73c2c1-52a2-4467-919c-9c00d236a3b6",
            productionUnit: null,
            createdOn: "2020-10-15T17:46:00Z",
            deletedOn: null,
            modifiedOn: "2020-10-15T17:46:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Ammonia Feed",
            description: null,
            id: "e6e1a145-3320-4bb6-926e-0eef31d8490c"
          },
          plant: {
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Urea",
            description: null,
            id: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15"
          },
          assetCode: "IPM132B.I",
          plantArea: null,
          createdOn: "2022-12-08T00:31:00Z",
          deletedOn: null,
          modifiedOn: "2022-12-08T00:31:00Z",
          createdBy: "sa",
          deletedBy: null,
          modifiedBy: "sa",
          name: "AMMONIA FEED PUMP B MOTOR, 721233",
          description: "MOTOR, AMMONIA FEED PUMP B, 721233",
          id: "cdec59c9-ded6-64c6-3014-776b4bbc07de"
        },
        {
          assetTypeId: "10942d1e-02d1-4a3c-82bc-8bb47bd2a0d3",
          assetSubTypeId: "4d4437d4-c444-4d55-8686-5fe3bf4a32fc",
          productionUnitId: null,
          siteId: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6",
          subAreaId: "e6e1a145-3320-4bb6-926e-0eef31d8490c",
          plantId: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15",
          assetType: {
            createdOn: "2024-10-16T18:25:24.463181Z",
            deletedOn: null,
            modifiedOn: "2024-10-16T18:25:24.463181Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Gearbox",
            description: "Gearbox - Synced by Accelerator V2 data migration task",
            id: "10942d1e-02d1-4a3c-82bc-8bb47bd2a0d3"
          },
          assetSubType: {
            assetTypeId: "10942d1e-02d1-4a3c-82bc-8bb47bd2a0d3",
            assetType: null,
            createdOn: "2021-10-01T02:00:00Z",
            deletedOn: null,
            modifiedOn: "2022-02-04T12:30:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Single Stage Speed Increaser Gearbox",
            description:
              "Single Stage Speed Increaser Gearbox - Synced by Accelerator V2 data migration task",
            id: "4d4437d4-c444-4d55-8686-5fe3bf4a32fc"
          },
          productionUnit: null,
          site: {
            siteCode: "LIM",
            plotly: "http://10.202.34.100:8030/LIM?asset_name=@AssetCode",
            powerBi: null,
            sharePoint:
              "https://ui.sharepoint.com/sites/lim/SitePages/EVE-Search.aspx?searchquery=@AssetCode*",
            timeZone: "Eastern Standard Time",
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Lima",
            description: null,
            id: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6"
          },
          subArea: {
            productionUnitId: "5d73c2c1-52a2-4467-919c-9c00d236a3b6",
            productionUnit: null,
            createdOn: "2020-10-15T17:46:00Z",
            deletedOn: null,
            modifiedOn: "2020-10-15T17:46:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Ammonia Feed",
            description: null,
            id: "e6e1a145-3320-4bb6-926e-0eef31d8490c"
          },
          plant: {
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Urea",
            description: null,
            id: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15"
          },
          assetCode: "IPG132A.I",
          plantArea: null,
          createdOn: "2022-12-08T00:31:00Z",
          deletedOn: null,
          modifiedOn: "2022-12-08T00:31:00Z",
          createdBy: "sa",
          deletedBy: null,
          modifiedBy: "sa",
          name: "AMMONIA FEED PUMP A SPEED INCREASER GEAR, 741228",
          description: "GEAR, AMMONIA FEED PUMP A SPEED INCREASER, 741228",
          id: "6c98edac-9e8c-22ee-e986-0a64aa2d8dfb"
        }
      ],
      createdOn: "2022-06-29T14:28:00Z",
      deletedOn: null,
      modifiedOn: "2022-12-08T00:31:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: " AMMONIA FEED PUMPS A/B SEAL WATER AUX PUMP, 701256",
      description: "PUMP, AMMONIA FEED PUMPS A/B SEAL WATER AUX, 701256",
      id: "207b214b-70d7-4b74-8fdb-a52873cb0414"
    },
    {
      assetTypeId: "63df1548-6f86-4dcd-bd0d-bc8c4cec1f93",
      assetSubTypeId: "7f7cb553-9dc2-407d-87cf-7e6a1d29a3e1",
      productionUnitId: null,
      siteId: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6",
      subAreaId: "e6e1a145-3320-4bb6-926e-0eef31d8490c",
      plantId: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15",
      assetType: {
        createdOn: "2024-10-16T18:25:24.463181Z",
        deletedOn: null,
        modifiedOn: "2024-10-16T18:25:24.463181Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Motors & Generators",
        description: "Motors & Generators - Synced by Accelerator V2 data migration task",
        id: "63df1548-6f86-4dcd-bd0d-bc8c4cec1f93"
      },
      assetSubType: {
        assetTypeId: "63df1548-6f86-4dcd-bd0d-bc8c4cec1f93",
        assetType: null,
        createdOn: "2021-10-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2022-02-04T12:30:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Induction Motor",
        description: "Induction Motor - Synced by Accelerator V2 data migration task",
        id: "7f7cb553-9dc2-407d-87cf-7e6a1d29a3e1"
      },
      productionUnit: null,
      site: {
        siteCode: "LIM",
        plotly: "http://10.202.34.100:8030/LIM?asset_name=@AssetCode",
        powerBi: null,
        sharePoint:
          "https://ui.sharepoint.com/sites/lim/SitePages/EVE-Search.aspx?searchquery=@AssetCode*",
        timeZone: "Eastern Standard Time",
        createdOn: "2024-01-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2024-01-01T01:00:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Lima",
        description: null,
        id: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6"
      },
      subArea: {
        productionUnitId: "5d73c2c1-52a2-4467-919c-9c00d236a3b6",
        productionUnit: null,
        createdOn: "2020-10-15T17:46:00Z",
        deletedOn: null,
        modifiedOn: "2020-10-15T17:46:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Ammonia Feed",
        description: null,
        id: "e6e1a145-3320-4bb6-926e-0eef31d8490c"
      },
      plant: {
        createdOn: "2024-01-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2024-01-01T01:00:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Urea",
        description: null,
        id: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15"
      },
      assetCode: "IPM132H.I",
      plantArea: null,
      train: "IP132H AUX SEAL WATER PUMP",
      trainPosition: "1",
      relatedAssetIds: [
        "27a67a9a-cd59-5c49-36cf-99df674643ea",
        "6c98edac-9e8c-22ee-e986-0a64aa2d8dfb",
        "cdec59c9-ded6-64c6-3014-776b4bbc07de",
        "47545cbc-58a4-0824-a3b5-34e2383d92ba",
        "06984f68-0695-ed5c-7230-35682d086165",
        "b92f8948-7b4f-1199-2399-719039f94c3d"
      ],
      relatedAssets: [
        {
          assetTypeId: "d5d9dc10-331f-4d0b-84f1-8942fb7ba23e",
          assetSubTypeId: "cb424770-8956-4ab7-9170-e73070860ee6",
          productionUnitId: null,
          siteId: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6",
          subAreaId: "e6e1a145-3320-4bb6-926e-0eef31d8490c",
          plantId: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15",
          assetType: {
            createdOn: "2024-10-16T18:25:24.463181Z",
            deletedOn: null,
            modifiedOn: "2024-10-16T18:25:24.463181Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Process Pumps",
            description: "Process Pumps - Synced by Accelerator V2 data migration task",
            id: "d5d9dc10-331f-4d0b-84f1-8942fb7ba23e"
          },
          assetSubType: {
            assetTypeId: "d5d9dc10-331f-4d0b-84f1-8942fb7ba23e",
            assetType: null,
            createdOn: "2021-10-01T00:00:00Z",
            deletedOn: null,
            modifiedOn: "2022-02-04T12:30:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Between Bearings Centrifugal Pump",
            description:
              "Between Bearings Centrifugal Pump - Synced by Accelerator V2 data migration task",
            id: "cb424770-8956-4ab7-9170-e73070860ee6"
          },
          productionUnit: null,
          site: {
            siteCode: "LIM",
            plotly: "http://10.202.34.100:8030/LIM?asset_name=@AssetCode",
            powerBi: null,
            sharePoint:
              "https://ui.sharepoint.com/sites/lim/SitePages/EVE-Search.aspx?searchquery=@AssetCode*",
            timeZone: "Eastern Standard Time",
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Lima",
            description: null,
            id: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6"
          },
          subArea: {
            productionUnitId: "5d73c2c1-52a2-4467-919c-9c00d236a3b6",
            productionUnit: null,
            createdOn: "2020-10-15T17:46:00Z",
            deletedOn: null,
            modifiedOn: "2020-10-15T17:46:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Ammonia Feed",
            description: null,
            id: "e6e1a145-3320-4bb6-926e-0eef31d8490c"
          },
          plant: {
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Urea",
            description: null,
            id: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15"
          },
          assetCode: "IP132A.I",
          plantArea: null,
          createdOn: "2022-12-08T00:31:00Z",
          deletedOn: null,
          modifiedOn: "2022-12-08T00:31:00Z",
          createdBy: "sa",
          deletedBy: null,
          modifiedBy: "sa",
          name: "EBARA AMMONIA FEED A PUMP (NORTH), 701230",
          description: "PUMP, EBARA AMMONIA FEED A (NORTH), 701230",
          id: "27a67a9a-cd59-5c49-36cf-99df674643ea"
        },
        {
          assetTypeId: "10942d1e-02d1-4a3c-82bc-8bb47bd2a0d3",
          assetSubTypeId: "4d4437d4-c444-4d55-8686-5fe3bf4a32fc",
          productionUnitId: null,
          siteId: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6",
          subAreaId: "e6e1a145-3320-4bb6-926e-0eef31d8490c",
          plantId: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15",
          assetType: {
            createdOn: "2024-10-16T18:25:24.463181Z",
            deletedOn: null,
            modifiedOn: "2024-10-16T18:25:24.463181Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Gearbox",
            description: "Gearbox - Synced by Accelerator V2 data migration task",
            id: "10942d1e-02d1-4a3c-82bc-8bb47bd2a0d3"
          },
          assetSubType: {
            assetTypeId: "10942d1e-02d1-4a3c-82bc-8bb47bd2a0d3",
            assetType: null,
            createdOn: "2021-10-01T02:00:00Z",
            deletedOn: null,
            modifiedOn: "2022-02-04T12:30:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Single Stage Speed Increaser Gearbox",
            description:
              "Single Stage Speed Increaser Gearbox - Synced by Accelerator V2 data migration task",
            id: "4d4437d4-c444-4d55-8686-5fe3bf4a32fc"
          },
          productionUnit: null,
          site: {
            siteCode: "LIM",
            plotly: "http://10.202.34.100:8030/LIM?asset_name=@AssetCode",
            powerBi: null,
            sharePoint:
              "https://ui.sharepoint.com/sites/lim/SitePages/EVE-Search.aspx?searchquery=@AssetCode*",
            timeZone: "Eastern Standard Time",
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Lima",
            description: null,
            id: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6"
          },
          subArea: {
            productionUnitId: "5d73c2c1-52a2-4467-919c-9c00d236a3b6",
            productionUnit: null,
            createdOn: "2020-10-15T17:46:00Z",
            deletedOn: null,
            modifiedOn: "2020-10-15T17:46:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Ammonia Feed",
            description: null,
            id: "e6e1a145-3320-4bb6-926e-0eef31d8490c"
          },
          plant: {
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Urea",
            description: null,
            id: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15"
          },
          assetCode: "IPG132A.I",
          plantArea: null,
          createdOn: "2022-12-08T00:31:00Z",
          deletedOn: null,
          modifiedOn: "2022-12-08T00:31:00Z",
          createdBy: "sa",
          deletedBy: null,
          modifiedBy: "sa",
          name: "AMMONIA FEED PUMP A SPEED INCREASER GEAR, 741228",
          description: "GEAR, AMMONIA FEED PUMP A SPEED INCREASER, 741228",
          id: "6c98edac-9e8c-22ee-e986-0a64aa2d8dfb"
        },
        {
          assetTypeId: "63df1548-6f86-4dcd-bd0d-bc8c4cec1f93",
          assetSubTypeId: "7f7cb553-9dc2-407d-87cf-7e6a1d29a3e1",
          productionUnitId: null,
          siteId: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6",
          subAreaId: "e6e1a145-3320-4bb6-926e-0eef31d8490c",
          plantId: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15",
          assetType: {
            createdOn: "2024-10-16T18:25:24.463181Z",
            deletedOn: null,
            modifiedOn: "2024-10-16T18:25:24.463181Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Motors & Generators",
            description: "Motors & Generators - Synced by Accelerator V2 data migration task",
            id: "63df1548-6f86-4dcd-bd0d-bc8c4cec1f93"
          },
          assetSubType: {
            assetTypeId: "63df1548-6f86-4dcd-bd0d-bc8c4cec1f93",
            assetType: null,
            createdOn: "2021-10-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2022-02-04T12:30:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Induction Motor",
            description: "Induction Motor - Synced by Accelerator V2 data migration task",
            id: "7f7cb553-9dc2-407d-87cf-7e6a1d29a3e1"
          },
          productionUnit: null,
          site: {
            siteCode: "LIM",
            plotly: "http://10.202.34.100:8030/LIM?asset_name=@AssetCode",
            powerBi: null,
            sharePoint:
              "https://ui.sharepoint.com/sites/lim/SitePages/EVE-Search.aspx?searchquery=@AssetCode*",
            timeZone: "Eastern Standard Time",
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Lima",
            description: null,
            id: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6"
          },
          subArea: {
            productionUnitId: "5d73c2c1-52a2-4467-919c-9c00d236a3b6",
            productionUnit: null,
            createdOn: "2020-10-15T17:46:00Z",
            deletedOn: null,
            modifiedOn: "2020-10-15T17:46:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Ammonia Feed",
            description: null,
            id: "e6e1a145-3320-4bb6-926e-0eef31d8490c"
          },
          plant: {
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Urea",
            description: null,
            id: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15"
          },
          assetCode: "IPM132B.I",
          plantArea: null,
          createdOn: "2022-12-08T00:31:00Z",
          deletedOn: null,
          modifiedOn: "2022-12-08T00:31:00Z",
          createdBy: "sa",
          deletedBy: null,
          modifiedBy: "sa",
          name: "AMMONIA FEED PUMP B MOTOR, 721233",
          description: "MOTOR, AMMONIA FEED PUMP B, 721233",
          id: "cdec59c9-ded6-64c6-3014-776b4bbc07de"
        },
        {
          assetTypeId: "d5d9dc10-331f-4d0b-84f1-8942fb7ba23e",
          assetSubTypeId: "cb424770-8956-4ab7-9170-e73070860ee6",
          productionUnitId: null,
          siteId: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6",
          subAreaId: "e6e1a145-3320-4bb6-926e-0eef31d8490c",
          plantId: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15",
          assetType: {
            createdOn: "2024-10-16T18:25:24.463181Z",
            deletedOn: null,
            modifiedOn: "2024-10-16T18:25:24.463181Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Process Pumps",
            description: "Process Pumps - Synced by Accelerator V2 data migration task",
            id: "d5d9dc10-331f-4d0b-84f1-8942fb7ba23e"
          },
          assetSubType: {
            assetTypeId: "d5d9dc10-331f-4d0b-84f1-8942fb7ba23e",
            assetType: null,
            createdOn: "2021-10-01T00:00:00Z",
            deletedOn: null,
            modifiedOn: "2022-02-04T12:30:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Between Bearings Centrifugal Pump",
            description:
              "Between Bearings Centrifugal Pump - Synced by Accelerator V2 data migration task",
            id: "cb424770-8956-4ab7-9170-e73070860ee6"
          },
          productionUnit: null,
          site: {
            siteCode: "LIM",
            plotly: "http://10.202.34.100:8030/LIM?asset_name=@AssetCode",
            powerBi: null,
            sharePoint:
              "https://ui.sharepoint.com/sites/lim/SitePages/EVE-Search.aspx?searchquery=@AssetCode*",
            timeZone: "Eastern Standard Time",
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Lima",
            description: null,
            id: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6"
          },
          subArea: {
            productionUnitId: "5d73c2c1-52a2-4467-919c-9c00d236a3b6",
            productionUnit: null,
            createdOn: "2020-10-15T17:46:00Z",
            deletedOn: null,
            modifiedOn: "2020-10-15T17:46:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Ammonia Feed",
            description: null,
            id: "e6e1a145-3320-4bb6-926e-0eef31d8490c"
          },
          plant: {
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Urea",
            description: null,
            id: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15"
          },
          assetCode: "IP132B.I",
          plantArea: null,
          createdOn: "2022-12-08T00:31:00Z",
          deletedOn: null,
          modifiedOn: "2022-12-08T00:31:00Z",
          createdBy: "sa",
          deletedBy: null,
          modifiedBy: "sa",
          name: "EBARA AMMONIA FEED B PUMP (SOUTH), 701231",
          description: "PUMP, EBARA AMMONIA FEED B (SOUTH), 701231",
          id: "47545cbc-58a4-0824-a3b5-34e2383d92ba"
        },
        {
          assetTypeId: "10942d1e-02d1-4a3c-82bc-8bb47bd2a0d3",
          assetSubTypeId: "4d4437d4-c444-4d55-8686-5fe3bf4a32fc",
          productionUnitId: null,
          siteId: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6",
          subAreaId: "e6e1a145-3320-4bb6-926e-0eef31d8490c",
          plantId: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15",
          assetType: {
            createdOn: "2024-10-16T18:25:24.463181Z",
            deletedOn: null,
            modifiedOn: "2024-10-16T18:25:24.463181Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Gearbox",
            description: "Gearbox - Synced by Accelerator V2 data migration task",
            id: "10942d1e-02d1-4a3c-82bc-8bb47bd2a0d3"
          },
          assetSubType: {
            assetTypeId: "10942d1e-02d1-4a3c-82bc-8bb47bd2a0d3",
            assetType: null,
            createdOn: "2021-10-01T02:00:00Z",
            deletedOn: null,
            modifiedOn: "2022-02-04T12:30:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Single Stage Speed Increaser Gearbox",
            description:
              "Single Stage Speed Increaser Gearbox - Synced by Accelerator V2 data migration task",
            id: "4d4437d4-c444-4d55-8686-5fe3bf4a32fc"
          },
          productionUnit: null,
          site: {
            siteCode: "LIM",
            plotly: "http://10.202.34.100:8030/LIM?asset_name=@AssetCode",
            powerBi: null,
            sharePoint:
              "https://ui.sharepoint.com/sites/lim/SitePages/EVE-Search.aspx?searchquery=@AssetCode*",
            timeZone: "Eastern Standard Time",
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Lima",
            description: null,
            id: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6"
          },
          subArea: {
            productionUnitId: "5d73c2c1-52a2-4467-919c-9c00d236a3b6",
            productionUnit: null,
            createdOn: "2020-10-15T17:46:00Z",
            deletedOn: null,
            modifiedOn: "2020-10-15T17:46:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Ammonia Feed",
            description: null,
            id: "e6e1a145-3320-4bb6-926e-0eef31d8490c"
          },
          plant: {
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Urea",
            description: null,
            id: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15"
          },
          assetCode: "IPG132B.I",
          plantArea: null,
          createdOn: "2022-12-08T00:31:00Z",
          deletedOn: null,
          modifiedOn: "2022-12-08T00:31:00Z",
          createdBy: "sa",
          deletedBy: null,
          modifiedBy: "sa",
          name: "AMMONIA FEED PUMP B SPEED INCREASER GEAR, 741229",
          description: "GEAR, AMMONIA FEED PUMP B SPEED INCREASER, 741229",
          id: "06984f68-0695-ed5c-7230-35682d086165"
        },
        {
          assetTypeId: "e0e82e9a-e795-475d-8590-52a0819f1912",
          assetSubTypeId: "b37ee5ef-c5b7-4901-afbb-483b29c9b3ac",
          productionUnitId: null,
          siteId: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6",
          subAreaId: "e6e1a145-3320-4bb6-926e-0eef31d8490c",
          plantId: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15",
          assetType: {
            createdOn: "2024-10-16T18:25:24.463181Z",
            deletedOn: null,
            modifiedOn: "2024-10-16T18:25:24.463181Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Steam Turbines",
            description: "Steam Turbines - Synced by Accelerator V2 data migration task",
            id: "e0e82e9a-e795-475d-8590-52a0819f1912"
          },
          assetSubType: {
            assetTypeId: "e0e82e9a-e795-475d-8590-52a0819f1912",
            assetType: null,
            createdOn: "2021-10-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2022-02-04T12:30:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Horizontal Single Stage Back Pressure Steam Turbine",
            description:
              "Horizontal Single Stage Back Pressure Steam Turbine - Synced by Accelerator V2 data migration task",
            id: "b37ee5ef-c5b7-4901-afbb-483b29c9b3ac"
          },
          productionUnit: null,
          site: {
            siteCode: "LIM",
            plotly: "http://10.202.34.100:8030/LIM?asset_name=@AssetCode",
            powerBi: null,
            sharePoint:
              "https://ui.sharepoint.com/sites/lim/SitePages/EVE-Search.aspx?searchquery=@AssetCode*",
            timeZone: "Eastern Standard Time",
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Lima",
            description: null,
            id: "e3ff3c51-160b-4df3-89c5-5180fd2e02d6"
          },
          subArea: {
            productionUnitId: "5d73c2c1-52a2-4467-919c-9c00d236a3b6",
            productionUnit: null,
            createdOn: "2020-10-15T17:46:00Z",
            deletedOn: null,
            modifiedOn: "2020-10-15T17:46:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Ammonia Feed",
            description: null,
            id: "e6e1a145-3320-4bb6-926e-0eef31d8490c"
          },
          plant: {
            createdOn: "2024-01-01T01:00:00Z",
            deletedOn: null,
            modifiedOn: "2024-01-01T01:00:00Z",
            createdBy: "sa",
            deletedBy: null,
            modifiedBy: "sa",
            name: "Urea",
            description: null,
            id: "5a82c6a7-b1b6-40a8-a53e-79767ab22f15"
          },
          assetCode: "IT132A.I",
          plantArea: null,
          createdOn: "2022-12-08T00:31:00Z",
          deletedOn: null,
          modifiedOn: "2022-12-08T00:31:00Z",
          createdBy: "sa",
          deletedBy: null,
          modifiedBy: "sa",
          name: "AMMONIA FEED PUMP A TURBINE, 711232",
          description: "TURBINE, AMMONIA FEED PUMP A, 711232",
          id: "b92f8948-7b4f-1199-2399-719039f94c3d"
        }
      ],
      createdOn: "2022-12-08T00:31:00Z",
      deletedOn: null,
      modifiedOn: "2022-12-08T00:31:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: " AMMONIA FEED PUMPS A/B SEAL WATER PUMP AUX MOTOR, 721258",
      description: "MOTOR, AMMONIA FEED PUMPS A/B SEAL WATER PUMP AUX, 721258",
      id: "d2b2e493-2c10-d4f3-9ac1-5d7fee63412f"
    },
    {
      assetTypeId: "8a5b2820-06f9-48e8-05dd-08da7666d9e3",
      assetSubTypeId: "3e659964-a941-48b6-95e1-08da795739db",
      productionUnitId: "9364f296-763e-43b0-ad6c-81f53f2b5dd7",
      siteId: "95b4e211-37dd-4e69-83f1-ee60e01f2e44",
      subAreaId: "b8eb1c5c-d6b7-4399-8970-c01b439f48c2",
      plantId: "f049670b-cb07-4669-83f7-548304691587",
      assetType: {
        createdOn: "2022-08-04T22:16:00Z",
        deletedOn: null,
        modifiedOn: "2022-08-04T22:16:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Column",
        description: "Column - Synced by Accelerator V2 data migration task",
        id: "8a5b2820-06f9-48e8-05dd-08da7666d9e3"
      },
      assetSubType: {
        assetTypeId: "8a5b2820-06f9-48e8-05dd-08da7666d9e3",
        assetType: null,
        createdOn: "2022-08-08T16:01:00Z",
        deletedOn: null,
        modifiedOn: "2022-08-08T16:01:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Ammonia Absorber",
        description: "Ammonia Absorber - Synced by Accelerator V2 data migration task",
        id: "3e659964-a941-48b6-95e1-08da795739db"
      },
      productionUnit: {
        plantId: "f049670b-cb07-4669-83f7-548304691587",
        plant: null,
        createdOn: "2024-01-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2024-01-01T01:00:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Ammonia 2",
        description: null,
        id: "9364f296-763e-43b0-ad6c-81f53f2b5dd7"
      },
      site: {
        siteCode: "TRM",
        plotly: "http://10.202.34.100:9040/TRM?asset_name=@AssetCode",
        powerBi:
          "https://app.powerbi.com/groups/f500b677-ce3a-428f-92eb-7b06b0cfa8a3/reports/8d08d148-22f8-428a-a496-a12d6e56a66b/ReportSection?filter=TRN_All/Train eq '@Train' and TRN_All/TagAssetID eq '@AssetCode' and TRN_All/TagNROCID eq '@Identifier'",
        sharePoint: null,
        timeZone: "Atlantic Standard Time",
        createdOn: "2024-01-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2024-01-01T01:00:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Trinidad",
        description: null,
        id: "95b4e211-37dd-4e69-83f1-ee60e01f2e44"
      },
      subArea: {
        productionUnitId: "9364f296-763e-43b0-ad6c-81f53f2b5dd7",
        productionUnit: null,
        createdOn: "2022-07-26T15:09:00Z",
        deletedOn: null,
        modifiedOn: "2022-07-26T15:09:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Purge Gas Recovery",
        description: null,
        id: "b8eb1c5c-d6b7-4399-8970-c01b439f48c2"
      },
      plant: {
        createdOn: "2024-01-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2024-01-01T01:00:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Ammonia",
        description: null,
        id: "f049670b-cb07-4669-83f7-548304691587"
      },
      assetCode: "C100----2",
      plantArea: null,
      train: null,
      trainPosition: null,
      relatedAssetIds: [],
      relatedAssets: [],
      createdOn: "2022-08-19T18:40:00Z",
      deletedOn: null,
      modifiedOn: "2023-12-07T23:08:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "C-100",
      description: "ARU AMMONIA ABSORBER 106L",
      id: "081b0214-86b7-42eb-cbd8-08da821230c4"
    },
    {
      assetTypeId: "8a5b2820-06f9-48e8-05dd-08da7666d9e3",
      assetSubTypeId: "3e659964-a941-48b6-95e1-08da795739db",
      productionUnitId: "d6359abf-c909-42ea-b5df-a4bfd9b4457c",
      siteId: "95b4e211-37dd-4e69-83f1-ee60e01f2e44",
      subAreaId: "ea0b673f-933a-45cb-833b-320b51d47d98",
      plantId: "f049670b-cb07-4669-83f7-548304691587",
      assetType: {
        createdOn: "2022-08-04T22:16:00Z",
        deletedOn: null,
        modifiedOn: "2022-08-04T22:16:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Column",
        description: "Column - Synced by Accelerator V2 data migration task",
        id: "8a5b2820-06f9-48e8-05dd-08da7666d9e3"
      },
      assetSubType: {
        assetTypeId: "8a5b2820-06f9-48e8-05dd-08da7666d9e3",
        assetType: null,
        createdOn: "2022-08-08T16:01:00Z",
        deletedOn: null,
        modifiedOn: "2022-08-08T16:01:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Ammonia Absorber",
        description: "Ammonia Absorber - Synced by Accelerator V2 data migration task",
        id: "3e659964-a941-48b6-95e1-08da795739db"
      },
      productionUnit: {
        plantId: "f049670b-cb07-4669-83f7-548304691587",
        plant: null,
        createdOn: "2024-01-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2024-01-01T01:00:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Ammonia 1",
        description: null,
        id: "d6359abf-c909-42ea-b5df-a4bfd9b4457c"
      },
      site: {
        siteCode: "TRM",
        plotly: "http://10.202.34.100:9040/TRM?asset_name=@AssetCode",
        powerBi:
          "https://app.powerbi.com/groups/f500b677-ce3a-428f-92eb-7b06b0cfa8a3/reports/8d08d148-22f8-428a-a496-a12d6e56a66b/ReportSection?filter=TRN_All/Train eq '@Train' and TRN_All/TagAssetID eq '@AssetCode' and TRN_All/TagNROCID eq '@Identifier'",
        sharePoint: null,
        timeZone: "Atlantic Standard Time",
        createdOn: "2024-01-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2024-01-01T01:00:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Trinidad",
        description: null,
        id: "95b4e211-37dd-4e69-83f1-ee60e01f2e44"
      },
      subArea: {
        productionUnitId: "d6359abf-c909-42ea-b5df-a4bfd9b4457c",
        productionUnit: null,
        createdOn: "2022-07-26T15:03:00Z",
        deletedOn: null,
        modifiedOn: "2022-07-26T15:03:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Purge Gas Recovery",
        description: null,
        id: "ea0b673f-933a-45cb-833b-320b51d47d98"
      },
      plant: {
        createdOn: "2024-01-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2024-01-01T01:00:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Ammonia",
        description: null,
        id: "f049670b-cb07-4669-83f7-548304691587"
      },
      assetCode: "H33",
      plantArea: null,
      train: null,
      trainPosition: null,
      relatedAssetIds: [],
      relatedAssets: [],
      createdOn: "2023-12-07T23:12:00Z",
      deletedOn: null,
      modifiedOn: "2023-12-07T23:12:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "H33",
      description: "HRU AMMONIA STRIPPER",
      id: "44894939-a791-42b1-774c-08da7a1c71c6"
    },
    {
      assetTypeId: "8a5b2820-06f9-48e8-05dd-08da7666d9e3",
      assetSubTypeId: "3e659964-a941-48b6-95e1-08da795739db",
      productionUnitId: "228fb650-d14a-428a-9219-f2d065796199",
      siteId: "95b4e211-37dd-4e69-83f1-ee60e01f2e44",
      subAreaId: "c2436934-7355-489d-8794-9e1606229979",
      plantId: "f049670b-cb07-4669-83f7-548304691587",
      assetType: {
        createdOn: "2022-08-04T22:16:00Z",
        deletedOn: null,
        modifiedOn: "2022-08-04T22:16:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Column",
        description: "Column - Synced by Accelerator V2 data migration task",
        id: "8a5b2820-06f9-48e8-05dd-08da7666d9e3"
      },
      assetSubType: {
        assetTypeId: "8a5b2820-06f9-48e8-05dd-08da7666d9e3",
        assetType: null,
        createdOn: "2022-08-08T16:01:00Z",
        deletedOn: null,
        modifiedOn: "2022-08-08T16:01:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Ammonia Absorber",
        description: "Ammonia Absorber - Synced by Accelerator V2 data migration task",
        id: "3e659964-a941-48b6-95e1-08da795739db"
      },
      productionUnit: {
        plantId: "f049670b-cb07-4669-83f7-548304691587",
        plant: null,
        createdOn: "2024-01-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2024-01-01T01:00:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Ammonia 4",
        description: null,
        id: "228fb650-d14a-428a-9219-f2d065796199"
      },
      site: {
        siteCode: "TRM",
        plotly: "http://10.202.34.100:9040/TRM?asset_name=@AssetCode",
        powerBi:
          "https://app.powerbi.com/groups/f500b677-ce3a-428f-92eb-7b06b0cfa8a3/reports/8d08d148-22f8-428a-a496-a12d6e56a66b/ReportSection?filter=TRN_All/Train eq '@Train' and TRN_All/TagAssetID eq '@AssetCode' and TRN_All/TagNROCID eq '@Identifier'",
        sharePoint: null,
        timeZone: "Atlantic Standard Time",
        createdOn: "2024-01-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2024-01-01T01:00:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Trinidad",
        description: null,
        id: "95b4e211-37dd-4e69-83f1-ee60e01f2e44"
      },
      subArea: {
        productionUnitId: "228fb650-d14a-428a-9219-f2d065796199",
        productionUnit: null,
        createdOn: "2022-07-26T15:09:00Z",
        deletedOn: null,
        modifiedOn: "2022-07-26T15:09:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Purge Gas Recovery",
        description: null,
        id: "c2436934-7355-489d-8794-9e1606229979"
      },
      plant: {
        createdOn: "2024-01-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2024-01-01T01:00:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Ammonia",
        description: null,
        id: "f049670b-cb07-4669-83f7-548304691587"
      },
      assetCode: "105E-----4",
      plantArea: null,
      train: null,
      trainPosition: null,
      relatedAssetIds: [],
      relatedAssets: [],
      createdOn: "2022-08-20T00:24:00Z",
      deletedOn: null,
      modifiedOn: "2023-12-07T20:57:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "105-E",
      description: "AMMONIA DISTILLATION COLUMN",
      id: "5eb66a75-de94-445c-dc6c-08da824253c7"
    },
    {
      assetTypeId: "8a5b2820-06f9-48e8-05dd-08da7666d9e3",
      assetSubTypeId: "3e659964-a941-48b6-95e1-08da795739db",
      productionUnitId: "fb7babb2-350a-409e-8927-718c1cc71498",
      siteId: "7fd4b725-26a4-4aa5-b66b-a117ac6c3b68",
      subAreaId: "896cdf38-d7ed-4358-85e8-b8af49ce636f",
      plantId: "f049670b-cb07-4669-83f7-548304691587",
      assetType: {
        createdOn: "2022-08-04T22:16:00Z",
        deletedOn: null,
        modifiedOn: "2022-08-04T22:16:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Column",
        description: "Column - Synced by Accelerator V2 data migration task",
        id: "8a5b2820-06f9-48e8-05dd-08da7666d9e3"
      },
      assetSubType: {
        assetTypeId: "8a5b2820-06f9-48e8-05dd-08da7666d9e3",
        assetType: null,
        createdOn: "2022-08-08T16:01:00Z",
        deletedOn: null,
        modifiedOn: "2022-08-08T16:01:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Ammonia Absorber",
        description: "Ammonia Absorber - Synced by Accelerator V2 data migration task",
        id: "3e659964-a941-48b6-95e1-08da795739db"
      },
      productionUnit: {
        plantId: "f049670b-cb07-4669-83f7-548304691587",
        plant: null,
        createdOn: "2024-01-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2024-01-01T01:00:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Ammonia",
        description: null,
        id: "fb7babb2-350a-409e-8927-718c1cc71498"
      },
      site: {
        siteCode: "AGM",
        plotly: "http://10.202.34.100:9010/AGM?asset_name=@AssetCode",
        powerBi:
          "https://app.powerbi.com/groups/f500b677-ce3a-428f-92eb-7b06b0cfa8a3/reports/205e7d68-b706-4fd7-9d91-35e37d58ef1a/ReportSection?filter=AGS_All/Train eq '@Train' and AGS_All/TagNROCID eq '@Identifier'",
        sharePoint:
          "https://ui.sharepoint.com/sites/Augusta/SitePages/EVE-Search.aspx?searchquery=AssetNumber:@AssetCode*",
        timeZone: "Eastern Standard Time",
        createdOn: "2024-01-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2024-01-01T01:00:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Augusta",
        description: null,
        id: "7fd4b725-26a4-4aa5-b66b-a117ac6c3b68"
      },
      subArea: {
        productionUnitId: "fb7babb2-350a-409e-8927-718c1cc71498",
        productionUnit: null,
        createdOn: "2022-07-26T14:59:00Z",
        deletedOn: null,
        modifiedOn: "2022-07-26T14:59:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Purge Gas Recovery",
        description: null,
        id: "896cdf38-d7ed-4358-85e8-b8af49ce636f"
      },
      plant: {
        createdOn: "2024-01-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2024-01-01T01:00:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Ammonia",
        description: null,
        id: "f049670b-cb07-4669-83f7-548304691587"
      },
      assetCode: "C2C6161.B",
      plantArea: null,
      train: null,
      trainPosition: null,
      relatedAssetIds: [],
      relatedAssets: [],
      createdOn: "2022-08-30T20:56:00Z",
      deletedOn: null,
      modifiedOn: "2023-12-07T18:52:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "C6161",
      description:
        "ABSORBER, AMMONIA SPC V3 4973-610-111-4, PROJ C024 (FMEA-MEDIUM) - COLUMN.ABSORBER",
      id: "6c7a6d61-3124-4d5d-c574-08da8ac93dbb"
    },
    {
      assetTypeId: "245dd5e5-23a8-40a7-559f-08da763918e7",
      assetSubTypeId: "d4b07892-a031-416a-52e9-08da7669bdb4",
      productionUnitId: "a86ad144-1bb4-4fe2-bb0c-eac70aea7fcc",
      siteId: "95b4e211-37dd-4e69-83f1-ee60e01f2e44",
      subAreaId: "ebc0b68d-f27d-4f98-8fae-83b44688473d",
      plantId: "f049670b-cb07-4669-83f7-548304691587",
      assetType: {
        createdOn: "2022-08-04T16:48:00Z",
        deletedOn: null,
        modifiedOn: "2022-08-04T16:48:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Reactor",
        description: "Reactor - Synced by Accelerator V2 data migration task",
        id: "245dd5e5-23a8-40a7-559f-08da763918e7"
      },
      assetSubType: {
        assetTypeId: "245dd5e5-23a8-40a7-559f-08da763918e7",
        assetType: null,
        createdOn: "2022-08-04T22:36:00Z",
        deletedOn: null,
        modifiedOn: "2022-08-04T22:36:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Ammonia Converter",
        description: "Ammonia Converter - Synced by Accelerator V2 data migration task",
        id: "d4b07892-a031-416a-52e9-08da7669bdb4"
      },
      productionUnit: {
        plantId: "f049670b-cb07-4669-83f7-548304691587",
        plant: null,
        createdOn: "2024-01-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2024-01-01T01:00:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Ammonia 3",
        description: null,
        id: "a86ad144-1bb4-4fe2-bb0c-eac70aea7fcc"
      },
      site: {
        siteCode: "TRM",
        plotly: "http://10.202.34.100:9040/TRM?asset_name=@AssetCode",
        powerBi:
          "https://app.powerbi.com/groups/f500b677-ce3a-428f-92eb-7b06b0cfa8a3/reports/8d08d148-22f8-428a-a496-a12d6e56a66b/ReportSection?filter=TRN_All/Train eq '@Train' and TRN_All/TagAssetID eq '@AssetCode' and TRN_All/TagNROCID eq '@Identifier'",
        sharePoint: null,
        timeZone: "Atlantic Standard Time",
        createdOn: "2024-01-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2024-01-01T01:00:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Trinidad",
        description: null,
        id: "95b4e211-37dd-4e69-83f1-ee60e01f2e44"
      },
      subArea: {
        productionUnitId: "a86ad144-1bb4-4fe2-bb0c-eac70aea7fcc",
        productionUnit: null,
        createdOn: "2022-02-20T22:45:00Z",
        deletedOn: null,
        modifiedOn: "2022-02-20T22:45:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Ammonia Synthesis",
        description: null,
        id: "ebc0b68d-f27d-4f98-8fae-83b44688473d"
      },
      plant: {
        createdOn: "2024-01-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2024-01-01T01:00:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Ammonia",
        description: null,
        id: "f049670b-cb07-4669-83f7-548304691587"
      },
      assetCode: "D-1008",
      plantArea: null,
      train: null,
      trainPosition: null,
      relatedAssetIds: [],
      relatedAssets: [],
      createdOn: "2022-08-19T22:10:00Z",
      deletedOn: null,
      modifiedOn: "2023-12-07T22:40:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "D-1008",
      description: "SYNTHESIS CONVERTER (EAST CONVERTER)",
      id: "80054165-7984-4f4e-34fd-08da822f5053"
    },
    {
      assetTypeId: "245dd5e5-23a8-40a7-559f-08da763918e7",
      assetSubTypeId: "d4b07892-a031-416a-52e9-08da7669bdb4",
      productionUnitId: "a86ad144-1bb4-4fe2-bb0c-eac70aea7fcc",
      siteId: "95b4e211-37dd-4e69-83f1-ee60e01f2e44",
      subAreaId: "ebc0b68d-f27d-4f98-8fae-83b44688473d",
      plantId: "f049670b-cb07-4669-83f7-548304691587",
      assetType: {
        createdOn: "2022-08-04T16:48:00Z",
        deletedOn: null,
        modifiedOn: "2022-08-04T16:48:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Reactor",
        description: "Reactor - Synced by Accelerator V2 data migration task",
        id: "245dd5e5-23a8-40a7-559f-08da763918e7"
      },
      assetSubType: {
        assetTypeId: "245dd5e5-23a8-40a7-559f-08da763918e7",
        assetType: null,
        createdOn: "2022-08-04T22:36:00Z",
        deletedOn: null,
        modifiedOn: "2022-08-04T22:36:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Ammonia Converter",
        description: "Ammonia Converter - Synced by Accelerator V2 data migration task",
        id: "d4b07892-a031-416a-52e9-08da7669bdb4"
      },
      productionUnit: {
        plantId: "f049670b-cb07-4669-83f7-548304691587",
        plant: null,
        createdOn: "2024-01-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2024-01-01T01:00:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Ammonia 3",
        description: null,
        id: "a86ad144-1bb4-4fe2-bb0c-eac70aea7fcc"
      },
      site: {
        siteCode: "TRM",
        plotly: "http://10.202.34.100:9040/TRM?asset_name=@AssetCode",
        powerBi:
          "https://app.powerbi.com/groups/f500b677-ce3a-428f-92eb-7b06b0cfa8a3/reports/8d08d148-22f8-428a-a496-a12d6e56a66b/ReportSection?filter=TRN_All/Train eq '@Train' and TRN_All/TagAssetID eq '@AssetCode' and TRN_All/TagNROCID eq '@Identifier'",
        sharePoint: null,
        timeZone: "Atlantic Standard Time",
        createdOn: "2024-01-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2024-01-01T01:00:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Trinidad",
        description: null,
        id: "95b4e211-37dd-4e69-83f1-ee60e01f2e44"
      },
      subArea: {
        productionUnitId: "a86ad144-1bb4-4fe2-bb0c-eac70aea7fcc",
        productionUnit: null,
        createdOn: "2022-02-20T22:45:00Z",
        deletedOn: null,
        modifiedOn: "2022-02-20T22:45:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Ammonia Synthesis",
        description: null,
        id: "ebc0b68d-f27d-4f98-8fae-83b44688473d"
      },
      plant: {
        createdOn: "2024-01-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2024-01-01T01:00:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Ammonia",
        description: null,
        id: "f049670b-cb07-4669-83f7-548304691587"
      },
      assetCode: "D-708B",
      plantArea: null,
      train: null,
      trainPosition: null,
      relatedAssetIds: [],
      relatedAssets: [],
      createdOn: "2022-08-19T22:10:00Z",
      deletedOn: null,
      modifiedOn: "2023-12-07T22:40:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "D-708B",
      description: "SYNTHESIS CONVERTER (SOUTH CONVERTER)",
      id: "e9ad8bee-d41e-4821-3506-08da822f5053"
    },
    {
      assetTypeId: "245dd5e5-23a8-40a7-559f-08da763918e7",
      assetSubTypeId: "d4b07892-a031-416a-52e9-08da7669bdb4",
      productionUnitId: "d73ed065-fa56-437c-8b2b-a1015a1c2da4",
      siteId: "c27ef737-f553-47d1-badf-fba9205d2b9d",
      subAreaId: "d22f50d2-9e5b-4e3f-8a32-a8106dc03c1b",
      plantId: "f049670b-cb07-4669-83f7-548304691587",
      assetType: {
        createdOn: "2022-08-04T16:48:00Z",
        deletedOn: null,
        modifiedOn: "2022-08-04T16:48:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Reactor",
        description: "Reactor - Synced by Accelerator V2 data migration task",
        id: "245dd5e5-23a8-40a7-559f-08da763918e7"
      },
      assetSubType: {
        assetTypeId: "245dd5e5-23a8-40a7-559f-08da763918e7",
        assetType: null,
        createdOn: "2022-08-04T22:36:00Z",
        deletedOn: null,
        modifiedOn: "2022-08-04T22:36:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Ammonia Converter",
        description: "Ammonia Converter - Synced by Accelerator V2 data migration task",
        id: "d4b07892-a031-416a-52e9-08da7669bdb4"
      },
      productionUnit: {
        plantId: "f049670b-cb07-4669-83f7-548304691587",
        plant: null,
        createdOn: "2024-01-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2024-01-01T01:00:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Ammonia 9",
        description: null,
        id: "d73ed065-fa56-437c-8b2b-a1015a1c2da4"
      },
      site: {
        siteCode: "RNO",
        plotly: "http://10.202.34.100:9030/RNO?asset_name=@AssetCode",
        powerBi: null,
        sharePoint:
          "https://ui.sharepoint.com/sites/rfo/SitePages/EVE-Search.aspx?eveSearchQuery=FunctionalLocation:@AssetCode*",
        timeZone: "Mountain Standard Time",
        createdOn: "2024-01-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2024-01-01T01:00:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Redwater",
        description: null,
        id: "c27ef737-f553-47d1-badf-fba9205d2b9d"
      },
      subArea: {
        productionUnitId: "d73ed065-fa56-437c-8b2b-a1015a1c2da4",
        productionUnit: null,
        createdOn: "2022-07-26T15:09:00Z",
        deletedOn: null,
        modifiedOn: "2022-07-26T15:09:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Ammonia Synthesis",
        description: null,
        id: "d22f50d2-9e5b-4e3f-8a32-a8106dc03c1b"
      },
      plant: {
        createdOn: "2024-01-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2024-01-01T01:00:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Ammonia",
        description: null,
        id: "f049670b-cb07-4669-83f7-548304691587"
      },
      assetCode: "RW-D921",
      plantArea: null,
      train: null,
      trainPosition: null,
      relatedAssetIds: [],
      relatedAssets: [],
      createdOn: "2022-08-30T22:42:00Z",
      deletedOn: null,
      modifiedOn: "2023-12-19T14:41:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "D-921",
      description: "AMMONIA CONVERTER",
      id: "5777fb17-5557-48a4-5ed9-08da8ad8cc9a"
    },
    {
      assetTypeId: "245dd5e5-23a8-40a7-559f-08da763918e7",
      assetSubTypeId: "d4b07892-a031-416a-52e9-08da7669bdb4",
      productionUnitId: "fb7babb2-350a-409e-8927-718c1cc71498",
      siteId: "ee7aaf0e-7655-4371-bed7-dbfb489e5aef",
      subAreaId: "6a6e4d2d-9cd3-43fa-ad76-194fb3c2d7dc",
      plantId: "f049670b-cb07-4669-83f7-548304691587",
      assetType: {
        createdOn: "2022-08-04T16:48:00Z",
        deletedOn: null,
        modifiedOn: "2022-08-04T16:48:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Reactor",
        description: "Reactor - Synced by Accelerator V2 data migration task",
        id: "245dd5e5-23a8-40a7-559f-08da763918e7"
      },
      assetSubType: {
        assetTypeId: "245dd5e5-23a8-40a7-559f-08da763918e7",
        assetType: null,
        createdOn: "2022-08-04T22:36:00Z",
        deletedOn: null,
        modifiedOn: "2022-08-04T22:36:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Ammonia Converter",
        description: "Ammonia Converter - Synced by Accelerator V2 data migration task",
        id: "d4b07892-a031-416a-52e9-08da7669bdb4"
      },
      productionUnit: {
        plantId: "f049670b-cb07-4669-83f7-548304691587",
        plant: null,
        createdOn: "2024-01-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2024-01-01T01:00:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Ammonia",
        description: null,
        id: "fb7babb2-350a-409e-8927-718c1cc71498"
      },
      site: {
        siteCode: "BNO",
        plotly: null,
        powerBi: null,
        sharePoint: null,
        timeZone: "Central Standard Time",
        createdOn: "2024-01-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2024-01-01T01:00:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Borger",
        description: null,
        id: "ee7aaf0e-7655-4371-bed7-dbfb489e5aef"
      },
      subArea: {
        productionUnitId: "fb7babb2-350a-409e-8927-718c1cc71498",
        productionUnit: null,
        createdOn: "2020-10-15T17:45:00Z",
        deletedOn: null,
        modifiedOn: "2020-10-15T17:45:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Ammonia Synthesis",
        description: null,
        id: "6a6e4d2d-9cd3-43fa-ad76-194fb3c2d7dc"
      },
      plant: {
        createdOn: "2024-01-01T01:00:00Z",
        deletedOn: null,
        modifiedOn: "2024-01-01T01:00:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "Ammonia",
        description: null,
        id: "f049670b-cb07-4669-83f7-548304691587"
      },
      assetCode: "BG-122CB",
      plantArea: null,
      train: null,
      trainPosition: null,
      relatedAssetIds: [],
      relatedAssets: [],
      createdOn: "2022-08-30T20:50:00Z",
      deletedOn: null,
      modifiedOn: "2023-12-07T18:43:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "122CB",
      description: "122CB Converter Inner Bed Exchanger",
      id: "70864ad2-8356-4c50-f6e1-08da8ac93dbb"
    }
  ],
  hasMore: true,
  total: 6857
};

export const MockResponseAcceleratorTagsSearch = {
  data: [
    {
      tungstenSystem: "redwaterphd",
      maximumEu: 0,
      minimumEu: 0,
      operationalHi: null,
      operationalLo: null,
      target: null,
      typicalValue: null,
      engineeringUnit: null,
      extendedDescription: null,
      createdOn: "2022-08-04T21:15:00Z",
      deletedOn: null,
      modifiedOn: "2022-08-04T21:15:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "A09Y225.MODE",
      description: "PV223 OVERRIDE",
      id: "32fcf9f2-d26a-4d4b-a485-c2b41c4ed710"
    },
    {
      tungstenSystem: "redwaterphd",
      maximumEu: 107,
      minimumEu: -7,
      operationalHi: null,
      operationalLo: null,
      target: null,
      typicalValue: null,
      engineeringUnit: "PCT",
      extendedDescription: null,
      createdOn: "2022-08-04T21:15:00Z",
      deletedOn: null,
      modifiedOn: "2022-08-04T21:15:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "A09Y225.OP",
      description: "PV223 OVERRIDE",
      id: "4297f16c-1d48-4dab-a671-b9c7f660c899"
    },
    {
      tungstenSystem: "enterprisePi",
      maximumEu: 100,
      minimumEu: 0,
      operationalHi: null,
      operationalLo: null,
      target: null,
      typicalValue: null,
      engineeringUnit: "",
      extendedDescription: null,
      createdOn: "2023-09-19T14:44:00Z",
      deletedOn: null,
      modifiedOn: "2023-09-19T14:44:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "RNO_A09Y225.MODE_LIVE",
      description: "PV223 OVERRIDE",
      id: "e2e4813a-f8c9-4db5-a250-08dbb91ed0aa"
    },
    {
      tungstenSystem: "trinidadphd",
      maximumEu: 100,
      minimumEu: 0,
      operationalHi: null,
      operationalLo: null,
      target: null,
      typicalValue: null,
      engineeringUnit: "",
      extendedDescription: null,
      createdOn: "2023-03-30T17:51:00Z",
      deletedOn: null,
      modifiedOn: "2023-03-30T17:51:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "PV9228.PV",
      description: "V3 INDUCT STM POS DMD",
      id: "c7d42524-153d-4737-8840-08db314752e3"
    },
    {
      tungstenSystem: "augustaWonderware",
      maximumEu: 100,
      minimumEu: 0,
      operationalHi: 106.00442714804,
      operationalLo: 86.7308949393051,
      target: 96.3676610436724,
      typicalValue: null,
      engineeringUnit: "PCT",
      extendedDescription: "PV1801 POSITION FB",
      createdOn: "2024-06-10T19:04:00Z",
      deletedOn: null,
      modifiedOn: "2024-06-10T19:04:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "AUHIST.ZI1801A-PV",
      description: "PV1801 POSITION FB",
      id: "a1c9b406-bc34-4f00-070e-08d9fe0c4703"
    },
    {
      tungstenSystem: "enterprisePi",
      maximumEu: 107,
      minimumEu: -7,
      operationalHi: null,
      operationalLo: null,
      target: null,
      typicalValue: null,
      engineeringUnit: "%",
      extendedDescription: null,
      createdOn: "2022-11-15T17:15:00Z",
      deletedOn: null,
      modifiedOn: "2022-11-15T17:15:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "RNO_A09Y225.OP",
      description: "PV223 OVERRIDE",
      id: "526c9bb6-6b98-4124-ac4f-38fa947c8e0c"
    },
    {
      tungstenSystem: "enterprisePi",
      maximumEu: 100,
      minimumEu: 0,
      operationalHi: null,
      operationalLo: null,
      target: null,
      typicalValue: null,
      engineeringUnit: "",
      extendedDescription: null,
      createdOn: "2022-11-15T17:15:00Z",
      deletedOn: null,
      modifiedOn: "2022-11-15T17:15:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "RNO_A09Y225.MODE",
      description: "PV223 OVERRIDE",
      id: "ea3ccc63-7d15-4c8f-8b53-51e14d4345a3"
    },
    {
      tungstenSystem: "enterprisePi",
      maximumEu: 100,
      minimumEu: 0,
      operationalHi: null,
      operationalLo: null,
      target: null,
      typicalValue: null,
      engineeringUnit: "%",
      extendedDescription: null,
      createdOn: "2022-11-16T13:16:00Z",
      deletedOn: null,
      modifiedOn: "2022-11-16T13:16:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "CNO_MDEA_VENTS:PZ0072.PNT",
      description: "PV0072 Position Feedback",
      id: "371a122d-fbb3-40e5-aac0-5eed2256b75a"
    },
    {
      tungstenSystem: "enterprisePi",
      maximumEu: 100,
      minimumEu: 0,
      operationalHi: null,
      operationalLo: null,
      target: null,
      typicalValue: null,
      engineeringUnit: "%",
      extendedDescription: null,
      createdOn: "2023-09-26T12:58:00Z",
      deletedOn: null,
      modifiedOn: "2023-09-26T12:58:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "CNO_H2O_MISC:PZ0901.PNT",
      description: "PV0901 POSITION    FEEDBACK",
      id: "67334527-152d-43e1-6c2d-08dbbe901f2e"
    },
    {
      tungstenSystem: "augustawonderware",
      maximumEu: 100,
      minimumEu: 0,
      operationalHi: null,
      operationalLo: null,
      target: null,
      typicalValue: null,
      engineeringUnit: "PCT",
      extendedDescription: null,
      createdOn: "2022-11-21T22:58:00Z",
      deletedOn: null,
      modifiedOn: "2022-11-21T22:58:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "AUHIST.ZI1301B-PV",
      description: "PV1301B Position Feedback",
      id: "bb91a150-a9f5-4364-90c6-0a0e6a82ce07"
    },
    {
      tungstenSystem: "enterprisePi",
      maximumEu: 100,
      minimumEu: 0,
      operationalHi: null,
      operationalLo: null,
      target: null,
      typicalValue: null,
      engineeringUnit: "%",
      extendedDescription: null,
      createdOn: "2022-08-19T18:07:00Z",
      deletedOn: null,
      modifiedOn: "2022-08-19T18:07:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "CNO_NH3F.PZ0210.PDA",
      description: "PV0210 Valve Position",
      id: "e1eb7044-004b-4552-aea5-00155c92a0f1"
    },
    {
      tungstenSystem: "enterprisePi",
      maximumEu: 100,
      minimumEu: 0,
      operationalHi: null,
      operationalLo: null,
      target: null,
      typicalValue: null,
      engineeringUnit: "%",
      extendedDescription: null,
      createdOn: "2022-08-04T21:15:00Z",
      deletedOn: null,
      modifiedOn: "2022-08-04T21:15:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "CNO_MDEA_VENTS:PV0261.OUT",
      description: "PV0261 Valve Output",
      id: "e82f7ac1-910f-4417-8ae7-7161d8eadeed"
    },
    {
      tungstenSystem: "enterprisePi",
      maximumEu: 100,
      minimumEu: 0,
      operationalHi: null,
      operationalLo: null,
      target: null,
      typicalValue: null,
      engineeringUnit: "PCT",
      extendedDescription: null,
      createdOn: "2022-09-07T17:54:00Z",
      deletedOn: null,
      modifiedOn: "2022-09-07T17:54:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "CNO_101B_LOGIC:PZ0002B.PNT",
      description: "PV0002B Position Feedback",
      id: "02f24bba-b611-4945-a407-9e5aba2e46b9"
    },
    {
      tungstenSystem: "enterprisePi",
      maximumEu: 100,
      minimumEu: 0,
      operationalHi: null,
      operationalLo: null,
      target: null,
      typicalValue: null,
      engineeringUnit: "%",
      extendedDescription: null,
      createdOn: "2023-06-13T13:24:00Z",
      deletedOn: null,
      modifiedOn: "2023-06-13T13:24:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "CNO_545_STEAM:PZ0204.PNT",
      description: "PV0204 Position Feedback",
      id: "29fd818f-d478-44ca-fdf9-08db6c11756e"
    },
    {
      tungstenSystem: "enterprisePi",
      maximumEu: 100,
      minimumEu: 0,
      operationalHi: null,
      operationalLo: null,
      target: null,
      typicalValue: null,
      engineeringUnit: "PCT",
      extendedDescription: null,
      createdOn: "2023-06-13T13:24:00Z",
      deletedOn: null,
      modifiedOn: "2023-06-13T13:24:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "CNO_101B_LOGIC:PZ0013.PNT",
      description: "PV0013 Position Feedback",
      id: "867ea5e4-4db5-49ec-fdfe-08db6c11756e"
    },
    {
      tungstenSystem: "enterprisePi",
      maximumEu: 100,
      minimumEu: 0,
      operationalHi: null,
      operationalLo: null,
      target: null,
      typicalValue: null,
      engineeringUnit: "%",
      extendedDescription: null,
      createdOn: "2022-08-19T18:07:00Z",
      deletedOn: null,
      modifiedOn: "2022-08-19T18:07:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "CNO_102B:PZ0624.PNT",
      description: "PV0624 Position feedback",
      id: "9807703d-42bf-4964-8dae-7564401496da"
    },
    {
      tungstenSystem: "enterprisePi",
      maximumEu: 100,
      minimumEu: 0,
      operationalHi: null,
      operationalLo: null,
      target: null,
      typicalValue: null,
      engineeringUnit: "%",
      extendedDescription: null,
      createdOn: "2023-09-26T12:57:00Z",
      deletedOn: null,
      modifiedOn: "2023-09-26T12:57:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "CNO_H2O_MISC:PZ0902B.PNT",
      description: "PV0902B POSITION   FEEDBACK",
      id: "8e488ef1-e00e-4b52-6c25-08dbbe901f2e"
    },
    {
      tungstenSystem: "enterprisePi",
      maximumEu: 100,
      minimumEu: 0,
      operationalHi: null,
      operationalLo: null,
      target: null,
      typicalValue: null,
      engineeringUnit: "%",
      extendedDescription: null,
      createdOn: "2023-09-26T12:57:00Z",
      deletedOn: null,
      modifiedOn: "2023-09-26T12:57:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "CNO_H2O_MISC:PZ0902A.PNT",
      description: "PV0902A POSITION   FEEDBACK",
      id: "470e05ee-1470-4a6e-6c23-08dbbe901f2e"
    },
    {
      tungstenSystem: "enterprisePi",
      maximumEu: 100,
      minimumEu: 0,
      operationalHi: null,
      operationalLo: null,
      target: null,
      typicalValue: null,
      engineeringUnit: "PCT",
      extendedDescription: null,
      createdOn: "2022-09-07T17:54:00Z",
      deletedOn: null,
      modifiedOn: "2022-09-07T17:54:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "CNO_101B_LOGIC:PZ0002A.PNT",
      description: "PV0002A Position feedback",
      id: "60682d8c-c91d-443a-9b7a-9a57726204b4"
    },
    {
      tungstenSystem: "augustawonderware",
      maximumEu: 100,
      minimumEu: 0,
      operationalHi: null,
      operationalLo: null,
      target: null,
      typicalValue: null,
      engineeringUnit: "PCT",
      extendedDescription: null,
      createdOn: "2022-11-21T22:58:00Z",
      deletedOn: null,
      modifiedOn: "2022-11-21T22:58:00Z",
      createdBy: "sa",
      deletedBy: null,
      modifiedBy: "sa",
      name: "AUHIST.ZI1301A-PV",
      description: "PV1301A Position Feedback",
      id: "c35ef8bf-4568-4783-abb2-54031bc9c76b"
    }
  ],
  hasMore: true,
  total: 8827
};

export const MockResponseTagById = {
  data: {
    tungstenSystem: "enterprisePi",
    maximumEu: 100,
    minimumEu: 0,
    operationalHi: null,
    operationalLo: null,
    target: null,
    typicalValue: null,
    engineeringUnit: "%",
    extendedDescription: null,
    createdOn: "2023-09-26T12:57:00Z",
    deletedOn: null,
    modifiedOn: "2023-09-26T12:57:00Z",
    createdBy: "sa",
    deletedBy: null,
    modifiedBy: "sa",
    name: "CNO_H2O_MISC:PZ0902A.PNT",
    description: "PV0902A POSITION   FEEDBACK",
    id: "470e05ee-1470-4a6e-6c23-08dbbe901f2e"
  }
};

export const MockResponseAcceleratorTagMappedAssetsSearch = {
  data: [
    {
      tagId: "371a122d-fbb3-40e5-aac0-5eed2256b75a",
      assetId: "2a0e8f81-5dc5-4643-c4dc-08dac9bd1487",
      assetPropertyId: "25e9a53f-5e3f-4bc1-8706-08da76858033",
      tag: {
        tungstenSystem: "enterprisePi",
        maximumEu: 100,
        minimumEu: 0,
        operationalHi: null,
        operationalLo: null,
        target: null,
        typicalValue: null,
        engineeringUnit: "%",
        extendedDescription: null,
        createdOn: "2022-11-16T13:16:00Z",
        deletedOn: null,
        modifiedOn: "2022-11-16T13:16:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "CNO_MDEA_VENTS:PZ0072.PNT",
        description: "PV0072 Position Feedback",
        id: "371a122d-fbb3-40e5-aac0-5eed2256b75a"
      },
      asset: {
        assetTypeId: "a85a7ff2-3ce5-45af-39c9-08da796a578a",
        assetSubTypeId: "82fe1fe3-969e-48d5-982f-08da796a9f17",
        productionUnitId: "fb7babb2-350a-409e-8927-718c1cc71498",
        siteId: "2ba1d47e-d82d-4ae2-a04a-ca20399a4da7",
        subAreaId: "0a9b70f4-8527-4f61-81d7-544354da238e",
        plantId: "f049670b-cb07-4669-83f7-548304691587",
        assetType: {
          createdOn: "2022-08-08T18:18:00Z",
          deletedOn: null,
          modifiedOn: "2022-08-08T18:18:00Z",
          createdBy: "sa",
          deletedBy: null,
          modifiedBy: "sa",
          name: "Controller",
          description: "Controller - Synced by Accelerator V2 data migration task",
          id: "a85a7ff2-3ce5-45af-39c9-08da796a578a"
        },
        assetSubType: {
          assetTypeId: "a85a7ff2-3ce5-45af-39c9-08da796a578a",
          assetType: null,
          createdOn: "2022-08-08T18:20:00Z",
          deletedOn: null,
          modifiedOn: "2022-08-08T18:20:00Z",
          createdBy: "sa",
          deletedBy: null,
          modifiedBy: "sa",
          name: "Pressure",
          description: "Pressure - Synced by Accelerator V2 data migration task",
          id: "82fe1fe3-969e-48d5-982f-08da796a9f17"
        },
        productionUnit: {
          plantId: "f049670b-cb07-4669-83f7-548304691587",
          plant: null,
          createdOn: "2024-01-01T01:00:00Z",
          deletedOn: null,
          modifiedOn: "2024-01-01T01:00:00Z",
          createdBy: "sa",
          deletedBy: null,
          modifiedBy: "sa",
          name: "Ammonia",
          description: null,
          id: "fb7babb2-350a-409e-8927-718c1cc71498"
        },
        site: {
          siteCode: "CNO",
          plotly: null,
          powerBi: null,
          sharePoint:
            "https://ui.sharepoint.com/sites/CNO/SitePages/Eve-Search.aspx?eveSearchQuery=((DcDisciplineDescription:PRO*) AND (DocumentSubType:PID*) AND FunctionalLocation:@AssetCode*)",
          timeZone: "Mountain Standard Time",
          createdOn: "2024-01-01T01:00:00Z",
          deletedOn: null,
          modifiedOn: "2024-01-01T01:00:00Z",
          createdBy: "sa",
          deletedBy: null,
          modifiedBy: "sa",
          name: "Carseland",
          description: null,
          id: "2ba1d47e-d82d-4ae2-a04a-ca20399a4da7"
        },
        subArea: {
          productionUnitId: "fb7babb2-350a-409e-8927-718c1cc71498",
          productionUnit: null,
          createdOn: "2020-10-15T17:45:00Z",
          deletedOn: null,
          modifiedOn: "2020-10-15T17:45:00Z",
          createdBy: "sa",
          deletedBy: null,
          modifiedBy: "sa",
          name: "CO2 Removal",
          description: null,
          id: "0a9b70f4-8527-4f61-81d7-544354da238e"
        },
        plant: {
          createdOn: "2024-01-01T01:00:00Z",
          deletedOn: null,
          modifiedOn: "2024-01-01T01:00:00Z",
          createdBy: "sa",
          deletedBy: null,
          modifiedBy: "sa",
          name: "Ammonia",
          description: null,
          id: "f049670b-cb07-4669-83f7-548304691587"
        },
        assetCode: "CA-PV0072",
        criticality: null,
        train: null,
        trainPosition: null,
        relatedAssetIds: null,
        relatedAssets: null,
        sourceAssetIds: null,
        sourceAssets: null,
        createdOn: "2022-11-18T23:32:00Z",
        deletedOn: null,
        modifiedOn: "2023-01-06T20:33:00Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "sa",
        name: "PV0072 103F Stripper Vent Valve",
        description: "PV0072 103F Stripper Vent Valve",
        id: "2a0e8f81-5dc5-4643-c4dc-08dac9bd1487"
      },
      assetProperty: {
        measureGroup: "Valve",
        measureUnit: "Feedback Position",
        createdOn: "2024-10-02T20:12:44.145017Z",
        deletedOn: null,
        modifiedOn: "2025-02-18T23:07:14.646567Z",
        createdBy: "sa",
        deletedBy: null,
        modifiedBy: "Mary-Ellen.Harrison@nutrien.com",
        name: "Valve - Feedback Position",
        description: "",
        id: "25e9a53f-5e3f-4bc1-8706-08da76858033"
      },
      priority: 1,
      isCritical: null,
      layoutRow: null,
      createdOn: "0001-01-01T00:00:00",
      deletedOn: null,
      modifiedOn: "0001-01-01T00:00:00",
      createdBy: null,
      deletedBy: null,
      modifiedBy: null,
      name: null,
      description: null,
      id: "4d86c6aa-bfdf-4129-30a9-08daca8ce82f"
    }
  ],
  hasMore: false,
  total: 1
};

export const MockResponseAcceleratorTagWeightById = {
  data: {
    tagId: "470e05ee-1470-4a6e-6c23-08dbbe901f2e",
    tag: null,
    personaId: "608e976b-9906-4a47-bcd1-d5e961c54ef3",
    persona: null,
    assetId: "2a0e8f81-5dc5-4643-c4dc-08dac9bd1487",
    asset: null,
    productionUnit: null,
    weight: 500,
    createdOn: "2025-02-05T19:42:31.735622Z",
    deletedOn: null,
    modifiedOn: "2025-02-18T17:58:59.40406Z",
    createdBy: "allen.nguyen@nutrien.com",
    deletedBy: null,
    modifiedBy: "ian.lovett@nutrien.com",
    name: null,
    description: null,
    id: "3abb2c2c-9f4e-4085-bcdd-32bdf0985c58"
  }
};
