import { _case } from "@packages/case-management-queries";
import {
  styled,
  MUIDrawer as Drawer,
  DrawerLayout,
  Tab,
  TabContext,
  TabList,
  TabPanel,
  Button,
  LabelValue,
  LabelValueGrid,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@packages/theme-mui-v5";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { CaseStageHistoriesTimeline } from "./CaseStageHistoriesTimeline";
import { CaseCommunicationsTimeline } from "./CaseCommunicationsTimeline";
import { CasePersonnel } from "./CasePersonnel";
import { formatDateTimeShort } from "@packages/core";
import { CaseFileAttachment } from "./CaseFileAttachment";
import { ExistingCaseFileAttachment } from "./CaseManagementTypes";

// TODO DrawerResizable sets atom width
export const casePreviewDrawerWidth = 480;

const DrawerStyled = styled(Drawer)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    position: "relative"
  },
  minWidth: "100%",
  [theme.breakpoints.up(700)]: {
    minWidth: 500
  },
  zIndex: 1500,
  "& > .MuiDrawer-paperAnchorRight": {
    borderRadius: 0
  },
  "& .MuiDrawer-paper": {
    [theme.breakpoints.down(700)]: {
      width: "100%"
    }
  }
})) as typeof Drawer;

export type CasePreviewDrawerProps = {
  case?: _case;
  open: boolean;
  onClose: () => void;

  /** In the context of the app, the persistent drawer will make room for the app bar in a `fixed` position. */
  appBarHeight?: number;
};

export function CasePreviewDrawer({
  case: _case,
  open,
  onClose,
  appBarHeight
}: CasePreviewDrawerProps) {
  const handleClose = () => onClose();
  const [tab, setTab] = useState<
    "details" | "posts" | "personnel" | "history" | "attachments" | "caseSitesAssetTags"
  >("details");
  return (
    <DrawerStyled
      open={open}
      onClose={handleClose}
      anchor="right"
      variant="persistent"
      PaperProps={{
        style: {
          width: casePreviewDrawerWidth,
          top: appBarHeight,
          height: appBarHeight ? `calc(100vh - ${appBarHeight}px)` : undefined
        }
      }}
    >
      {_case ? (
        <DrawerLayout
          onClose={handleClose}
          title="Case Preview"
          content={
            <div>
              <TabContext value={tab}>
                <TabList
                  onChange={(e, newValue) => setTab(newValue)}
                  aria-label="Case Card Tabs"
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                >
                  <Tab label="Details" value="details" />
                  <Tab label="History" value="history" />
                  <Tab label="Posts" value="posts" />
                  <Tab label="Personnel" value="personnel" />
                  <Tab label="Attachments" value="attachments" />
                  <Tab label="Site Asset Tags" value="caseSitesAssetTags" />
                </TabList>
                <TabPanel value="details" sx={{ p: 2 }}>
                  <LabelValueGrid>
                    <LabelValue
                      label="Severity"
                      value={_case.caseSeverityCategory?.titleShort || "Unknown"}
                    />
                    <LabelValue label="Stage" value={_case.caseStage?.title || "Unknown"} />
                    <LabelValue
                      label="Type Category"
                      value={_case.caseTypeCategory?.title || "Unknown"}
                    />
                    <LabelValue
                      label="Event Category"
                      value={_case.caseEventCategory?.title || "Unknown"}
                    />

                    <LabelValue label="Created By" value={_case.createdBy} />
                    <LabelValue label="Created At" value={formatDateTimeShort(_case.createdOn)} />
                    <LabelValue label="Modified At" value={formatDateTimeShort(_case.modifiedOn)} />
                  </LabelValueGrid>

                  <Box sx={{ mt: 2 }}>
                    <LabelValue label="Description" value={_case.description} />
                  </Box>
                </TabPanel>
                <TabPanel value="history" sx={{ p: 0 }}>
                  <CaseStageHistoriesTimeline case={_case} />
                </TabPanel>
                <TabPanel value="posts" sx={{ p: 0 }}>
                  <CaseCommunicationsTimeline case={_case} />
                </TabPanel>
                <TabPanel value="personnel" sx={{ p: 2 }}>
                  <CasePersonnel case={_case} />
                </TabPanel>
                <TabPanel value="attachments" sx={{ p: 2 }}>
                  {_case.caseFiles.length === 0 ? (
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      component="div"
                      sx={{ textAlign: "center" }}
                    >
                      No attachments
                    </Typography>
                  ) : (
                    <Box display="flex" flexDirection="column" gap={2} alignItems="flex-start">
                      {_case.caseFiles.map((caseFile) => {
                        return (
                          <CaseFileAttachment
                            key={caseFile.id}
                            attachment={{
                              type: "existing",
                              data: caseFile as ExistingCaseFileAttachment
                            }}
                          />
                        );
                      })}
                    </Box>
                  )}
                </TabPanel>
                <TabPanel value="caseSitesAssetTags" sx={{ p: 2 }}>
                  {_case.caseSitesAssetsTags.length === 0 ? (
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      component="div"
                      sx={{ textAlign: "center" }}
                    >
                      No site asset tags
                    </Typography>
                  ) : (
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Site</TableCell>
                            <TableCell>Asset</TableCell>
                            <TableCell>Tag</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {_case.caseSitesAssetsTags.map((caseSiteAssetTag, index) => {
                            return (
                              <TableRow key={index}>
                                {/* @ts-expect-error relationships are not on the openapi spec type but they do exist */}
                                <TableCell>{caseSiteAssetTag?.site || "N/A"}</TableCell>
                                {/* @ts-expect-error relationships are not on the openapi spec type but they do exist */}
                                <TableCell>{caseSiteAssetTag?.asset || "N/A"}</TableCell>
                                {/* @ts-expect-error relationships are not on the openapi spec type but they do exist */}
                                <TableCell>{caseSiteAssetTag?.tag || "N/A"}</TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </TabPanel>
              </TabContext>
            </div>
          }
          actions={
            <>
              <Button onClick={() => alert("not yet implemented")}>Pin</Button>
              <Button onClick={() => alert("not yet implemented")}>Follow</Button>
              <Button component={RouterLink} to={`/case/${_case.id}/edit`}>
                Edit
              </Button>
            </>
          }
        />
      ) : null}
    </DrawerStyled>
  );
}

/**
 * Wraps around page content, making room for the case preview drawer when a `case` is selected.
 */
export function CasePreviewDrawerPage({
  children,
  appBarHeight
}: {
  children: (setSelected: (caseId: _case | null) => void) => React.ReactNode;
  appBarHeight?: number;
}) {
  const [selected, setSelected] = useState<_case | null>(null);

  return (
    <>
      <Box
        sx={{
          // make room for the case preview drawer
          paddingRight: selected ? `${casePreviewDrawerWidth}px` : 0,
          display: "flex",
          flexDirection: "column",
          gap: 2
        }}
      >
        {children(setSelected)}
      </Box>

      <CasePreviewDrawer
        open={!!selected}
        case={selected}
        onClose={() => setSelected(null)}
        appBarHeight={appBarHeight}
      />
    </>
  );
}
