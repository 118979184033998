import { useMutation } from "@tanstack/react-query";

export type PresignedUploadFile = {
  presignedUrl: string;
  file: File;
};

export async function fileToBlob(file: File) {
  return new Blob([new Uint8Array(await file.arrayBuffer())], { type: file.type });
}

export function useFileUploadMutation() {
  return useMutation({
    mutationFn: async ({ presignedUrl, file }: PresignedUploadFile) => {
      const body = await fileToBlob(file);
      try {
        const response = await fetch(presignedUrl, {
          method: "PUT",
          body: body,
          headers: {
            "Content-Type": file.type ?? "application/octet-stream"
          }
        });

        return response;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Error uploading to S3 with presigned url", error);
        throw error;
      }
    }
  });
}
