import { useDebounce, Box } from "@packages/theme-mui-v5";
import { useState, useMemo } from "react";
import { AutocompleteBase, CommonFieldStateProps } from "@packages/core";
import { getTagsByIdOptions, postTagsSearchOptions, tag } from "@packages/accelerator-queries";
import { buildSearchPayload } from "@packages/data-grid";
import { useQuery } from "@tanstack/react-query";

export type AcceleratorTagsAutocompleteProps = {
  value: tag["id"];
  onChange: (value: tag["id"], tag: tag) => void;
} & CommonFieldStateProps;

export function AcceleratorTagsAutocomplete({
  value,
  onChange,
  ...autocompleteProps
}: AcceleratorTagsAutocompleteProps) {
  const [searchInput, setSearchInput] = useState("");
  const searchInputDebounced = useDebounce(searchInput, 200);
  const body = buildSearchPayload({ searchTerm: searchInputDebounced }) as Record<string, unknown>;
  const query = useQuery(postTagsSearchOptions({ body }));

  /** For mapping the current / selected item, separate from search results. */
  const selectedTagQuery = useQuery({
    enabled: !!value,
    ...getTagsByIdOptions({ path: { id: value } })
  });

  /**
   * All options from search merged with the selected item `value` fetched by id.
   * Dedupes the search results and selected item by id.
   *
   * @TODO this could be a candidate for a common hook for selects that need to merge
   * a selected item with search results in shared / common Autocomplete components.
   * Consider moving this function to `@packages/core`.
   */
  const options = useMemo(() => {
    const opts = [selectedTagQuery.data?.data, ...(query.data?.data || [])].filter(Boolean);
    const deduped = dedupeBy(opts, (a, b) => a.id === b.id);
    return deduped;
  }, [query.data, selectedTagQuery.data]);

  const valueMappedOption = useMemo(() => {
    return options.find((option) => option.id === value) || null;
  }, [value, options]);

  return (
    <AutocompleteBase
      label="Tag"
      primaryKey="id"
      options={options}
      value={valueMappedOption!} // ! handles strict null check
      onChange={onChange}
      setSearchInput={(value) => setSearchInput(value)}
      loading={query.isPending}
      {...autocompleteProps}
      ListboxOptionComponent={({ option }) => (
        <Box data-testid="ListboxOptionComponent" sx={{ wordWrap: "break-word" }}>
          <Box component="span">{option.name}</Box>
        </Box>
      )}
      getOptionString={(option) => option.name}
    />
  );
}

/**
 * Deduplicates a collection of data by given iteratee function.
 *
 * @TODO move to core / util package
 *
 * @example
 * // [{ id: 1 }, { id: 2 }]
 * const deduped = dedupeBy([{ id: 1 }, { id: 1 }, { id: 2 }], (a, b) => a.id === b.id);
 */
function dedupeBy(arr, fn) {
  return arr.filter((element, index) => arr.findIndex((step) => fn(element, step)) === index);
}
