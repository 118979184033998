import { useCallback, useMemo } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  ColDef,
  ColumnMenuTab,
  CustomCellRendererProps,
  DataGrid,
  KeyCreatorParams,
  RowAction,
  RowActionsCell,
  useSearchServerSideDataSource,
  ValueFormatterParams
} from "@packages/data-grid";
import { Paper } from "@packages/theme-mui-v5";
import {
  _case,
  getCaseEventCategoriesOptions,
  getCaseImpactCategoriesOptions,
  getCaseInitiatingActionsOptions,
  getCaseProcessFocusCategoriesOptions,
  getCaseSeverityCategoriesOptions,
  getCaseTypeCategoriesOptions,
  postCasesSearchMutation
} from "@packages/case-management-queries";
import { DEFAULT_DATE_FORMAT, getDateInLocalTime } from "@packages/utils";
import { ApiListResponse, SearchFilter } from "@packages/service-api";

export type CaseManagementRowAction = RowAction & {
  action: (data: CustomCellRendererProps) => void;
};

export type CaseManagementDataGridProps = {
  filters?: SearchFilter<_case>;
  defaultVisibleColumns?: string[];
  rowActions?: CaseManagementRowAction[];
  onRowSelected?: (data: _case | null) => void;
};

export function CaseManagementDataGrid(props: CaseManagementDataGridProps) {
  const { filters, rowActions, defaultVisibleColumns = [] } = props;
  const searchCase = useMutation(postCasesSearchMutation());

  const { data: { data: eventCategoriesOptions } = {} } = useQuery(getCaseEventCategoriesOptions());
  const { data: { data: impactCategoriesOptions } = {} } = useQuery(
    getCaseImpactCategoriesOptions()
  );

  const { data: { data: initiatingActionsOptions } = {} } = useQuery(
    getCaseInitiatingActionsOptions()
  );
  const { data: { data: processFocusCategoriesOptions } = {} } = useQuery(
    getCaseProcessFocusCategoriesOptions()
  );
  const { data: { data: severityCategoriesOptions } = {} } = useQuery(
    getCaseSeverityCategoriesOptions()
  );
  const { data: { data: typeCategoriesOptions } = {} } = useQuery(getCaseTypeCategoriesOptions());

  const defaultColDef: ColDef<_case> = useMemo(() => {
    return {
      sortable: true,
      cellDataType: "text",
      suppressHeaderMenuButton: false,
      suppressHeaderFilterButton: false,
      menuTabs: ["generalMenuTab", "columnsMenuTab"] as ColumnMenuTab[]
    };
  }, []);

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          toolPanelParams: {
            suppressPivotMode: true,
            suppressRowGroups: true,
            suppressValues: true
          }
        }
      ],
      visible: false
    };
  }, []);

  const [skipKeywordList, sortConfig] = useMemo(() => {
    return [
      ["createdOn", "alarmDate"],
      {
        caseInitiatingActionId: "caseInitiatingAction.title",
        caseEventCategoryId: "caseEventCategory.title",
        caseImpactCategoryId: "caseImpactCategory.title",
        caseSeverityCategoryId: "caseSeverityCategory.title",
        caseTypeCategoryId: "caseTypeCategory.title"
      }
    ];
  }, []);

  const { setSearchTerm, serverSideDatasource } = useSearchServerSideDataSource<_case>({
    filters,
    search: async (searchParams) => {
      const response = await searchCase.mutateAsync({ body: searchParams });
      return response as ApiListResponse<_case>;
    },
    skipKeywordList,
    sortConfig
  });

  const comparator = useCallback((a, b) => a?.title?.localeCompare(b?.title), []);

  const columnDefs = useMemo(() => {
    const hideColumn = (id) => !defaultVisibleColumns.includes(id);

    const colDefs: ColDef<_case>[] = [
      {
        field: "id",
        headerName: "Case ID",
        hide: hideColumn("caseId")
      },
      {
        field: "caseSitesAssetsTags",
        headerName: "Site",
        autoHeight: true,
        hide: hideColumn("site"),
        wrapText: true,
        sortable: false,
        valueFormatter: (params) => params?.value?.map((data) => data?.site?.name)?.join("\n")
      },
      {
        field: "caseSitesAssetsTags",
        headerName: "Asset",
        hide: hideColumn("asset"),
        autoHeight: true,
        wrapText: true,
        sortable: false,
        valueFormatter: (params) => params?.value?.map((data) => data?.asset?.assetCode)?.join("\n")
      },
      {
        field: "description",
        headerName: "Description",
        hide: hideColumn("description")
      },
      {
        field: "caseProcessFocusCategoriesSelections",
        valueFormatter: (params) => params?.value?.caseProcessFocusCategory?.title || "",
        headerName: "NROC Focus Per Plan",
        sortable: false,
        hide: hideColumn("NROCFocusPerPlan")
      },
      {
        field: "caseAlarmId",
        headerName: "Alarm ID",
        hide: hideColumn("alarmId")
      },
      {
        field: "alarmDate",
        headerName: "Alarm Time",
        valueFormatter: (params) => getDateInLocalTime(params.value, DEFAULT_DATE_FORMAT),
        hide: hideColumn("alarmDate")
      },
      {
        field: "caseInitiatingActionId",
        headerName: "Initiating Action",
        hide: hideColumn("initiatingAction"),
        valueGetter: (params) => params.data?.caseInitiatingAction?.title,
        filter: "agSetColumnFilter",
        headerComponentParams: {
          menuIcon: "caseInitiatingActionId"
        },
        filterParams: {
          buttons: ["apply", "reset"],
          comparator,
          closeOnApply: true,
          values: (params) => params.success(initiatingActionsOptions),
          keyCreator: (params: KeyCreatorParams) => params.value.id,
          valueFormatter: (params: ValueFormatterParams) => params.value.title
        }
      },
      {
        field: "caseEventCategoryId",
        headerName: "Event",
        hide: hideColumn("event"),
        valueGetter: (params) => params.data?.caseEventCategory?.title,
        filter: "agSetColumnFilter",
        headerComponentParams: {
          menuIcon: "caseEventCategory.id"
        },
        filterParams: {
          buttons: ["apply", "reset"],
          comparator,
          closeOnApply: true,
          values: (params) => params.success(eventCategoriesOptions),
          keyCreator: (params: KeyCreatorParams) => params.value.id,
          valueFormatter: (params: ValueFormatterParams) => params.value.title
        }
      },
      {
        field: "caseImpactCategoryId",
        headerName: "Impact",
        hide: hideColumn("impact"),
        filter: "agSetColumnFilter",
        headerComponentParams: {
          menuIcon: "caseImpactCategory.id"
        },
        filterParams: {
          buttons: ["apply", "reset"],
          comparator,
          closeOnApply: true,
          values: (params) => params.success(impactCategoriesOptions),
          keyCreator: (params: KeyCreatorParams) => params.value.id,
          valueFormatter: (params: ValueFormatterParams) => params.value.title
        }
      },
      {
        field: "caseSeverityCategoryId",
        headerName: "Severity",
        hide: hideColumn("severity"),
        valueGetter: (params) => params.data?.caseSeverityCategory?.title,
        filter: "agSetColumnFilter",
        headerComponentParams: {
          menuIcon: "caseSeverityCategory.id"
        },
        filterParams: {
          buttons: ["apply", "reset"],
          comparator,
          closeOnApply: true,
          values: (params) => params.success(severityCategoriesOptions),
          keyCreator: (params: KeyCreatorParams) => params.value.id,
          valueFormatter: (params: ValueFormatterParams) => params.value.title
        }
      },
      {
        field: "caseTypeCategoryId",
        headerName: "Type",
        hide: hideColumn("type"),
        valueGetter: (params) => params.data?.caseTypeCategory?.title,
        filter: "agSetColumnFilter",
        headerComponentParams: {
          menuIcon: "caseTypeCategory.id"
        },
        filterParams: {
          buttons: ["apply", "reset"],
          comparator,
          closeOnApply: true,
          values: (params) => params.success(typeCategoriesOptions),
          keyCreator: (params: KeyCreatorParams) => params.value.id,
          valueFormatter: (params: ValueFormatterParams) => params.value.title
        }
      },
      {
        field: "createdBy",
        headerName: "Opened By",
        hide: hideColumn("openedBy")
      },
      {
        field: "createdOn",
        headerName: "Opened Date",
        valueFormatter: (params) => getDateInLocalTime(params.value, DEFAULT_DATE_FORMAT),
        hide: hideColumn("openedDate")
      },
      {
        field: "caseStage.title",
        headerName: "Status",
        hide: hideColumn("status")
      }
    ];

    if (rowActions?.length) {
      colDefs.push({
        cellRenderer: (params: CustomCellRendererProps) => {
          const actions: RowAction[] = rowActions.map((rowAction) => ({
            ...rowAction,
            action: () => rowAction.action(params)
          }));
          return <RowActionsCell actions={actions} />;
        },
        enableCellChangeFlash: false,
        filter: false,
        sortable: false,
        editable: false,
        minWidth: 80,
        maxWidth: 80
      });
    }

    return colDefs;
  }, [
    rowActions,
    eventCategoriesOptions,
    impactCategoriesOptions,
    initiatingActionsOptions,
    processFocusCategoriesOptions,
    severityCategoriesOptions,
    typeCategoriesOptions,
    defaultVisibleColumns
  ]);

  const getRowId = useCallback((params) => params.data.id, []);

  return (
    <Paper>
      <DataGrid
        serverSideDatasource={serverSideDatasource}
        getRowId={getRowId}
        sideBar={sideBar}
        onQuickFilterChange={setSearchTerm}
        isServersidePagination
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        allowCustomHeader
        csvExportFileName="csv-filename"
        enableCsvExport
        enableQuickSearch
        onRowSelected={(event) => {
          if (event.node.isSelected()) {
            props?.onRowSelected(event.node.data);
          }
        }}
      />
    </Paper>
  );
}
